import { apiKeys, apiMethods, apiStatuses, apiUrls } from '../../api/constants/index';
import { clearData, fetchData } from '../../@app/slices/fetch-slice';
import { getData, getStatus } from '../../@app/slices/helpers/helpers';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetTermOptions() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getTermOption = getData(state, apiKeys.termMasterData, []) || [];
  const getTermStatus = getStatus(state, apiKeys.termMasterData, '');
  const getTermLoading = apiStatuses.pending === getTermStatus;

  useEffect(() => {
    dispatch(
      fetchData({
        url: apiUrls.termMasterData,
        method: apiMethods.GET,
        key: apiKeys.termMasterData
      })
    );
  }, [dispatch]);

  const getTermOptionData= useMemo(() => {
    return getTermOption.filter((option) => option.activeStatus === 1)
    .map(({ id, term }) => ({
      value: id,
      label: term
    }))
  }, [getTermOption]);

  const getTermOptionId= useMemo(() => {
    return getTermOption.filter((option) => option.activeStatus === 1)
    .map(({ termOrderId, term }) => ({
      value: termOrderId,
      label: term
    }))
  }, [getTermOption]);

  useEffect(
    () => () => {
      dispatch(clearData({ key: [apiKeys.termMasterData,] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      getTermOption,
      getTermOptionData,
      getTermOptionId,
      getTermStatus,
      getTermLoading
    }),
    [getTermOption,
      getTermOptionData,
      getTermOptionId,
      getTermStatus,
      getTermLoading]
  );
}

export default useGetTermOptions;
