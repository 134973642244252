import { apiKeys, apiMethods, apiStatuses, apiUrls } from '../../api/constants/index';
import { clearData, fetchData } from '../../@app/slices/fetch-slice';
import { getData, getStatus } from '../../@app/slices/helpers/helpers';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetAcademicOptions() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getAcademicData = getData(state, apiKeys.academicMasterData, []) || [];
  const getAcademicStatus = getStatus(state, apiKeys.academicMasterData, '');
  const getAcademicLoading = apiStatuses.pending === getAcademicStatus;

  useEffect(() => {
    dispatch(
      fetchData({
        url: apiUrls.academicMasterData,
        method: apiMethods.GET,
        key: apiKeys.academicMasterData
      })
    );
  }, [dispatch]);

  const getAcademicYear = useMemo(() => {
    return getAcademicData.filter((option) => option.activeStatus === 1)
    .map(({ acadId, acadYear }) => ({
      value: acadId,
      label: acadYear
    }))
  }, [getAcademicData]);

  useEffect(
    () => () => {
      dispatch(clearData({ key: [apiKeys.academicMaster,] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      getAcademicData,
      getAcademicYear,
      getAcademicStatus,
      getAcademicLoading
    }),
    [getAcademicData, getAcademicYear, getAcademicStatus, getAcademicLoading]
  );
}

export default useGetAcademicOptions;
