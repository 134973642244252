import login_logo from '../../../asset/School_Logo.jpg'

export const Images = {
    login_logo
}

export const Colors = {
    text_color: "#f5a60b",
}


export const Colorsbold = {
    text_color: "#e3092a",
}