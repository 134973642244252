import { apiKeys, apiMethods, apiUrls } from '../../../../../api/constants/index';
import { fetchData } from '../../../../../@app/slices/fetch-slice';
import { useDispatch } from 'react-redux';
import { useMemo, useState } from 'react';
import {useLocation, useNavigate} from 'react-router';
import navs from '../../../../../api/constants/navs';
import { pathNavigate, sendFormattedId } from '../../../../../common/helpers/helpers';

export default function useSubjectMasterForm() {
  const dispatch = useDispatch();
  // const {state} = useLocation();
  const { state: locationState } = useLocation(); // Rename state to locationState
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const isEdit = locationState?.isEdit || false;
  let defaultValue = locationState?.data;
  const id = defaultValue?.id;

  const onFinish = (values) => {
    setShowDialog(false);
    if(isEdit === true){
      dispatch(fetchData({
        url: apiUrls.updateSubjectMasterData,
        method: apiMethods.POST,
        key: apiKeys.updateSubjectMasterData,
        body: sendFormattedId({...values, id })
      })).then((response) => {
        pathNavigate(navigate, { response, page: navs.subjectMaster }); 
    });
    }else{
      dispatch(fetchData({
        url: apiUrls.addSubjectMasterData,
        method: apiMethods.POST,
        key: apiKeys.addSubjectMasterData,
        body: sendFormattedId({...values })
        })).then((response) => {
        pathNavigate(navigate, { response, page: navs.subjectMaster }); 
    });
    }

  }

  const handleClickBack = () => {
    navigate('/subjectMaster');
  };


  return useMemo(() => ({
    onFinish,
    showDialog,
    handleClickBack,
    defaultValue,
    isEdit
  }), [onFinish, showDialog, handleClickBack, defaultValue, isEdit]);
}