import React, {  } from 'react';
import CustomTable from '../../../common/components/CustomCheckboxTable';
import { column } from './components/column';
import UseClassAllotment from './hooks/UseClassAllotment';
import CustomDropdown from '../../../common/components/Dropdown/dropdown';
import { Col, Row, Form } from "antd";

const EnquiryMaster = ({ setTopTitle }) => {
  
    setTopTitle('Class Allotment');

  const {
    onClickAdd,
    handleEditClick,
    enquiryMasterLoading,
    enquiryMasterValues,
    getStandardLoading,
    getStandardOptionData,
    selectedValues,
    handleChange,
  } = UseClassAllotment();
  

  return (
    <div className="h-screen">
              <Row gutter={[45, 0]} style={{justifyContent:'center', paddingTop: 25}} >
                <Col md={{ span: 6 }} xs={{ span: 24 }} >
                  <Form.Item name='dropdownId' label='Fees Category' >
                    <CustomDropdown
                      options={getStandardOptionData}
                      onChange={handleChange}
                      value={selectedValues} // Pass selectedValues here
                      maxTagCount={0}
                      loading={getStandardLoading}
                      mode="single"
                      showSearch={true}
                      placeholder="Select an option"
                    />
                  </Form.Item>
                </Col>
              </Row>
      <CustomTable
        dataSource={enquiryMasterValues}
        loading={enquiryMasterLoading}
        column={column}
        handleEditClick={handleEditClick}
        onClickAdd={(selectedRows) => onClickAdd(selectedRows)}
        title={"Enquiry Master List"}
      />
    </div>
  );
}

export default EnquiryMaster;
