// navs.js
const navs = {
  //master
  academicMaster: "/academicMaster",
  academicMasterForm: "/academicMaster/addForm",

  //submaster
  dropdownMaster: "/dropdownMaster",
  dropdownMasterForm: "/dropdownMaster/addForm",
  roleMaster: "/roleMaster",
  roleMasterForm: "/roleMaster/addForm",
  standardMaster: "/standardMaster",
  standardMasterForm: "/standardMaster/addForm",
  subjectMaster: "/subjectMaster",
  subjectMasterForm: "/subjectMaster/addForm",
  termMaster: "/termMaster",
  termMasterForm: "/termMaster/addForm",
  menuMaster: "/menuMaster",
  menuMasterForm: "/menuMaster/addForm",
  menuListMasterForm: "/menuListMaster/addForm",
  menuAccessMasterForm: "/menuAccessMaster/addForm",

  //student
  admissionMaster: "/admissionMaster",
  admissionMasterForm: "/admissionMaster/addForm",
  studentMaster: "/studentMaster",
  studentMasterForm: "/studentMaster/addForm",
  enquiryMaster: "/enquiryMaster",
  enquiryMasterForm: "/enquiryMaster/addForm",
 
  //fees
  feesTypeMaster: "/feesType",
  feesTypeMasterForm: "/feesType/addForm",
  feesCategoryMaster: "/feesCategory",
  feesCategoryMasterForm: "/feesCategory/addForm",
  feesAllotment: "/feesAllotment",
  feesAllotmentForm: "/feesAllotment/addForm",

  staffMaster: "/staffMaster",
  staffMasterForm: "/staffMaster/addForm",


};

module.exports = navs;
