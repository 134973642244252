import React, { useEffect, useState } from 'react';
import { Select } from 'antd';

const { Option } = Select;

const CustomDropdown = ({ options, onChange, placeholder, maxTagCount, loading, mode, showSearch, value }) => {
  const [selectedValues, setSelectedValues] = useState(value || []);

  useEffect(() => {
    setSelectedValues(value || []);
  }, [value]);

  const handleChange = (newSelectedValues) => {
    if (mode === 'multiple') {
      if (Array.isArray(newSelectedValues)) {
        if (newSelectedValues.includes('select_all')) {
          const allValues = options.map(option => option.value);
          setSelectedValues(allValues);
          onChange(allValues);
        } else if (newSelectedValues.includes('unselect_all')) {
          setSelectedValues([]);
          onChange([]);
        } else {
          setSelectedValues(newSelectedValues);
          onChange(newSelectedValues);
        }
      }
    } else if (mode === 'single') {
      setSelectedValues(newSelectedValues);
      onChange(newSelectedValues);
    }
  };

  return (
    <Select
      placeholder={placeholder}
      mode={mode}
      value={selectedValues}
      onChange={handleChange}
      maxTagCount={maxTagCount}
      loading={loading}
      showSearch={showSearch}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      style={{
        fontWeight: "bold",
        borderColor: "black",
        boxShadow: "none",
        textAlign: "left",
        width: "100%"
      }}
    >
      {mode === 'multiple' && (
        <>
          <Option key="select_all" value="select_all">Select All</Option>
          <Option key="unselect_all" value="unselect_all">Unselect All</Option>
        </>
      )}
      {options.map(option => (
        <Option key={option.value} value={option.value}>{option.label}</Option>
      ))}
    </Select>
  );
};

export default CustomDropdown;
