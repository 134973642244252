import React from 'react';
import { Card, Button, Radio, Col, Row, Form, Upload, Modal, Image, Collapse } from 'antd';
import ConfirmOnExit from '../../../../common/components/confirmOnExit/ConfirmOnExit';
import UseAdmissionMasterForm from './hooks/UseStudentMasterForm';
import useStudentImageAdd from './hooks/UseStudentImageAdd';
import CustomDropdown from '../../../../common/components/Dropdown/dropdown';
import useGetDropdownListOptions from '../../../../common/hooks/useGetDropdownListMaster';
import useGetStandardOptions from '../../../../common/hooks/useGetStandardMaster';
import useGetAcademicOptions from '../../../../common/hooks/useGetAcademicMaster';
import CustomDatePicker from '../../../../common/components/DateInput/dateInput';
import CustomInput from '../../../../common/components/TextInput/textInput';
import RadioYesButton from '../../../../common/components/RadioButton/radioButton';

function AdmissionMasterForm({ setTopTitle }) {
  setTopTitle('Student Details');
  const { gender, salaryRange, enquiryBy, admissionType } = useGetDropdownListOptions();
  const { getStandardOptionData } = useGetStandardOptions();
  const { getAcademicYear } = useGetAcademicOptions();
  const { Panel } = Collapse;
  const {
    getFile, previewImage, previewOpen, previewTitle, fileList, handleChange, handleCancel,
    handlePreview, uploadButton, props, image } = useStudentImageAdd();
  const {
    onFinish, showDialog, handleClickBack, isEdit, selectedSalary, selectedAdmissionBy,
    defaultValue, selectedStandardOption, selectedAcademicYear, selectedGender, selectedAdmissionOption,
    form, handleSalary, handleEnyBy, handleAdmType, handleGender, handleEnyStd, handleAcdYear,
    handleSibling, disabledFutureDates, addAdmissionMasterStatus, updateAdmissionMasterStatus } = UseAdmissionMasterForm(image);
  console.log(updateAdmissionMasterStatus, '///');

  return (
    <>
      <Card>
        <ConfirmOnExit showModel={showDialog} />
        <Row>
          <Col span={24}>
            <Form
              name='basic'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{
                studentName: defaultValue?.stName,
                gender: defaultValue?.gender,
                stDob: defaultValue?.stDob,
                doa: defaultValue?.doa,
                parentType: defaultValue?.parentType,
                admissionStd: defaultValue?.admissionStd,
                academicYear: defaultValue?.academicYear,
                fatherName: defaultValue?.fatherName,
                motherName: defaultValue?.motherName,
                guardianName: defaultValue?.guardianName,
                admissionType: defaultValue?.admissionType,
                fatherNumber: defaultValue?.fatherNumber,
                motherNumber: defaultValue?.motherNumber,
                guardianNumber: defaultValue?.guardianNumber,
                fatherOccupation: defaultValue?.fatherOccupation,
                selectedAdmissionBy: defaultValue?.selectedAdmissionBy,
                motherOccupation: defaultValue?.motherOccupation,
                guardianOccupation: defaultValue?.guardianOccupation,
                salary: defaultValue?.salary,
                religion: defaultValue?.religion,
                caste: defaultValue?.caste,
                previousSchool: defaultValue?.previousSchool,
                studentImage: defaultValue?.studentImage,
                sibling: defaultValue?.sibling === 'No' ? 'inActive' : 'active',
                activeStatus: defaultValue?.activeStatus === 'In Active' ? 'inActive' : 'active',
              }}
              onFinish={onFinish}
              autoComplete='off'
              form={form}>

              <Collapse defaultActiveKey={['2']}>
                <Panel
                  header={<div >Enquiry and Admission</div>}
                  key="1"
                >
                  <Row gutter={[15, 0]}>
                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item name="doa" label="Admission Date " rules={[{ required: true, message: 'Please Enter The Admission Date!' }]}
                        labelCol={{ md: { span: 24 }, xs: { span: 24 }, style: { textAlign: 'left' } }}>
                        <CustomDatePicker
                          disabledDate={disabledFutureDates} // Pass the disabled date function here
                        />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 6 }} xs={{ span: 24 }} >
                      <Form.Item name='admissionStd' label='Admission Standard' rules={[{ required: true, message: 'Please Enter The Admission Standard!' }]} >
                        <CustomDropdown
                          options={getStandardOptionData}
                          onChange={handleEnyStd}
                          value={selectedStandardOption} // Pass selectedValues here
                          maxTagCount={0}
                          loading={false}
                          mode="single"
                          showSearch={true}
                          placeholder="Select an option"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
                <Panel header="Student Details" key="2">
                  <Row gutter={[15, 0]}>
                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item
                        name='studentImage'
                        label='Student Image'
                        getValueFromEvent={getFile}
                      >
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                          {defaultValue?.id && fileList?.length === 0 ? (
                            defaultValue?.image ? (
                              <Image style={{ paddingRight: '10px' }} width={100} src={defaultValue?.image ?? ''} />
                            ) : (
                              'No Image Available'
                            )
                          ) : (
                            <></>
                          )}
                          <Upload
                            {...props}
                            listType="picture-card"
                            fileList={fileList}
                            onPreview={handlePreview}
                            capture='environment'
                            accept='.png,.jpg,.jpeg'
                            onChange={(e) => {
                              handleChange(e);
                            }}>
                            {fileList.length >= 1 ? null : uploadButton}
                          </Upload>
                        </div>
                        <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                          <img
                            alt='example'
                            style={{
                              width: '100%'
                            }}
                            src={previewImage}
                          />
                        </Modal>
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item label='Student Name' name='studentName'
                        rules={[{ required: true, message: 'Please Enter The Student Name!' }]}>
                        <CustomInput placeholder='Student Name' name='studentName' />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 6 }} xs={{ span: 24 }} >
                      <Form.Item name='gender' label='Gender' rules={[{ required: true, message: 'Please Enter The Gender!' }]} >
                        <CustomDropdown
                          options={gender}
                          onChange={handleGender}
                          value={selectedGender}
                          maxTagCount={0}
                          loading={false}
                          mode="single"
                          showSearch={true}
                          placeholder="Select an option"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item name="stDob" label="Date Of Birth " rules={[{ required: true, message: 'Please Enter The Date Of Birth!' }]}
                        labelCol={{ md: { span: 24 }, xs: { span: 24 }, style: { textAlign: 'left' } }}>
                        <CustomDatePicker
                          disabledDate={disabledFutureDates} // 
                        />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 6 }} xs={{ span: 24 }} >
                      <Form.Item name='parentType' label='Parent / Guardian' rules={[{ required: true, message: 'Please Enter The Parent Type!' }]}>
                        <CustomDropdown
                          options={enquiryBy}
                          onChange={handleEnyBy}
                          value={selectedAdmissionBy} // Pass selectedValues here
                          maxTagCount={0}
                          loading={false}
                          mode="single"
                          showSearch={true}
                          placeholder="Select an option"
                        />
                      </Form.Item>
                    </Col>

                    <Col md={{ span: 6 }} xs={{ span: 24 }} >
                      <Form.Item name='admissionType' label='Admission Type' rules={[{ required: true, message: 'Please Enter The Admission Type!' }]}>
                        <CustomDropdown
                          options={admissionType}
                          onChange={handleAdmType}
                          value={selectedAdmissionOption} // Pass selectedValues here
                          maxTagCount={0}
                          loading={false}
                          mode="single"
                          showSearch={true}
                          placeholder="Select an option"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 6 }} xs={{ span: 24 }} >
                      <Form.Item name='academicYear' label='Academic Year' rules={[{ required: true, message: 'Please Enter The Academic Year!' }]}>
                        <CustomDropdown
                          options={getAcademicYear}
                          onChange={handleAcdYear}
                          value={selectedAcademicYear} // Pass selectedValues here
                          maxTagCount={0}
                          loading={false}
                          mode="single"
                          showSearch={true}
                          placeholder="Select an option"
                        />
                      </Form.Item>
                    </Col>

                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item name='sibling' label='Sibling'
                        rules={[{ required: true, message: 'Please select your Sibling' }]}>
                        <RadioYesButton onChange={handleSibling}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item label='Refer By' name='referBy'>
                        <CustomInput placeholder='Refer By' name='referBy' />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item label='Religion' name='religion'>
                        <CustomInput placeholder='Religion' name='religion' />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item label='Caste' name='caste'>
                        <CustomInput placeholder='Caste' name='caste' />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item label='Previous School' name='previousSchool'>
                        <CustomInput placeholder='Previous School' name='previousSchool' />
                      </Form.Item>
                    </Col>

                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item name='activeStatus' label='Status'
                        rules={[{ required: true, message: 'Please select your status' }]}>
                        <Radio.Group buttonStyle='solid' size='middle' name='activeStatus' >
                          <Radio.Button className='active' value='active'>
                            Active
                          </Radio.Button>
                          <Radio.Button className='in-active' value='inActive'>
                            In-Active
                          </Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                    </Col>

                  </Row>
                </Panel>
                <Panel header="Parent Details" key="3">
                  <Row gutter={[15, 0]}>

                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item label='Father Name' name='fatherName'
                        rules={[{ required: true, message: 'Please input the Father Name!' }]}>
                        <CustomInput placeholder='Father Name' name='fatherName' />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item label='Mother Name' name='motherName'
                        rules={[{ required: true, message: 'Please input the Mother Name!' }]}>
                        <CustomInput placeholder='Father Name' name='motherName' />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item label='Father Number' name='fatherNumber'
                        rules={[{ required: true, message: 'Please input the Father NUmber!' }]}>
                        <CustomInput placeholder='Father Number' name='fatherNumber' />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item label='Mother Number' name='motherNumber'
                        rules={[{ required: true, message: 'Please input the Mother Number!' }]}>
                        <CustomInput placeholder='Mother Number' name='motherNumber' />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item label='Father Occupation' name='fatherOccupation'
                        rules={[{ required: true, message: 'Please input the Father Occupation!' }]}>
                        <CustomInput placeholder='Father Occupation' name='fatherOccupation' />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item label='Mother Occupation' name='motherOccupation'
                        rules={[{ required: true, message: 'Please input the Mother Occupation!' }]}>
                        <CustomInput placeholder='Mother Occupation' name='motherOccupation' />
                      </Form.Item>
                    </Col>

                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item label='Guardian Name' name='guardianName'
                        rules={[{ required: true, message: 'Please input the Guardian Name!' }]}>
                        <CustomInput placeholder='Guardian Name' name='guardianName' />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item label='Guardian Number' name='guardianNumber'
                        rules={[{ required: true, message: 'Please input the Guardian Number!' }]}>
                        <CustomInput placeholder='Guardian Number' name='guardianNumber' />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item label='Guardian Occupation' name='guardianOccupation'
                        rules={[{ required: true, message: 'Please input the Guardian Occupation!' }]}>
                        <CustomInput placeholder='Guardian Occupation' name='guardianOccupation' />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item label='Address' name='address'
                        rules={[{ required: true, message: 'Please input the Address!' }]}>
                        <CustomInput placeholder='Address' name='address' />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 6 }} xs={{ span: 24 }} >
                      <Form.Item name='salary' label='Salary'  >
                        <CustomDropdown
                          options={salaryRange}
                          onChange={handleSalary}
                          value={selectedSalary} // Pass selectedValues here
                          maxTagCount={0}
                          loading={false}
                          mode="single"
                          showSearch={true}
                          placeholder="Select an option"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
                <Panel header="Student Documents" key="4">
                </Panel>
                <Panel header="Student Fees Details" key="5">
                </Panel>
                <Panel header="Student History Details" key="6">
                </Panel>



                <Col span={24}>
                  <Row gutter={[15, 15]} style={{ justifyContent: 'end' }}>
                    <Col span={12} style={{ textAlign: 'right' }} className='d-flex align-items-center justify-content-end mt-3'>
                      <Form.Item className='mx-2'>
                        <Button
                          className='orangeFactory'
                          type='primary'
                          disabled={addAdmissionMasterStatus === 'pending' || updateAdmissionMasterStatus === 'pending'}
                          htmlType='submit'
                        >
                          {isEdit ? 'Update' : 'Add'}
                        </Button>
                      </Form.Item>
                      <Form.Item>
                        <Button onClick={handleClickBack}>Back</Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Collapse>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default AdmissionMasterForm;
