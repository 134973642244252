import { apiKeys, apiMethods, apiUrls } from '../../../../../api/constants/index';
import { fetchData } from '../../../../../@app/slices/fetch-slice';
import { useDispatch } from 'react-redux';
import { useMemo, useState } from 'react';
import {useLocation, useNavigate} from 'react-router';
import navs from '../../../../../api/constants/navs';
import { pathNavigate, sendFormattedId } from '../../../../../common/helpers/helpers';

export default function useSubjectMasterForm() {
  const dispatch = useDispatch();
  // const {state} = useLocation();
  const { state: locationState } = useLocation(); // Rename state to locationState
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const isEdit = locationState?.isEdit || false;
  let defaultValue = locationState?.data;
  const dropListId = defaultValue?.dropListId;
  const dropdownId = defaultValue?.dropdownId || 11 ;
console.log(isEdit);
console.log(dropdownId);

  const onFinish = (values) => {
    setShowDialog(false);
    if (isEdit === true) {
      dispatch(fetchData({
        url: apiUrls.updateFeesTypeData,
        method: apiMethods.POST,
        key: apiKeys.updateFeesTypeData,
        body: sendFormattedId({ ...values, dropListId, dropdownId })
      })).then((response) => {
        pathNavigate(navigate, { response, page: navs.feesTypeMaster }); 
    });
    } else {
      dispatch(fetchData({
        url: apiUrls.addFeesTypeData,
        method: apiMethods.POST,
        key: apiKeys.addFeesTypeData,
        body: sendFormattedId({ ...values, dropListId, dropdownId })
      })).then((response) => {
        pathNavigate(navigate, { response, page: navs.feesTypeMaster }); 
    });
    }

  }

  const handleClickBack = () => {
    navigate(navs.feesTypeMaster);
  };


  return useMemo(() => ({
    onFinish,
    showDialog,
    handleClickBack,
    defaultValue,
    isEdit
  }), [onFinish, showDialog, handleClickBack, defaultValue, isEdit]);
}