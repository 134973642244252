import { apiKeys, apiMethods, apiUrls } from '../../../../../api/constants/index';
import { clearMultipleData, fetchData } from '../../../../../@app/slices/fetch-slice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Form, } from 'antd';
import { getStatus } from '../../../../../@app/slices/helpers/helpers';
import { sendFormattedId } from '../../../../../common/helpers/helpers';
// import { getStatus } from '../../../@app/slices/helpers/helpers';

export default function useAdmissionMasterForm(image) {

  const dispatch = useDispatch();
  const { state: locationState } = useLocation(); // Rename state to locationState
  const state = useSelector((store) => store); // Renamed to reduxState
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [selectedGender, setSelectedGender] = useState([]);
  const [selectedSalary, setSelectedSalary] = useState([]);
  const [selectedStandardOption, setSelectedStandardOption] = useState([]);
  const [selectedAcademicYear, setSelectedAcademicYear] = useState([]);
  const [selectedAdmissionBy, setSelectedAdmissionBy] = useState([]);
  const [selectedSibling, setSelectedSibling] = useState([]);
  const [selectedAdmissionOption, setSelectedAdmissionOption] = useState([]);

  const addAdmissionMasterStatus = getStatus(state, apiKeys.addAdmissionMasterData, '');
  const updateAdmissionMasterStatus = getStatus(state, apiKeys.updateAdmissionMasterData, '');

  const isEdit = locationState?.isEdit || false; // Updated to use locationState
  let defaultValue = locationState?.data; // Updated to use locationState
  const admissionNo =defaultValue?.admissionNo;
  const [form] = Form.useForm();

  const onFinish = (values) => {
 
    setShowDialog(false);
      if(isEdit){
        dispatch(fetchData({
          url: apiUrls.updateAdmissionMasterData,
          method: apiMethods.POST,
          key: apiKeys.updateAdmissionMasterData,
          body: sendFormattedId({...values, image, admissionNo })
        }))
        .then((response) => {
          if (response?.payload?.status === 200) {
            navigate('/admissionMaster');
          } else {
            console.log('asdasd');
          }
        })
      }else{
        dispatch(fetchData({
          url: apiUrls.addAdmissionMasterData,
          method: apiMethods.POST,
          key: apiKeys.addAdmissionMasterData,
          body: {...values, image} 
        }))
        .then((response) => {
          if (response?.payload?.status === 200) {
            navigate('/admissionMaster');
          } else {
            console.log('asdasd');
          }
        })
      }
        

  }

  const handleGender = (selectedGender) => {
    setSelectedGender(selectedGender);
  };

  const handleEnyBy = (selectedAdmissionBy) => {
    setSelectedAdmissionBy(selectedAdmissionBy);
  };
  const handleEnyStd = (selectedStandardOption) => {
    setSelectedStandardOption(selectedStandardOption);
  };
  const handleAcdYear = (selectedAcademicYear) => {
    setSelectedAcademicYear(selectedAcademicYear);
  };
  const handleAdmType = (selectedAdmissionOption) => {
    setSelectedAdmissionOption(selectedAdmissionOption);
  };
  const handleSalary = (selectedSalary) => {
    setSelectedSalary(selectedSalary);
  };
  
  const disabledFutureDates = (current) => {
    const today = new Date();
    return current && current > today;
  };


  const handleSibling = (selectedSibling) => {
    setSelectedSibling(selectedSibling);
  };

  const handleClickBack = () => {
    navigate('/admissionMaster');
  };


  // useEffect(() => {
  //   if (addAdmissionMasterStatus === apiStatuses.succeeded) {
  //     console.log('865');
  //   }
  // }, [addAdmissionMasterStatus])

  useEffect(
    () => () => {
      dispatch(clearMultipleData({ key: [apiKeys.addAdmissionMasterData, 
        apiKeys.updateAdmissionMasterData,] }));
    },
    [dispatch]
  );

  return useMemo(() => ({
    onFinish,
    showDialog,
    handleClickBack,
    handleGender,
    handleEnyBy,
    handleEnyStd,
    handleAcdYear,
    handleSibling,
    handleAdmType,
    handleSalary,
    defaultValue,
    selectedGender, selectedSalary, selectedSibling,
    selectedStandardOption, selectedAcademicYear, selectedAdmissionBy,
    disabledFutureDates, selectedAdmissionOption, addAdmissionMasterStatus,
    isEdit, updateAdmissionMasterStatus,
    form
  }), [onFinish,
    showDialog,
    handleClickBack,
    handleGender,
    handleEnyBy,
    handleEnyStd,
    handleAcdYear,
    handleSibling,
    handleAdmType,
    handleSalary,
    defaultValue,
    selectedGender, selectedSalary, selectedSibling,
    selectedStandardOption, selectedAcademicYear, selectedAdmissionBy,
    disabledFutureDates, selectedAdmissionOption, addAdmissionMasterStatus,
    isEdit, updateAdmissionMasterStatus, form]);
}