import React, {  } from 'react';
import CustomTable from '../../../common/components/CustomTableView';
import { column } from './components/column';
import UseSubjectMaster from './hooks/UseSubjectMaster';
import { Tooltip } from '@mui/material';
import { Button } from 'react-bootstrap';
import { BsPlusLg } from 'react-icons/bs';
import { requestedFeaturesAll, requestedMenuAll } from '../../../api/constants/menuAccess';
import VerifyAccess from '../../../common/components/verify-access/verify-access';


const SubjectMaster = ({ setTopTitle }) => {
  setTopTitle('Subject Master');

  const {subjectMasterKey} = requestedMenuAll;
const {createKey, editKey, deleteKey} = requestedFeaturesAll;
  const userData = useSelector((state) => state.auth);
  const entitlement = userData?.userData?.data?.entitlement || {};

  const isEditDeleteAccess = isUserHasAccess(subjectMasterKey, editKey, entitlement)
    || isUserHasAccess(subjectMasterKey, deleteKey, entitlement);

  const actionButton = (
    <Tooltip placement="bottom" title={"Add"}>
          <VerifyAccess requestedMenu={academicMasterKey} requestedFeature={createKey}>
      <Button
        onClick={() => {
          onClickAdd();
        }}
        className="btn btn-primary me-2 px-md-3 px-sm-4"
      >
        <BsPlusLg size={12} />
      </Button>
      </VerifyAccess>
    </Tooltip>
  );
   
  const {
    onClickAdd,
    handleEditClick,
    subjectMasterValues,
    subjectMasterLoading
  } = UseSubjectMaster();

  return (
    <div className="h-screen pad-lg">
    
      <CustomTable
        dataSource={subjectMasterValues}
        loading={subjectMasterLoading}
        column={column({ handleEditClick, isEditDeleteAccess })}
        actionButton={actionButton}
        title={"Subject Master List"}
      />
    </div>
  );
}

export default SubjectMaster;
