import { apiKeys, apiMethods, apiUrls } from '../../../../../api/constants/index';
import { fetchData } from '../../../../../@app/slices/fetch-slice';
import { useDispatch } from 'react-redux';
import { useMemo, useState } from 'react';
import {useLocation, useNavigate} from 'react-router';
import navs from '../../../../../api/constants/navs';
import { pathNavigate, sendFormattedId } from '../../../../../common/helpers/helpers';
// import { getStatusCode } from '../../../@app/slices/helpers/helpers';

export default function useDropdownMasterForm() {
  const dispatch = useDispatch();
  // const state = useSelector((store) => store);
  const { state: locationState } = useLocation(); // Rename state to locationState
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const modaldropdown = locationState?.modaldropdown || false;
  const isEditdp = locationState?.isEditdp || false;
  const modaldropdownlist = locationState?.modaldropdownlist || false;
  const isEditdpl = locationState?.isEditdpl || false;
  let defaultValue = locationState?.data;
  const dropDownId = defaultValue?.dropDownId;
  const dropListId = defaultValue?.dropListId;
  const [selectedValues, setSelectedValues] = useState([]);

  // const updateAcademicMasterData  = getStatusCode(state, apiKeys.updateAcademicMasterData, '');

  const onFinish = (values) => {
    setShowDialog(false);

    console.log(values);
    if (modaldropdown) {
      if (isEditdp) {
        dispatch(fetchData({
          url: apiUrls.updateDropdownMasterData,
          method: apiMethods.POST,
          key: apiKeys.updateDropdownMasterData,
          body: sendFormattedId({ ...values, dropDownId })
        })).then((response) => {
          pathNavigate(navigate, { response, page: navs.dropdownMaster }); 
      });
      } else {
        dispatch(fetchData({
          url: apiUrls.addDropdownMasterData,
          method: apiMethods.POST,
          key: apiKeys.addDropdownMasterData,
          body: sendFormattedId({ ...values })
        })).then((response) => {
          pathNavigate(navigate, { response, page: navs.dropdownMaster }); 
      });
      }
    } else {
      if (isEditdpl) {
        dispatch(fetchData({
          url: apiUrls.updateDropdownListMasterData,
          method: apiMethods.POST,
          key: apiKeys.updateDropdownListMasterData,
          body: sendFormattedId({ ...values, dropListId })
        })).then((response) => {
          pathNavigate(navigate, { response, page: navs.dropdownMaster }); 
      });
      } else {
        dispatch(fetchData({
          url: apiUrls.addDropdownListMasterData,
          method: apiMethods.POST,
          key: apiKeys.addDropdownListMasterData,
          body: sendFormattedId({ ...values })
        })).then((response) => {
          pathNavigate(navigate, { response, page: navs.dropdownMaster }); 
      });
      }
    }
  };
  

  const handleClickBack = () => {
    navigate(navs.dropdownMaster);
  };

  const handleDropdown = (selectedValues) => {
    setSelectedValues(selectedValues);
  };


  return useMemo(() => ({
    onFinish,
    showDialog,
    modaldropdown,
    modaldropdownlist,
    handleClickBack,
    defaultValue,
    isEditdp,
    isEditdpl,
    handleDropdown,
    selectedValues,
  }), [onFinish, showDialog,
    modaldropdown,
    modaldropdownlist,
    handleDropdown,
    selectedValues,
     handleClickBack, defaultValue,
      isEditdp, isEditdpl]);
}