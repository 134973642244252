import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import history from './history';
import subMasterReducer from './subMaster/subMasterSlice'
import masterReducer from './master/masterSlice'
import authSlice from './master/authSlice';
import fetchReducer from './slices/fetch-slice';
import globalSlice from './slices/global-slice';


const rootReducer = combineReducers( {
  router: connectRouter( history ),
  subMaster: subMasterReducer,
  master: masterReducer,
  auth: authSlice,
  fetch: fetchReducer,
  global: globalSlice

} );

export const store = configureStore( {
  reducer: rootReducer,
  middleware: [routerMiddleware( history ), thunk],
} );

export default store;

