import React from "react";
import EditAction from "../components/edit-action/edit-action";
import {
  requestedFeaturesAll,
  requestedMenuAll,
} from "../../../../api/constants/menuAccess";
import VerifyAccess from "../../../../common/components/verify-access/verify-access";

const { subjectMasterKey } = requestedMenuAll;
const { editKey } = requestedFeaturesAll;

export const column = ({ handleEditClick, isEditDeleteAccess }) => [
  { key: "1", headerName: "S.No", field: "S.No", hide: false, width: 100 },
  {
    key: "2",
    headerName: "Subject Code",
    field: "subjectCode",
    hide: false,
    width: 180,
  },
  {
    key: "3",
    headerName: "Subject Name",
    field: "subjectName",
    hide: false,
    width: 180,
  },
  {
    key: "4",
    headerName: "Active Status",
    field: "activeStatus",
    hide: false,
    width: 180,
  },
  ...(isEditDeleteAccess
    ? [
        {
          key: "5",
          field: "action",
          headerName: "Action",
          width: 150,
          sortable: false,
          disableClickEventBubbling: true,
          renderCell: (params) => (
            <VerifyAccess
              requestedMenu={subjectMasterKey}
              requestedFeature={editKey}
            >
              <EditAction row={params.row} onRowEdit={handleEditClick} />
            </VerifyAccess>
          ),
        },
      ]
    : []),
];
