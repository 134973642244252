import React, { useEffect } from "react";
import CustomTable from "../../../common/components/CustomTableView";
import { column } from "./components/column";
import UseAcademicMaster from "./hooks/UseAcademicMaster";
import { BsPlusLg } from 'react-icons/bs';
import Button from 'react-bootstrap/Button';
import { Tooltip } from "@mui/material";
import { requestedFeaturesAll, requestedMenuAll } from "../../../api/constants/menuAccess";
import VerifyAccess  from "../../../common/components/verify-access/verify-access";
import { isUserHasAccess } from "../../../common/helpers/helpers";
import { useSelector } from "react-redux";



const AcademicMaster = ({ setTopTitle }) => {
  const userData = useSelector((state) => state.auth);
  const entitlement = userData?.userData?.data?.entitlement || {};

  useEffect(() => {
    setTopTitle("Academic Master");
  }, [setTopTitle]);
  const {academicMasterKey} = requestedMenuAll;
  const {createKey, editKey, deleteKey} = requestedFeaturesAll;
  const isEditDeleteAccess = isUserHasAccess(academicMasterKey, editKey, entitlement)
  || isUserHasAccess(academicMasterKey, deleteKey, entitlement);

  const actionButton = (
    <Tooltip placement="bottom" title={"Add"}>
          <VerifyAccess requestedMenu={academicMasterKey} requestedFeature={createKey}>
      <Button
        onClick={() => {
          onClickAdd();
        }}
        className="btn btn-primary me-2 px-md-3 px-sm-4"
      >
        <BsPlusLg size={12} />
      </Button>
      </VerifyAccess>
    </Tooltip>
  );

  const { onClickAdd, onRowEdit, academicMasterLoading, academicMasterValues } =
    UseAcademicMaster();

  return (
    <div className="h-screen">
      <CustomTable
        dataSource={academicMasterValues}
        loading={academicMasterLoading}
        column={column({ onRowEdit, isEditDeleteAccess })}
        actionButton={actionButton}
        title={"Academic Master List"}
      />
    </div>
  );
};

export default AcademicMaster;
