import { useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getData } from "../../../../@app/slices/helpers/helpers";
import { apiKeys, apiMethods, apiUrls } from '../../../../api/constants/index';
import { fetchData } from '../../../../@app/slices/fetch-slice';
import navs from '../../../../api/constants/navs'

export default function useAdmissionMaster() {
  const state = useSelector((store) => store);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  

  const admissionMasterValues = getData(state, apiKeys.admissionMasterData, []);

  useEffect(() => {
    dispatch(fetchData({
      url: apiUrls.admissionMasterData,
      method: apiMethods.GET,
      key: apiKeys.admissionMasterData,
    }));
  }, [dispatch]);

  const onClickAdd = () => {
    navigate(navs.studentMasterForm);
  };


  const handleEditClick = (data) => {
    navigate(navs.studentMasterForm, {
      state: { data, isEdit: true }
    });
  };

  return useMemo(() => ({
    onClickAdd,
    handleEditClick,
    admissionMasterValues
  }), [onClickAdd, handleEditClick, admissionMasterValues]);
}
