import React from "react";
import EditAction from "../components/edit-action/edit-action";
import {
  requestedFeaturesAll,
  requestedMenuAll,
} from "../../../../api/constants/menuAccess";
import VerifyAccess from "../../../../common/components/verify-access/verify-access";
const { standrdMasterTabKey, sectionMasterTabKey } = requestedMenuAll;
const { editKey } = requestedFeaturesAll;

export const columnStandard = ({ handleEditStandard, isEditDeleteAccess }) => [
  { key: "1", headerName: "S.No", field: "S.No", hide: false, width: 180 },
  {
    key: "2",
    headerName: "Standard",
    field: "standard",
    hide: false,
    width: 120,
  },
  {
    key: "3",
    headerName: "Active Status",
    field: "activeStatus",
    hide: false,
    width: 120,
  },
  ...(isEditDeleteAccess
    ? [
        {
          key: "4",
          field: "action",
          headerName: "Action",
          width: 150,
          sortable: false,
          disableClickEventBubbling: true,
          renderCell: (params) => (
            <VerifyAccess
              requestedMenu={standrdMasterTabKey}
              requestedFeature={editKey}
            >
              <EditAction row={params.row} onRowEdit={handleEditStandard} />
            </VerifyAccess>
          ),
        },
      ]
    : []),
];

export const columnSection = ({ handleEditSection, isEditDeleteAccess2 }) => [
  { key: "1", headerName: "S.No", field: "S.No", hide: false, width: 180 },
  {
    key: "2",
    headerName: "Section",
    field: "section",
    hide: false,
    width: 120,
  },
  {
    key: "3",
    headerName: "Active Status",
    field: "activeStatus",
    hide: false,
    width: 120,
  },
  ...(isEditDeleteAccess2
    ? [
        {
          key: "4",
          field: "action",
          headerName: "Action",
          width: 150,
          sortable: false,
          disableClickEventBubbling: true,
          renderCell: (params) => (
            <VerifyAccess
              requestedMenu={sectionMasterTabKey}
              requestedFeature={editKey}
            >
              <EditAction row={params.row} onRowEdit={handleEditSection} />
            </VerifyAccess>
          ),
        },
      ]
    : []),
];
