import { apiKeys, apiMethods, apiStatuses, apiUrls } from '../../api/constants/index';
import { clearData, fetchData } from '../../@app/slices/fetch-slice';
import { getData, getStatus } from '../../@app/slices/helpers/helpers';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetSubjectOptions() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getSubjectOption = getData(state, apiKeys.subjectMasterData, []) || [];
  const getSubjectStatus = getStatus(state, apiKeys.subjectMasterData, '');
  const getSubjectLoading = apiStatuses.pending === getSubjectStatus;

  useEffect(() => {
    dispatch(
      fetchData({
        url: apiUrls.subjectMasterData,
        method: apiMethods.GET,
        key: apiKeys.subjectMasterData
      })
    );
  }, [dispatch]);

  const getSubjectOptionData= useMemo(() => {
    return getSubjectOption.filter((option) => option.activeStatus === 1)
    .map(({ id, subjectName }) => ({
      value: id,
      label: subjectName
    }))
  }, [getSubjectOption]);

  useEffect(
    () => () => {
      dispatch(clearData({ key: [apiKeys.subjectMasterData,] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      getSubjectOption,
      getSubjectOptionData,
      getSubjectStatus,
      getSubjectLoading
    }),
    [getSubjectOption,
      getSubjectOptionData,
      getSubjectStatus,
      getSubjectLoading]
  );
}

export default useGetSubjectOptions;
