import React from 'react';

export const columnMenuAccessForm = (handleCheckboxChange) => [
  { key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 100 },
  { key: '2', headerName: 'Menu List Name', field: 'menuListName', hide: false, width: 250 },
  {
    key: '3',
    headerName: 'All CRUD',
    id: "all-crud",
    hide: false,
    width: 120,
    renderCell: (params) => {
      const handleAllCheckboxChange = () => {
        const newState = params.row.create === 0 ? 1 : 0;
        params.row.create = newState;
        params.row.view = newState;
        params.row.edit = newState;
        params.row.delete = newState;
        params.row.download = newState;
        handleCheckboxChange(params.row, 'all');
      };

      return (
        <input
          type="checkbox"
          checked={params.row.create === 1 && params.row.view === 1 && params.row.edit === 1 && 
            params.row.delete === 1 && params.row.download === 1}
          onChange={handleAllCheckboxChange}
        />
      );
    },
  },
  {
    headerName: 'Create',
    field: 'CREATE',
    renderCell: (params) => {
      const handleCreateCheckboxChange = () => {
        params.row.create = params.row.create === 1 ? 0 : 1;
        handleCheckboxChange(params.row, 'create');
      };

      return (
        <input
          type="checkbox"
          checked={params.row.create === 1}
          onChange={handleCreateCheckboxChange}
        />
      );
    },
  },
  {
    headerName: 'View',
    field: 'VIEW',
    renderCell: (params) => {
      const handleViewCheckboxChange = () => {
        params.row.view = params.row.view === 1 ? 0 : 1;
        handleCheckboxChange(params.row, 'view');
      };

      return (
        <input
          type="checkbox"
          checked={params.row.view === 1}
          onChange={handleViewCheckboxChange}
        />
      );
    },
  },
  {
    headerName: 'Edit',
    field: 'EDIT',
    renderCell: (params) => {
      const handleEditCheckboxChange = () => {
        params.row.edit = params.row.edit === 1 ? 0 : 1;
        handleCheckboxChange(params.row, 'edit');
      };

      return (
        <input
          type="checkbox"
          checked={params.row.edit === 1}
          onChange={handleEditCheckboxChange}
        />
      );
    },
  },
  {
    headerName: 'Delete',
    field: 'DELETE',
    renderCell: (params) => {
      const handleDeleteCheckboxChange = () => {
        params.row.delete = params.row.delete === 1 ? 0 : 1;
        handleCheckboxChange(params.row, 'delete');
      };

      return (
        <input
          type="checkbox"
          checked={params.row.delete === 1}
          onChange={handleDeleteCheckboxChange}
        />
      );
    },
  },
  {
    headerName: 'Download',
    field: 'DOWNLOAD',
    renderCell: (params) => {
      const handleDownloadCheckboxChange = () => {
        params.row.download = params.row.download === 1 ? 0 : 1;
        handleCheckboxChange(params.row, 'download');
      };

      return (
        <input
          type="checkbox"
          checked={params.row.download === 1}
          onChange={handleDownloadCheckboxChange}
        />
      );
    },
  },
];
