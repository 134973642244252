import { apiKeys, apiMethods, apiStatuses, apiUrls } from '../../api/constants/index';
import { clearData, fetchData } from '../../@app/slices/fetch-slice';
import { getData, getStatus } from '../../@app/slices/helpers/helpers';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetRoleOptions() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getRoleOption = getData(state, apiKeys.roleMasterData, []) || [];
  const getRoleStatus = getStatus(state, apiKeys.roleMasterData, '');
  const getRoleLoading = apiStatuses.pending === getRoleStatus;

  useEffect(() => {
    dispatch(
      fetchData({
        url: apiUrls.roleMasterData,
        method: apiMethods.GET,
        key: apiKeys.roleMasterData
      })
    );
  }, [dispatch]);

  const getRoleOptionData= useMemo(() => {
    return getRoleOption.filter((option) => option.activeStatus === 1)
    .map(({ roleId, roleName }) => ({
      value: roleId,
      label: roleName
    }))
  }, [getRoleOption]);

  useEffect(
    () => () => {
      dispatch(clearData({ key: [apiKeys.standardOptions,] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      getRoleOption,
      getRoleOptionData,
      getRoleStatus,
      getRoleLoading
    }),
    [getRoleOption,
      getRoleOptionData,
      getRoleStatus,
      getRoleLoading]
  );
}

export default useGetRoleOptions;
