/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./styles.css";
import { FaDatabase, FaUserAlt, FaEmpire } from "react-icons/fa";
import { RiLayoutGridFill } from "react-icons/ri";
import { Layout, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import logo from "../../logo.jpg";
import TopNavMenu from "./TopNavMenu";
import RoutesComponent from "./Routes";
import Footer from "./Footer";
import { useDispatch } from "react-redux";
import { logOutReducer } from "../../@app/master/authSlice";
import {
  requestedFeaturesAll,
  requestedMenuAll,
} from "../../api/constants/menuAccess";

const { Sider, Content } = Layout;

function App() {
  const [collapsed, setCollapsed] = useState(true);
  const navigate = useNavigate();
  const [TopTitle, setTopTitle] = useState("Dashboard");
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("userData")) || {};
  const entitlement = userData?.data?.entitlement || {};

  const {
    dashboardKey,
    academicMasterKey,
    employeeMasterKey,
    termMasterKey,
    standrdMasterKey,
    dropdownMasterKey,
    subjectMasterKey,
    roleMasterKey,
    locationMasterKey,
    menuMasterKey,
    admissionKey,
    enquiryKey,
    studentKey,
    timeTableKey,
    classAllotmentKey,
    classTransferKey,
    staffMasterKey,
    studentHistoryReportKey,
    enquiryReportKey,
    admissionReportKey,
    dropReportKey,
    enqAndAdmisnKey,
    pendingFeesReportKey,
    feesCollectionReportKey,
    busStudentsReportKey,
    waiveOffReportKey,
    feesConcessionReportKey,
    studentsFeesStructureReportKey,
    teacherAttendanceReportKey,
    studentAttendanceReportKey,
    expenseReportKey,
    studentDetailsReportKey,
    followupReportKey,
    menuAccessReportKey,
    feesTypeKey,
    feesCategoryKey,
    feesAllotmentKey,
    feesCollectionKey,
    feesWaiveOffKey,
  } = requestedMenuAll;
  const { readKey } = requestedFeaturesAll;

  const Dashboard = [dashboardKey];
  const Masters = [academicMasterKey, employeeMasterKey];
  const SubMasters = [
    termMasterKey,
    standrdMasterKey,
    dropdownMasterKey,
    subjectMasterKey,
    roleMasterKey,
    locationMasterKey,
    menuMasterKey,
  ];
  const StudentMasters = [
    admissionKey,
    enquiryKey,
    studentKey,
    timeTableKey,
    classAllotmentKey,
    classTransferKey,
  ];
  const StaffMasters = [staffMasterKey];
  const FeesManagement = [
    feesTypeKey,
    feesCategoryKey,
    feesAllotmentKey,
    feesCollectionKey,
    feesWaiveOffKey,
  ];
  const Reports = [
    studentHistoryReportKey,
    enquiryReportKey,
    admissionReportKey,
    dropReportKey,
    enqAndAdmisnKey,
    pendingFeesReportKey,
    feesCollectionReportKey,
    busStudentsReportKey,
    waiveOffReportKey,
    feesConcessionReportKey,
    studentsFeesStructureReportKey,
    teacherAttendanceReportKey,
    studentAttendanceReportKey,
    expenseReportKey,
    studentDetailsReportKey,
    followupReportKey,
    menuAccessReportKey,
  ];

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const updateScreenSize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    updateScreenSize();
    window.addEventListener("resize", updateScreenSize);
    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  const handleLogout = () => {
    dispatch(logOutReducer());
    navigate("/login");
  };

  return (
    <Layout style={{ height: "100vh" }}>
      <Sider
        trigger={null}
        width={150}
        collapsible
        collapsed={collapsed}
        style={{ transition: "0.5s" }}
        className={`${collapsed ? "d-flex" : "d-none"}`}
      >
        <div style={{ textAlign: "left", marginTop: "10px" }}>
          <img
            src={logo}
            alt="logo"
            style={{ width: "110%", padding: "35px" }}
            className="nav-logo"
          />
        </div>
        <Menu
          mode="vertical"
          onClick={({ key }) => {
            if (key === "logout") {
              handleLogout();
            } else {
              navigate(key);
            }
          }}
          style={{ backgroundColor: "black", color: "white" }}
        >
          {Dashboard.length >= 1 && (
            <Menu.Item key="/dashboard" className="menu side-nav">
              <RiLayoutGridFill
                size={28}
                color="#f5a60b"
                className="menu-icon"
              />
              <span className="menu-title">Dashboard</span>
            </Menu.Item>
          )}

          {Masters.length >= 1 && (
            <Menu.SubMenu
              key="masters"
              className="side-nav maintext"
              title={
                <div>
                  <FaDatabase size={20} color="#f5a60b" className="menu-icon" />
                  <span className="menu-title">Master</span>
                </div>
              }
            >
              {[
                {
                  key: "/employeeMaster",
                  icon: <FaUserAlt size={17} />,
                  title: "Employee Master",
                  requestedMenu: employeeMasterKey,
                },
                {
                  key: "/academicMaster",
                  icon: <FaUserAlt size={17} />,
                  title: "Academic Master",
                  requestedMenu: academicMasterKey,
                },
              ].map(({ key, icon, title, requestedMenu }) =>
                entitlement[requestedMenu]?.includes(readKey) ? (
                  <Menu.Item key={key} icon={icon}>
                    {title}
                  </Menu.Item>
                ) : null
              )}
            </Menu.SubMenu>
          )}

          {SubMasters.length >= 1 && (
            <Menu.SubMenu
              key="subMasters"
              className="side-nav maintext"
              title={
                <div>
                  <FaDatabase size={20} color="#f5a60b" className="menu-icon" />
                  <span className="menu-title">Sub Master</span>
                </div>
              }
            >
              {[
                {
                  key: "/dropdownMaster",
                  icon: <FaEmpire size={17} />,
                  title: "Dropdown Master",
                  requestedMenu: dropdownMasterKey,
                },
                {
                  key: "/roleMaster",
                  icon: <FaEmpire size={17} />,
                  title: "Role Master",
                  requestedMenu: roleMasterKey,
                },
                {
                  key: "/standardMaster",
                  icon: <FaEmpire size={17} />,
                  title: "Standard Master",
                  requestedMenu: standrdMasterKey,
                },
                {
                  key: "/subjectMaster",
                  icon: <FaEmpire size={17} />,
                  title: "Subject Master",
                  requestedMenu: subjectMasterKey,
                },
                {
                  key: "/termMaster",
                  icon: <FaEmpire size={17} />,
                  title: "Term Master",
                  requestedMenu: termMasterKey,
                },
                {
                  key: "/locationMaster",
                  icon: <FaEmpire size={17} />,
                  title: "Location Master",
                  requestedMenu: locationMasterKey,
                },
                {
                  key: "/menuMaster",
                  icon: <FaEmpire size={17} />,
                  title: "Menu Master",
                  requestedMenu: menuMasterKey,
                },
              ].map(({ key, icon, title, requestedMenu }) => {
                return entitlement[requestedMenu]?.includes(readKey) ? (
                  <Menu.Item key={key} icon={icon}>
                    {title}
                  </Menu.Item>
                ) : null;
              })}
            </Menu.SubMenu>
          )}
          {StudentMasters.length >= 1 && (
            <Menu.SubMenu
              key="studentmasters"
              className="side-nav maintext"
              title={
                <div>
                  <FaDatabase size={20} color="#f5a60b" className="menu-icon" />
                  <span className="menu-title">Students</span>
                </div>
              }
            >
              {[
                {
                  key: "/admissionMaster",
                  icon: <FaUserAlt size={17} />,
                  title: "Admission",
                  requestedMenu: admissionKey,
                },
                {
                  key: "/enquiryMaster",
                  icon: <FaUserAlt size={17} />,
                  title: "Enquiry",
                  requestedMenu: enquiryKey,
                },
                {
                  key: "/studentMaster",
                  icon: <FaUserAlt size={17} />,
                  title: "Student",
                  requestedMenu: studentKey,
                },
                {
                  key: "/timeTable",
                  icon: <FaUserAlt size={17} />,
                  title: "Time Table",
                  requestedMenu: timeTableKey,
                },
                {
                  key: "/classAllotment",
                  icon: <FaUserAlt size={17} />,
                  title: "Class Allotment",
                  requestedMenu: classAllotmentKey,
                },
                {
                  key: "/classTransfer",
                  icon: <FaUserAlt size={17} />,
                  title: "Class Transfer",
                  requestedMenu: classTransferKey,
                },
              ].map(({ key, icon, title, requestedMenu }) =>
                entitlement[requestedMenu]?.includes(readKey) ? (
                  <Menu.Item key={key} icon={icon}>
                    {title}
                  </Menu.Item>
                ) : null
              )}
            </Menu.SubMenu>
          )}
          {StaffMasters.length >= 1 && (
            <Menu.SubMenu
              key="staffmasters"
              className="side-nav maintext"
              title={
                <div>
                  <FaDatabase size={20} color="#f5a60b" className="menu-icon" />
                  <span className="menu-title">Staff</span>
                </div>
              }
            >
              {[
                {
                  key: "/staffMaster",
                  icon: <FaUserAlt size={17} />,
                  title: "Staff Master",
                  requestedMenu: staffMasterKey,
                },
              ].map(({ key, icon, title, requestedMenu }) =>
                entitlement[requestedMenu]?.includes(readKey) ? (
                  <Menu.Item key={key} icon={icon}>
                    {title}
                  </Menu.Item>
                ) : null
              )}
            </Menu.SubMenu>
          )}
          {FeesManagement.length >= 1 && (
            <Menu.SubMenu
              key="feesmanagement"
              className="side-nav maintext"
              title={
                <div>
                  <FaDatabase size={20} color="#f5a60b" className="menu-icon" />
                  <span className="menu-title">Fees Management</span>
                </div>
              }
            >
              {[
                {
                  key: "/feesType",
                  icon: <FaUserAlt size={10} />,
                  title: "Fees Type",
                  requestedMenu: feesTypeKey,
                },
                {
                  key: "/feesCategory",
                  icon: <FaUserAlt size={17} />,
                  title: "Fees Category",
                  requestedMenu: feesCategoryKey,
                },
                {
                  key: "/feesAllotment",
                  icon: <FaUserAlt size={17} />,
                  title: "Fees Allotment",
                  requestedMenu: feesAllotmentKey,
                },
                {
                  key: "/feesCollection",
                  icon: <FaUserAlt size={17} />,
                  title: "Fees Collection",
                  requestedMenu: feesCollectionKey,
                },
                {
                  key: "/feesWaiveoff",
                  icon: <FaUserAlt size={17} />,
                  title: "Fees Waiveoff",
                  requestedMenu: feesWaiveOffKey,
                },
              ].map(({ key, icon, title, requestedMenu }) =>
                entitlement[requestedMenu]?.includes(readKey) ? (
                  <Menu.Item key={key} icon={icon}>
                    {title}
                  </Menu.Item>
                ) : null
              )}
            </Menu.SubMenu>
          )}

          {Reports.length >= 1 && (
            <Menu.SubMenu
              key="reports"
              className="side-nav maintext"
              title={
                <div>
                  <FaDatabase size={20} color="#f5a60b" className="menu-icon" />
                  <span className="menu-title">Reports</span>
                </div>
              }
            >
              {[
                {
                  key: "/studentHistoryReport",
                  icon: <FaUserAlt size={17} />,
                  title: "Student History Report",
                  requestedMenu: studentHistoryReportKey,
                },
                {
                  key: "/enquiryReport",
                  icon: <FaUserAlt size={17} />,
                  title: "Enquiry Report",
                  requestedMenu: enquiryReportKey,
                },
                {
                  key: "/admissionReport",
                  icon: <FaUserAlt size={17} />,
                  title: "Admission Report",
                  requestedMenu: admissionReportKey,
                },
                {
                  key: "/dropReport",
                  icon: <FaUserAlt size={17} />,
                  title: "Drop Report",
                  requestedMenu: dropReportKey,
                },
                {
                  key: "/enqAdmCount",
                  icon: <FaUserAlt size={17} />,
                  title: "Enq & Adm Count",
                  requestedMenu: enqAndAdmisnKey,
                },
                {
                  key: "/pendingFeesReport",
                  icon: <FaUserAlt size={17} />,
                  title: "Pending Fees Report",
                  requestedMenu: pendingFeesReportKey,
                },
                {
                  key: "/feesCollectionReport",
                  icon: <FaUserAlt size={17} />,
                  title: "Fees Collection Report",
                  requestedMenu: feesCollectionReportKey,
                },
                {
                  key: "/busStudentsReport",
                  icon: <FaUserAlt size={17} />,
                  title: "Bus Students Report",
                  requestedMenu: busStudentsReportKey,
                },
                {
                  key: "/waiveoffReport",
                  icon: <FaUserAlt size={17} />,
                  title: "Waive-off Report",
                  requestedMenu: waiveOffReportKey,
                },
                {
                  key: "/feesConcessionReport",
                  icon: <FaUserAlt size={17} />,
                  title: "Fees Concession Report",
                  requestedMenu: feesConcessionReportKey,
                },
                {
                  key: "/studentFeesStructure",
                  icon: <FaUserAlt size={17} />,
                  title: "Student Fees Sructure",
                  requestedMenu: studentsFeesStructureReportKey,
                },
                {
                  key: "/studentAttendanceReport",
                  icon: <FaUserAlt size={17} />,
                  title: "Student Attendance Report",
                  requestedMenu: studentAttendanceReportKey,
                },
                {
                  key: "/teacherAttendanceReport",
                  icon: <FaUserAlt size={17} />,
                  title: "Teacher Attendance Report",
                  requestedMenu: teacherAttendanceReportKey,
                },
                {
                  key: "/expenseReport",
                  icon: <FaUserAlt size={17} />,
                  title: "Expense Report",
                  requestedMenu: expenseReportKey,
                },
                {
                  key: "/studentDetails",
                  icon: <FaUserAlt size={17} />,
                  title: "Student Details",
                  requestedMenu: studentDetailsReportKey,
                },
                {
                  key: "/followupReport",
                  icon: <FaUserAlt size={17} />,
                  title: "Follow-up Report",
                  requestedMenu: followupReportKey,
                },
                {
                  key: "/menuAccessReport",
                  icon: <FaUserAlt size={17} />,
                  title: "Menu Access Report",
                  requestedMenu: menuAccessReportKey,
                },
              ].map(({ key, icon, title, requestedMenu }) =>
                entitlement[requestedMenu]?.includes(readKey) ? (
                  <Menu.Item key={key} icon={icon}>
                    {title}
                  </Menu.Item>
                ) : null
              )}
            </Menu.SubMenu>
          )}

          {isMobile && (
            <Menu.Item
              key="logout"
              className="menu side-nav"
              onClick={handleLogout}
            >
              <RiLayoutGridFill
                size={28}
                color="#f5a60b"
                className="menu-icon"
              />
              <span className="menu-title">Logout</span>
            </Menu.Item>
          )}
        </Menu>
      </Sider>
      <Layout style={{ height: "100vh" }}>
        <TopNavMenu
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          TopTitle={TopTitle}
        />
        <ContentComponent setTopTitle={setTopTitle} />
        <Footer />
      </Layout>
    </Layout>
  );
}

function ContentComponent({ setTopTitle }) {
  const navigate = useNavigate();
  useEffect(() => {
    const loginStatus = localStorage.getItem("loginStatus") === "true";
    if (!loginStatus) {
      navigate(`/login`);
    }
  }, [navigate]);

  return (
    <div
      style={{ height: "100vh", backgroundColor: "#F4F5F7", overflow: "auto" }}
    >
      <RoutesComponent setTopTitle={setTopTitle} />
    </div>
  );
}

export default App;
