const CREATE = "CREATE";
const READ = "READ";
const EDIT = "EDIT";
const DELETE = "DELETE";
const DOWNLOAD = "DOWNLOAD";

export const requestedMenuAll = {
  dashboardKey: "DASHBOARD",
  customerMasterKey: "Customer_Master",
  employeeMasterKey: "Employee_Master",
  academicMasterKey: "Academic_Year_Master",
  termMasterKey: "Term_Master",
  standrdMasterKey: "Standard_Master",
  standrdMasterTabKey: "Standard_Tab_Master",
  sectionMasterTabKey: "Section_Tab_Master",
  dropdownMasterKey: "Dropdown_Master",
  dropdownMasterTabKey: "Dropdown_Tab_Master",
  dropdownListMasterTabKey: "DropdownList_Tab_Master",
  subjectMasterKey: "Subject_Master",
  roleMasterKey: "Role_Master",
  roleMasterTabKey: "Role_Tab_Master",
  designationMasterTabKey: "Designation_Tab_Master",
  locationMasterKey: "Location_Master",
  menuMasterKey: "Menu_Master",
  studentKey: "Student",
  admissionKey: "Admission",
  enquiryKey: "Enquiry",
  timeTableKey: "Time_Table",
  classAllotmentKey: "Class_Allotment",
  classTransferKey: "Class_Transfer",
  feesTypeKey: "Fees_Type",
  feesCategoryKey: "Fees_Category",
  feesAllotmentKey: "Fees_Allotment",
  feesCollectionKey: "Fees_Collection",
  feesWaiveOffKey: "Fees_Waiveoff",
  vehicleMasterKey: "Vehicle_Master",
  busStageMasterKey: "Bus_Stage_Master",
  busRouteMasterKey: "Bus_Route_Master",
  busStopsMasterKey: "Bus_Stops",
  busStudentsKey: "Bus_Students",
  staffMasterKey: "Staff_Master",
  studentHistoryReportKey: "Student_History_Report",
  enquiryReportKey: "Enquiry_Report",
  admissionReportKey: "Admission_Report",
  dropReportKey: "Drop_Report",
  enqAndAdmisnKey: "Enq_&_Adm_Count",
  pendingFeesReportKey: "Pending_Fees_Report",
  feesCollectionReportKey: "Fees_Collection_Report",
  busStudentsReportKey: "Bus_Students_Report",
  waiveOffReportKey: "Waive-off_Report",
  feesConcessionReportKey: "Fees_Concession_Report",
  studentsFeesStructureReportKey: "Student_Fees_Structure",
  teacherAttendanceReportKey: "Teacher_Attendance_Report",
  studentAttendanceReportKey: "Student_Attendance_Report",
  expenseReportKey: "Expense_Report",
  studentDetailsReportKey: "Student_Details",
  followupReportKey: "Follow-up_Report",
  menuAccessReportKey: "Menu_Access_Report",
};

export const requestedFeaturesAll = {
  createKey: CREATE,
  readKey: READ,
  editKey: EDIT,
  deleteKey: DELETE,
  downloadKey: DOWNLOAD,
};
