import React from "react";
import CustomTable from "../../../common/components/CustomTableView";
import { column } from "./components/column";
import UseFeesTypeMaster from "./hooks/UseFeesTypeMaster";
import { Tooltip } from "@mui/material";
import { Button } from "react-bootstrap";
import { BsPlusLg } from "react-icons/bs";

const FeesType = ({ setTopTitle }) => {
  setTopTitle("Fees Type");
  const actionButton = (
    <Tooltip placement="bottom" title={"Add"}>
      <Button
        onClick={() => {
          onClickAdd();
        }}
        className="btn btn-primary me-2 px-md-3 px-sm-4"
      >
        <BsPlusLg size={12} />
      </Button>
    </Tooltip>
  );

  const { onClickAdd, handleEditClick, feesTypeValues } = UseFeesTypeMaster();

  return (
    <div className="h-screen pad-lg">
      <CustomTable
        dataSource={feesTypeValues}
        column={column({ handleEditClick })}
        actionButton={actionButton}
        title={"Subject Master List"}
      />
    </div>
  );
};

export default FeesType;
