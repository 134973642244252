import { useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getData, getStatus } from "../../../@app/slices/helpers/helpers";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from '../../../api/constants/index';
import { fetchData } from '../../../@app/slices/fetch-slice';
import navs from '../../../api/constants/navs'

export default function useStaffMaster() {
  const state = useSelector((store) => store);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  

  const enquiryMasterValues = getData(state, apiKeys.enquiryMasterData, []);
  const enquiryMasterStatus = getStatus(state, apiKeys.enquiryMasterData, '');
  const enquiryMasterLoading = apiStatuses.pending === enquiryMasterStatus;

  useEffect(() => {
    dispatch(fetchData({
      url: apiUrls.enquiryMasterData,
      method: apiMethods.GET,
      key: apiKeys.enquiryMasterData,
    }));
  }, [dispatch]);

  const onClickAdd = () => {
    navigate(navs.staffMasterForm, {
      state: { isEdit: false }
    });
  };

  const handleEditClick = (data) => {
    navigate(navs.staffMasterForm, {
      state: { data, isEdit: true }
    });
  };

  return useMemo(() => ({
    onClickAdd,
    handleEditClick,
    enquiryMasterLoading,
    enquiryMasterValues
  }), [onClickAdd, handleEditClick, enquiryMasterLoading,
     enquiryMasterValues]);
}
