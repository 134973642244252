import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment'; // Import moment library

const CustomDatePicker = ({ value, onChange, disabledDate }) => {
  const handleDateChange = (date, dateString) => {
    onChange(dateString);
  };

  const disabledDateFunc = (current) => {
    // Example: Disable dates after today
    return current && current > moment().endOf('day');
  };

  return (
    <DatePicker
      style={{
        color: "black",
        fontWeight: "bold",
        borderColor: "black",
        boxShadow: "none",
        textAlign: "center",
        width: "100%"
      }}
      picker='date'
      value={value ? moment(value, 'YYYY-MM-DD') : null} // Convert value to moment object if provided
      format='YYYY-MM-DD'
      disabledDate={disabledDate || disabledDateFunc} // Ensure disabledDate is correctly handled
      onChange={handleDateChange}
    />
  );
};

export default CustomDatePicker;
