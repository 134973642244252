import { apiKeys, apiMethods, apiUrls } from '../../../../../api/constants/index';
import { fetchData } from '../../../../../@app/slices/fetch-slice';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo, useState, } from 'react';
import {useLocation, useNavigate} from 'react-router';
import navs from '../../../../../api/constants/navs';
import { pathNavigate, sendFormattedId } from '../../../../../common/helpers/helpers';


export default function useSubjectMasterForm({getFeesData}) {
  const dispatch = useDispatch();
  // const {state} = useLocation();
  const { state: locationState } = useLocation(); // Rename state to locationState
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const isEdit = locationState?.isEdit || false;
  let defaultValue = locationState?.data;
  const [selectedFees, setSelectedFees] = useState([]);

  console.log(locationState,'***');

  const onFinish = (values) => {
    setShowDialog(false);
    if(isEdit === true){
      dispatch(fetchData({
        url: apiUrls.updateFeesAllotmentData,
        method: apiMethods.POST,  
        key: apiKeys.updateFeesAllotmentData,
        body: sendFormattedId({...values, acadId })
      })).then((response) => {
        pathNavigate(navigate, { response, page: navs.feesAllotment }); 
    });
    }else{
      dispatch(fetchData({
        url: apiUrls.addFeesAllotmentData,
        method: apiMethods.POST,
        key: apiKeys.addFeesAllotmentData,
        body: sendFormattedId({...values })
        })).then((response) => {
        pathNavigate(navigate, { response, page: navs.feesAllotment }); 
    });
    }
  }


  useEffect(() => {
    getFeesData();
  }, [getFeesData]);

  const handleClickBack = () => {
    navigate(navs.feesAllotment);
  };

  const handleFeesName = (selectedFees) => {
    setSelectedFees(selectedFees);
  };

  return useMemo(() => ({
    onFinish,
    showDialog,
    handleClickBack,
    defaultValue,
    handleFeesName,
    selectedFees,
    isEdit
  }), [onFinish, showDialog,
    handleClickBack, handleFeesName, selectedFees,
   defaultValue, isEdit]);
}