import { useMemo, useEffect, } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getData, getStatus, } from "../../../../@app/slices/helpers/helpers";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from '../../../../api/constants/index';
import { fetchData } from '../../../../@app/slices/fetch-slice';
import navs from '../../../../api/constants/navs';

export default function useStandardMaster() {

  const state = useSelector((store) => store);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const roleMasterValues = getData(state, apiKeys.roleMasterData, {});
  const roleMasterStatus = getStatus(state, apiKeys.roleMasterData, '');
  const roleMasterLoading = apiStatuses.pending === roleMasterStatus;
  const designationMasterValues = getData(state, apiKeys.designationMasterData, {});
  const designationMasterStatus = getStatus(state, apiKeys.designationMasterData, '');
  const designationMasterLoading = apiStatuses.pending === designationMasterStatus;
  

  useEffect(() => {
    dispatch(fetchData({
      url: apiUrls.roleMasterData,
      method: apiMethods.GET,
      key: apiKeys.roleMasterData,
    }))
  }, [dispatch,]);

  useEffect(() => {
    dispatch(fetchData({
      url: apiUrls.designationMasterData,
      method: apiMethods.GET,
      key: apiKeys.designationMasterData,
    }))
  }, [dispatch,]);

  const onClickAddRole = () => {
    navigate(navs.roleMasterForm, {
      state: { modalrole: true}
    });
  };

  const handleEditRole = (data) => {
    navigate(navs.roleMasterForm, {
      state: {data, isEditdp: true, modalrole: true}
    });
  };

  const onClickAddDesignation = () => {
    navigate(navs.roleMasterForm, {
      state: {modaldesignation: true}
    });
  };

  const handleEditDesignation = (data) => {
    navigate(navs.roleMasterForm, {
      state: {data, isEditdpl: true, modaldesignation: true}
    });
  };


  return useMemo(() => ({
    onClickAddRole,
    handleEditRole,
    onClickAddDesignation,
    handleEditDesignation,
    roleMasterValues,
    designationMasterValues,
    roleMasterLoading,
    designationMasterLoading
  }), [
    onClickAddRole,
    handleEditRole,
    onClickAddDesignation,
    handleEditDesignation,
    roleMasterValues,
    designationMasterValues,
    roleMasterLoading,
    designationMasterLoading

  ]
  )

}