import React, {  } from 'react';
import CustomTable from '../../../common/components/CustomTimeTable';
import { column } from './components/column';
import UseTimeTableMaster from './hooks/UseTimeTableMaster';
import { Tooltip } from '@mui/material';
import { Button } from 'react-bootstrap';
import { BsPlusLg } from 'react-icons/bs';


const TimeTableMaster = ({ setTopTitle }) => {
  
    setTopTitle('Time Table');
    const actionButton = (
      <Tooltip placement="bottom" title={"Add"}>
        <Button
          onClick={() => {
            onClickAdd();
          }}
          className="btn btn-primary me-2 px-md-3 px-sm-4"
        >
          <BsPlusLg size={12} />
        </Button>
      </Tooltip>
    );
  const {
    onClickAdd,
    handleEditClick,
    timeTableMasterLoading,
    timeTableMasterValues
  } = UseTimeTableMaster();

  return (
    <div className="h-screen">
      <CustomTable
        dataSource={timeTableMasterValues}
        loading={timeTableMasterLoading}
        column={column({ handleEditClick })}
        actionButton={actionButton}
        title={"Time Table"}
      />
    </div>
  );
}

export default TimeTableMaster;
