import axios from 'axios';
import { openSnackbar } from './snackbar';
import { getResponseInterceptor } from './helpers/helpers';


const baseUrl = window.location.origin;

const instance = axios.create({
  baseURl: baseUrl,
  withCredentials: true
});

const fetchApi = async ({ url, method, body, headers = {}, signal, onUploadProgress, ...rest }) =>
  instance({
    url,
    method,
    data: body,
    headers,
    signal,
    onUploadProgress,
    ...rest
  });

instance.interceptors.response.use(
  (response) =>
    new Promise((resolve) => {
      if (response.config && response.config.successMsg) {
        // alert(response.config.successMsg || 'API Succeeded');
        openSnackbar({
          open: true,
          message: (
            response?.data?.response?.message || response.config.successMsg || 'API Succeeded'
          ),
          variant: 'alert',
          alert: {
            color: 'success'
          }
        });
      }
      resolve(getResponseInterceptor(response));
    }),
  (error) => {
    if (error.config && error.config.failureMsg) {
      // alert(error.config.failureMsg || 'API Failed');
      openSnackbar({
        open: true,
        message: (
          error?.data?.response?.message || error.config.failureMsg || 'API Failed'
        ),
        variant: 'alert',
        alert: {
          color: 'error'
        }
      });
    }
    return Promise.reject(error);
  }
);

export default fetchApi;
