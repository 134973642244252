import { apiKeys, apiMethods, apiUrls } from '../../../../../api/constants/index';
import { fetchData } from '../../../../../@app/slices/fetch-slice';
import { useDispatch, useSelector } from 'react-redux';
import { getData } from "../../../../../@app/slices/helpers/helpers";
import { useMemo, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { pathNavigate, sendFormattedId } from '../../../../../common/helpers/helpers';
import navs from '../../../../../api/constants/navs';

function useMenuMasterForm() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);
  const { state: locationState } = useLocation();
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const isEdit = locationState?.isEdit || false;
  let defaultValue = locationState?.data;
  const screenId = defaultValue?.screenId;

  const mainMenuData = getData(state, apiKeys.getMenuMasterData, []);
  console.log(isEdit,'****')

  const onFinish = (values) => {
    setShowDialog(false);
    if(isEdit === true){
      dispatch(fetchData({
        url: apiUrls.updateMenuListData,
        method: apiMethods.POST,
        key: apiKeys.updateMenuListData,
        body: sendFormattedId({...values, screenId })
      })).then((response) => {
        pathNavigate(navigate, { response, page: navs.menuMaster }); 
    });
    }else{
      dispatch(fetchData({
        url: apiUrls.addMenuListData,
        method: apiMethods.POST,
        key: apiKeys.addMenuListData,
        body: sendFormattedId({...values })
        })).then((response) => {
        pathNavigate(navigate, { response, page: navs.menuMaster }); 
    });
    }
  }

  useEffect(() => {
    dispatch(fetchData({
      url: apiUrls.getMenuMasterData,
      method: apiMethods.GET,
      key: apiKeys.getMenuMasterData,
    }))
  }, [dispatch]);

  const getMenuName = useMemo(() => {
    return mainMenuData.filter((option) => option.activeStatus === 1)
    .map(({ id, name }) => ({
      value: id,
      label: name
    }))
  }, [mainMenuData]);

  const handleClickBack = () => {
    navigate(navs.menuMaster);
  };

  return useMemo(() => ({
    onFinish,
    showDialog,
    handleClickBack,
    mainMenuData,
    defaultValue,
    getMenuName,
    isEdit
  }), [onFinish, showDialog, handleClickBack,
    mainMenuData, defaultValue, getMenuName, isEdit]);
}

export default useMenuMasterForm;
