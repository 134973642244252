// RadioYesButton.js
import React from 'react';
import { Radio } from 'antd';

const RadioYesButton = ({ value, onChange }) => {
  const handleChange = (e) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <Radio.Group buttonStyle='solid' size='middle' onChange={handleChange} value={value}>
      <Radio.Button className='active' value='yes'>
        Yes
      </Radio.Button>
      <Radio.Button className='in-active' value='no'>
        No
      </Radio.Button>
    </Radio.Group>
  );
};

export default RadioYesButton;
