import { apiKeys, apiMethods, apiUrls } from '../../../../../api/constants/index';
import { fetchData } from '../../../../../@app/slices/fetch-slice';
import { useDispatch } from 'react-redux';
import { useMemo, useState } from 'react';
import {useLocation, useNavigate} from 'react-router';
import navs from '../../../../../api/constants/navs';
import { pathNavigate, sendFormattedId } from '../../../../../common/helpers/helpers';

export default function useTermMasterForm() {
  const dispatch = useDispatch();
  // const {state} = useLocation();
  const { state: locationState } = useLocation(); // Rename state to locationState
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const isEdit = locationState?.isEdit || false;
  let defaultValue = locationState?.data;
  const termId = defaultValue?.termId;

  console.log(isEdit);

  const onFinish = (values) => {
  console.log('///');

    setShowDialog(false);
    if(isEdit === true){
      dispatch(fetchData({
        url: apiUrls.updateTermMasterData,
        method: apiMethods.POST,
        key: apiKeys.updateTermMasterData,
        body: sendFormattedId({...values, termId })
      })).then((response) => {
        pathNavigate(navigate, { response, page: navs.termMaster }); 
    });
    }else{
      dispatch(fetchData({
        url: apiUrls.addTermMasterData,
        method: apiMethods.POST,
        key: apiKeys.addTermMasterData,
        body: sendFormattedId({...values })
        })).then((response) => {
        pathNavigate(navigate, { response, page: navs.termMaster }); 
    });
    }

  }

  const handleClickBack = () => {
    navigate(navs.termMaster);
  };


  return useMemo(() => ({
    onFinish,
    showDialog,
    handleClickBack,
    defaultValue,
    isEdit
  }), [onFinish, showDialog, handleClickBack, defaultValue, isEdit]);
}