import React, { useEffect } from 'react';
import CustomTable from '../../../common/components/CustomTableView';
import { columnDistrict, columnState, columnCountry } from './components/column';
import UseLocationMaster from './hooks/UseLocationMaster';
import { Tabs, Col,  } from "antd";
import { Tooltip } from '@mui/material';
import { Button } from 'react-bootstrap';
import { BsPlusLg } from 'react-icons/bs';

const DistrictMaster = ({ setTopTitle }) => {
  const { TabPane } = Tabs;
  const actionButton = (
    <Tooltip placement="bottom" title={"Add"}>
      <Button
        onClick={() => {
          onClickAddStd();
        }}
        className="btn btn-primary me-2 px-md-3 px-sm-4"
      >
        <BsPlusLg size={12} />
      </Button>
    </Tooltip>
  );
  const actionButton2 = (
    <Tooltip placement="bottom" title={"Add"}>
      <Button
        onClick={() => {
          onClickAddSec();
        }}
        className="btn btn-primary me-2 px-md-3 px-sm-4"
      >
        <BsPlusLg size={12} />
      </Button>
    </Tooltip>
  );
  const actionButton3 = (
    <Tooltip placement="bottom" title={"Add"}>
      <Button
        onClick={() => {
          onClickAddSec();
        }}
        className="btn btn-primary me-2 px-md-3 px-sm-4"
      >
        <BsPlusLg size={12} />
      </Button>
    </Tooltip>
  );

  useEffect(() => {
    setTopTitle('Location Master');
  }, [setTopTitle]);

  const {
    onClickAddStd,
    handleEditClickStd,
    onClickAddSec,
    handleEditClickSec,
    districtMasterValues,
    stateMasterValues,
    countryMasterValues
  } = UseLocationMaster();

  return (
    <>
      <div className='pad-lg'>
        <Col span={"24"}>
          <Tabs
            centered
            type='card'
            defaultActiveKey="1"
          >
            <TabPane
              style={{ width: "100%", color: "#52d963" }}
              tab="District"
              key="1"
              className="ant-tabs-tab-active-btn"
            >
              <CustomTable
                dataSource={districtMasterValues}
                column={columnDistrict}
                handleEditClick={handleEditClickStd}
                actionButton={actionButton}
                title={"District Master List"}
              />
            </TabPane>
            <TabPane
              tab="State"
              key="2"
            >
              <CustomTable
                dataSource={stateMasterValues}
                column={columnState}
                handleEditClick={handleEditClickSec}
                actionButton={actionButton2}
                title={"District Master List"}
              />
            </TabPane>
            <TabPane
              tab="Country"
              key="3"
            >
              <CustomTable
                dataSource={countryMasterValues}
                column={columnCountry}
                handleEditClick={handleEditClickSec}
                actionButton={actionButton3}
                title={"District Master List"}
              />
            </TabPane>
          </Tabs>
        </Col>
      </div>

    </>
  );
}

export default DistrictMaster;
