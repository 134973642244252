import React, { useEffect } from 'react';
import { Card, Button, Col, Row, Form, Input, Radio } from 'antd';
import ConfirmOnExit from '../../../../common/components/confirmOnExit/ConfirmOnExit';
import UseFeesAllotmentForm from './hooks/UseFeesAllotmentForm';
import CustomDropdown from '../../../../common/components/Dropdown/dropdown';
import useGetStandardOptions from '../../../../common/hooks/useGetStandardOptions';
import useGetAcademicOptions from '../../../../common/hooks/useGetAcademicMaster';
import useGetFeesNameOptions from '../../../../common/hooks/useGetFeesName';

function FeesAllotmentForm({ setTopTitle }) {
  setTopTitle('Fees Allotment');
  const { getStandardOptionData, getStandardLoading } = useGetStandardOptions();
  const { getAcademicYear, getAcademicLoading } = useGetAcademicOptions();
  const { getFeesNameOptions, getFeesData, getFeesNameLoading } = useGetFeesNameOptions();
  const { onFinish, showDialog, handleClickBack, isEdit, handleFeesName, selectedFees, defaultValue }
   = UseFeesAllotmentForm({ getFeesData });
  const [form] = Form.useForm();

  useEffect(() => {
    if (getStandardOptionData && getStandardOptionData.length > 0) {
      const initialValues = {};
      getStandardOptionData.forEach((option, index) => {
        initialValues[`standard_${index}`] = option.value;
      });
      form.setFieldsValue(initialValues);
    }
  }, [getStandardOptionData]);

  return (
    <Card>
      <ConfirmOnExit showModel={showDialog} />
      <Row>
        <Col span={24}>
          <Form
            name='basic'
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
            autoComplete='off'
            initialValues={{
              feesName: defaultValue?.feesId,
              academicYear: defaultValue?.academicYear,
              standard: defaultValue?.std,
              feesAmount: defaultValue?.feesAmount,
              activeStatus: defaultValue?.activeStatus === 'In Active' ? 'inActive' : 'active'
            }}
            form={form}>
            <Row gutter={[15, 0]} justify="center">
              <Col md={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item name={`feesName`} label='Fees Name'>
                  <CustomDropdown
                    options={getFeesNameOptions}
                    maxTagCount={0}
                    onChange={handleFeesName}
                    value={selectedFees}
                    loading={getFeesNameLoading}
                    mode="single"
                    showSearch={true}
                    placeholder="Select an option"
                  />
                </Form.Item>
              </Col>
              <Col md={{ span: 6 }} xs={{ span: 24 }}>
                <Form.Item name={`academicYear`} label='Academic Year'>
                  <CustomDropdown
                    options={getAcademicYear}
                    maxTagCount={0}
                    loading={getAcademicLoading}
                    mode="single"
                    showSearch={true}
                    placeholder="Select an option"
                  />
                </Form.Item>
              </Col>
            </Row>
            {
              (selectedFees === 3 || selectedFees === 4) ? (
                getStandardOptionData.map((standard, index) => (
                  <Row gutter={[15, 0]} key={index} justify="center">
                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item
                        label='Standard'
                        name={`standard_${index}`}
                        rules={[{ required: true, message: 'Please select the Standard!' }]}>
                        <CustomDropdown
                          options={getStandardOptionData}
                          maxTagCount={0}
                          loading={getStandardLoading}
                          mode="single"
                          showSearch={true}
                          placeholder="Select an option"
                          onChange={(value) => form.setFieldsValue({ [`standard_${index}`]: value })}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item
                        label='Fees Amount'
                        name={`feesAmount_${index}`}
                        rules={[{ required: true, message: 'Please input the Fees Amount!' }]}>
                        <Input placeholder='Fees Amount' />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 6 }} xs={{ span: 24 }}>
                      <Form.Item
                        name={`activeStatus_${index}`}
                        label='Status'
                        initialValue='active'
                        rules={[{ required: true, message: 'Please select your status' }]}>
                        <Radio.Group buttonStyle='solid' size='middle'>
                          <Radio.Button className='active' value='active'>Active</Radio.Button>
                          <Radio.Button className='in-active' value='inActive'>In-Active</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                ))
              ) : (
                <Row gutter={[15, 0]} justify="center">
                  <Col md={{ span: 6 }} xs={{ span: 24 }}>
                    <Form.Item
                      label='Standard'
                      name={`standard`}
                      rules={[{ required: true, message: 'Please select the Standard!' }]}>
                      <CustomDropdown
                        options={getStandardOptionData}
                        maxTagCount={0}
                        loading={getStandardLoading}
                        mode="single"
                        showSearch={true}
                        placeholder="Select an option"
                      />
                    </Form.Item>
                  </Col>
                  <Col md={{ span: 6 }} xs={{ span: 24 }}>
                    <Form.Item
                      label='Fees Amount'
                      name={`feesAmount`}
                      rules={[{ required: true, message: 'Please input the Fees Amount!' }]}>
                      <Input placeholder='Fees Amount' />
                    </Form.Item>
                  </Col>
                  <Col md={{ span: 6 }} xs={{ span: 24 }}>
                    <Form.Item
                      name={`activeStatus`}
                      label='Status'
                      initialValue='active'
                      rules={[{ required: true, message: 'Please select your status' }]}>
                      <Radio.Group buttonStyle='solid' size='middle'>
                        <Radio.Button className='active' value='active'>Active</Radio.Button>
                        <Radio.Button className='in-active' value='inActive'>In-Active</Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              )
            }
            <Col span={24}>
              <Row gutter={[15, 15]} style={{ justifyContent: 'end' }}>
                <Col span={12} style={{ textAlign: 'right' }}>
                  <Form.Item className='mx-2'>
                    <Button className='orangeFactory' type='primary' htmlType='submit'>
                      {isEdit ? 'Update' : 'Add'}
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button onClick={handleClickBack}>Back</Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Form>
        </Col>
      </Row>
    </Card>
  );
}

export default FeesAllotmentForm;
