import React from "react";
import EditAction from "../components/edit-action/edit-action";
import {
  requestedFeaturesAll,
  requestedMenuAll,
} from "../../../../api/constants/menuAccess";
import VerifyAccess from "../../../../common/components/verify-access/verify-access";
const { enquiryKey } = requestedMenuAll;
const { editKey } = requestedFeaturesAll;

export const column = ({ handleEditClick, isEditDeleteAccess }) => [
  { key: "1", headerName: "S.No", field: "S.No", hide: false },
  { key: "1", headerName: "id", field: "id", hide: true, width: 100 },
  {
    key: "2",
    headerName: "Student Name",
    field: "studentName",
    hide: false,
    width: 180,
  },
  {
    key: "3",
    headerName: "Parent Name",
    field: "parentName",
    hide: false,
    width: 180,
  },
  {
    key: "4",
    headerName: "Parent Number",
    field: "parentNumber",
    hide: false,
    width: 180,
  },
  {
    key: "5",
    headerName: "Enquiry Type",
    field: "enquiryType",
    hide: false,
    width: 180,
  },
  {
    key: "6",
    headerName: "Enquiry For",
    field: "enquiryFor",
    hide: false,
    width: 180,
  },
  {
    key: "7",
    headerName: "Enquiry By",
    field: "enquiryBy",
    hide: false,
    width: 180,
  },
  {
    key: "8",
    headerName: "Referred By",
    field: "referredBy",
    hide: false,
    width: 180,
  },
  {
    key: "9",
    headerName: "Enquiry Standard",
    field: "enqStd",
    hide: false,
    width: 180,
  },
  {
    key: "10",
    headerName: "Enquiry Status",
    field: "enquiryStatus",
    hide: false,
    width: 180,
  },
  {
    key: "11",
    headerName: "Academic Year",
    field: "acadYear",
    hide: false,
    width: 180,
  },
  {
    key: "12",
    headerName: "Enquiry Data",
    field: "enquiryDate",
    hide: false,
    width: 180,
  },
  {
    key: "13",
    headerName: "Active Status",
    field: "activeStatus",
    hide: false,
    width: 180,
  },
  ...(isEditDeleteAccess
    ? [
        {
          key: "14",
          field: "action",
          headerName: "Action",
          width: 150,
          sortable: false,
          disableClickEventBubbling: true,
          renderCell: (params) => (
            <VerifyAccess requestedMenu={enquiryKey} requestedFeature={editKey}>
              <EditAction row={params.row} onRowEdit={handleEditClick} />
            </VerifyAccess>
          ),
        },
      ]
    : []),
];
