import { useMemo, useEffect } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getData, getStatus } from "../../../../@app/slices/helpers/helpers";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "../../../../api/constants/index";
import { fetchData } from "../../../../@app/slices/fetch-slice";
import navs from "../../../../api/constants/navs";

export default function useStandardMaster() {
  const state = useSelector((store) => store);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const standardMasterValues = getData(state, apiKeys.standardMasterData, []);
  const standardMasterStatus = getStatus(state, apiKeys.standardMasterData, "");
  const standardMasterLoading = apiStatuses.pending === standardMasterStatus;
  const sectionMasterValues = getData(state, apiKeys.sectionMasterData, []);
  const sectionMasterStatus = getStatus(state, apiKeys.sectionMasterData, "");
  const sectionMasterLoading = apiStatuses.pending === sectionMasterStatus;

  useEffect(() => {
    dispatch(
      fetchData({
        url: apiUrls.standardMasterData,
        method: apiMethods.GET,
        key: apiKeys.standardMasterData,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchData({
        url: apiUrls.sectionMasterData,
        method: apiMethods.GET,
        key: apiKeys.sectionMasterData,
      })
    );
  }, [dispatch]);

  const onClickAddStandard = () => {
    navigate(navs.standardMasterForm, {
      state: { modalstandard: true },
    });
  };

  const handleEditStandard = (data) => {
    navigate(navs.standardMasterForm, {
      state: { data, isEditdp: true, modalstandard: true },
    });
  };

  const onClickAddSection = () => {
    navigate(navs.standardMasterForm, {
      state: { modalsection: true },
    });
  };

  const handleEditSection = (data) => {
    navigate(navs.standardMasterForm, {
      state: { data, isEditdpl: true, modalsection: true },
    });
  };

  return useMemo(
    () => ({
      onClickAddStandard,
      handleEditStandard,
      onClickAddSection,
      handleEditSection,
      standardMasterValues,
      sectionMasterValues,
      standardMasterLoading,
      sectionMasterLoading
    }),
    [
      onClickAddStandard,
      handleEditStandard,
      onClickAddSection,
      handleEditSection,
      standardMasterValues,
      sectionMasterValues,
      standardMasterLoading,
      sectionMasterLoading
    ]
  );
}
