import React from 'react';
import { Input } from 'antd';

const CustomInput = ({ placeholder, name, ...rest }) => {
  return (
    <Input
      placeholder={placeholder}
      name={name}
      {...rest}
      style={{
        fontWeight: "bold",
        borderColor: "black",
        boxShadow: "none",
        textAlign: "left",
        width: "100%"
      }}
    />
  );
};

export default CustomInput;
