import React from 'react';
import { Input, Card, Button, Radio, Col, Row, Form, Modal } from 'antd';
import ConfirmOnExit from '../../../../common/components/confirmOnExit/ConfirmOnExit';
import useFeesCategoryForm from './hooks/UseFeesCategoryForm';
import CustomDropdown from '../../../../common/components/Dropdown/dropdown';


function FeesCategoryForm({ setTopTitle }) {
  setTopTitle('Fees Category Master');

  const {
    onFinish, showDialog, handleClickBack, handleTerm, handleFees, selectedTerm, isEditdp, isEditdpl , defaultValue,
    modalFeesCategory, modalFeesName, selectedFeesCategory, DropdownValue, getTermOptionId
  } = useFeesCategoryForm();

  const [form] = Form.useForm();

  return (
    <>
      <Modal open={modalFeesCategory} width={1000} footer={null} title={'Add Fees Category'}  >
        <Card>
          <ConfirmOnExit showModel={showDialog} />
          <Row>
            <Col span={24}>
              <Form
                name='basic'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{
                  dropDownName: defaultValue?.dropDownName,
                  dropdownListName: defaultValue?.dropdownListName,
                  activeStatus: defaultValue?.activeStatus === 'In Active' ? 'inActive' : 'active'
                }}
                onFinish={onFinish}
                autoComplete='off'
                form={form}>
                <Row gutter={[15, 0]}>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    <Form.Item label='Admission Type' name='dropDownName'>
                      <Input placeholder='Admission Type' name='dropDownName' disabled />
                    </Form.Item>
                  </Col>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    <Form.Item label='Fees Type' name='dropdownListName'
                      rules={[{ required: true, message: 'Please input the fees type!' }]}>
                      <Input placeholder='Fees Type' name='dropdownListName' />
                    </Form.Item>
                  </Col>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    <Form.Item name='activeStatus' label='Status'
                      rules={[{ required: true, message: 'Please select your status' }]}>
                      <Radio.Group buttonStyle='solid' size='middle' name='activeStatus' >
                        <Radio.Button className='active' value='active'>
                          Active
                        </Radio.Button>
                        <Radio.Button className='in-active' value='inActive'>
                          In-Active
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[15, 15]} style={{ justifyContent: 'end' }}>
                      <Col span={12} style={{ textAlign: 'right' }} className='d-flex align-items-center justify-content-end mt-3'>
                        <Form.Item className='mx-2'>
                          <Button className='orangeFactory' type='primary' htmlType='submit'>
                            {isEditdp ? 'Update' : 'Add'}
                          </Button>
                        </Form.Item>
                        {/* </Col>
                    <Col span={12}> */}
                        <Form.Item>
                          <Button onClick={handleClickBack}>Back</Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card>
      </Modal>
      <Modal open={modalFeesName} width={1000} footer={null} title={'Add Fees Name'} >
        <Card>
          <ConfirmOnExit showModel={showDialog} />
          <Row>
            <Col span={24}>
              <Form
                name='basic'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{
                  feesName: defaultValue?.feesName,
                  dropdownListName: defaultValue?.dropdownListName,
                  feesCatId: defaultValue?.feesCatId,
                  Terms: defaultValue?.Terms,
                  activeStatus: defaultValue?.activeStatus === 'In Active' ? 'inActive' : 'active'
                }}
                onFinish={onFinish}
                autoComplete='off'
                form={form}>
                <Row gutter={[15, 0]}>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    <Form.Item label='Admission Type' name='dropDownName'>
                      <Input placeholder='Admission Type' name='dropDownName' disabled />
                    </Form.Item>
                  </Col>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    <Form.Item label='Fees Name' name='feesName'
                      rules={[{ required: true, message: 'Please input the fees type!' }]}>
                      <Input placeholder='Fees Name' name='feesName' />
                    </Form.Item>
                  </Col>
                  <Col md={{ span: 8 }} xs={{ span: 24 }} >
                    <Form.Item name='feesCatId' label='Fees Category'  >
                      <CustomDropdown
                        options={DropdownValue}
                        value={selectedFeesCategory}
                        onChange={handleFees}
                        maxTagCount={0}
                        loading={false}
                        mode="single"
                        showSearch={true}
                        placeholder="Select an option"
                      />
                    </Form.Item>
                  </Col>
                  <Col md={{ span: 8 }} xs={{ span: 24 }} >
                    <Form.Item name='Terms' label='No Of Term'  >
                      <CustomDropdown
                        options={getTermOptionId}
                        value={selectedTerm}
                        onChange={handleTerm}
                        maxTagCount={0}
                        loading={false}
                        mode="single"
                        showSearch={true}
                        placeholder="Select an option"
                      />
                    </Form.Item>
                  </Col>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    <Form.Item name='activeStatus' label='Status'
                      rules={[{ required: true, message: 'Please select your status' }]}>
                      <Radio.Group buttonStyle='solid' size='middle' name='activeStatus' >
                        <Radio.Button className='active' value='active'>
                          Active
                        </Radio.Button>
                        <Radio.Button className='in-active' value='inActive'>
                          In-Active
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[15, 15]} style={{ justifyContent: 'end' }}>
                      <Col span={12} style={{ textAlign: 'right' }} className='d-flex align-items-center justify-content-end mt-3'>
                        <Form.Item className='mx-2'>
                          <Button className='orangeFactory' type='primary' htmlType='submit'>
                            {isEditdpl ? 'Update' : 'Add'}
                          </Button>
                        </Form.Item>
                        {/* </Col>
                    <Col span={12}> */}
                        <Form.Item>
                          <Button onClick={handleClickBack}>Back</Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card>
      </Modal>
    </>
  );
}

export default FeesCategoryForm;
