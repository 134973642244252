import React from 'react';
import EditAction from '../components/edit-action/edit-action';

export const column = ({ handleEditClick }) => [
      {key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 180},
      {key: '2', headerName: 'Term', field: 'term', hide: false, width: 120},
      {key: '3', headerName: 'From Month', field: 'fromMonth', hide: false, width: 120},
      {key: '4', headerName: 'To Month', field: 'toMonth', hide: false, width: 120},
      {key: '5', headerName: 'Active Status', field: 'activeStatus', hide: false, width: 120},
      {
        key: '6',
        field: 'action',
        headerName: 'Action',
        width: 150,
        sortable: false,
        disableClickEventBubbling: true,
        renderCell: (params) => (
          <EditAction row={params.row} onRowEdit={handleEditClick} />
        )
      }
  ];
  