import React from "react";
import EditAction from "../components/edit-action/edit-action";
import VerifyAccess from "../../../../common/components/verify-access/verify-access";
import { requestedFeaturesAll, requestedMenuAll } from "../../../../api/constants/menuAccess";

const {dropdownMasterTabKey, dropdownListMasterTabKey} = requestedMenuAll;
const {editKey} = requestedFeaturesAll;

export const columnDropdown = ({ handleEditDropdown, isEditDeleteAccess }) => [
  { key: "1", headerName: "S.No", field: "S.No", hide: false, width: 80 },
  {
    key: "2",
    headerName: "Short Name",
    field: "ddShortName",
    hide: false,
    width: 120,
  },
  {
    key: "3",
    headerName: "Dropdown Name",
    field: "dropdownName",
    hide: false,
    width: 280,
  },
  {
    key: "4",
    headerName: "Active Status",
    field: "activeStatus",
    hide: false,
    width: 160,
  },
  ...(isEditDeleteAccess
    ? [
        {
          key: "5",
          field: "action",
          headerName: "Action",
          width: 150,
          sortable: false,
          disableClickEventBubbling: true,
          renderCell: (params) => (
            <VerifyAccess
              requestedMenu={dropdownMasterTabKey}
              requestedFeature={editKey}
            >
              <EditAction row={params.row} onRowEdit={handleEditDropdown} />
            </VerifyAccess>
          ),
        },
      ]
    : []),
];

export const columnDropdownList = ({
  handleEditDropdownList,
  isEditDeleteAccess2,
}) => [
  { key: "1", headerName: "S.No", field: "S.No", hide: false, width: 80 },
  {
    key: "2",
    headerName: "Dropdown Name",
    field: "dropDownName",
    hide: false,
    width: 240,
  },
  {
    key: "2",
    headerName: "List Short Name",
    field: "listShortName",
    hide: false,
    width: 150,
  },
  {
    key: "2",
    headerName: "Dropdown List Name",
    field: "dropdownListName",
    hide: false,
    width: 280,
  },
  {
    key: "5",
    headerName: "Active Status",
    field: "activeStatus",
    hide: false,
    width: 160,
  },
  ...(isEditDeleteAccess2
    ? [
        {
          key: "6",
          field: "action",
          headerName: "Action",
          width: 150,
          sortable: false,
          disableClickEventBubbling: true,
          renderCell: (params) => (
            <VerifyAccess
              requestedMenu={dropdownListMasterTabKey}
              requestedFeature={editKey}
            >
              <EditAction row={params.row} onRowEdit={handleEditDropdownList} />
            </VerifyAccess>
          ),
        },
      ]
    : []),
];
