import React from "react";
import EditAction from "../components/edit-action/edit-action";
import {
  requestedFeaturesAll,
  requestedMenuAll,
} from "../../../../api/constants/menuAccess";
import VerifyAccess from "../../../../common/components/verify-access/verify-access";

const { studentKey } = requestedMenuAll;
const { editKey } = requestedFeaturesAll;

export const column = ({ handleEditClick, isEditDeleteAccess }) => [
  { key: "1", headerName: "S.No", field: "S.No", hide: false, width: 80 },
  {
    key: "2",
    headerName: "Admission Number",
    field: "admissionNo",
    hide: false,
    width: 160,
  },
  {
    key: "3",
    headerName: "Student Name",
    field: "stName",
    hide: false,
    width: 180,
  },
  {
    key: "4",
    headerName: "Admission Date",
    field: "doa",
    hide: false,
    width: 150,
  },
  {
    key: "5",
    headerName: "Admission Standard",
    field: "admissionStandard",
    hide: false,
    width: 180,
  },
  {
    key: "6",
    headerName: "Parent Name",
    field: "parentNumber",
    hide: false,
    width: 180,
  },
  {
    key: "7",
    headerName: "Parent Number",
    field: "parentNumber",
    hide: false,
    width: 180,
  },
  {
    key: "8",
    headerName: "Active Status",
    field: "activeStatus",
    hide: false,
    width: 180,
  },
  ...(isEditDeleteAccess? [
  {
    key: "9",
    field: "action",
    headerName: "Action",
    width: 150,
    sortable: false,
    disableClickEventBubbling: true,
    renderCell: (params) => (
      <VerifyAccess
        requestedMenu={studentKey}
        requestedFeature={editKey}
      >
        <EditAction row={params.row} onRowEdit={handleEditClick} />
      </VerifyAccess>
    ),
  },
]:[])
];
