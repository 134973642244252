import React from 'react';
import EditAction from '../components/edit-action/edit-action';

export const columnMenuMaster = ({handleEditClickMenu}) => [
      {key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 80},
      {key: '2', headerName: 'Menu Name', field: 'name', hide: false, width: 250},
      {key: '3', headerName: 'Active Status', field: 'activeStatus', hide: false, width: 200},
      {
        key: '4',
        field: 'action',
        headerName: 'Action',
        width: 150,
        sortable: false,
        disableClickEventBubbling: true,
        renderCell: (params) => (
          <EditAction row={params.row} onRowEdit={handleEditClickMenu} />
        )
      }
  ];
  
  export const columnMenuList = ({handleEditClickList}) => [
    {key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 100},
    {key: '2', headerName: 'Menu Master Name', field: 'menuName', hide: false, width: 250},
    {key: '2', headerName: 'Menu List Name', field: 'name', hide: false, width: 250},
    {key: '3', headerName: 'Active Status', field: 'activeStatus', hide: false, width: 120},
    {
      key: '4',
      field: 'action',
      headerName: 'Action',
      width: 150,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <EditAction row={params.row} onRowEdit={handleEditClickList} />
      )
    }
];

export const columnMenuAccess = ({handleEditClickAccess}) => [
  {key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 100},
  {key: '2', headerName: 'Country Name', field: 'countryName', hide: false, width: 250},
  {key: '3', headerName: 'Active Status', field: 'activeStatus', hide: false, width: 120},
  {
    key: '4',
    field: 'action',
    headerName: 'Action',
    width: 150,
    sortable: false,
    disableClickEventBubbling: true,
    renderCell: (params) => (
      <EditAction row={params.row} onRowEdit={handleEditClickAccess} />
    )
  }
];
