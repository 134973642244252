// const apiServicePath = '/';
import { baseURL } from "../baseURL";

const mockApiBaseUrl = '/mock/'
export const urls = Object.freeze({

  signIn: `${baseURL}web-login`,
  authenticate: `${baseURL}authenticate`,
  
  employeeMaster: `${mockApiBaseUrl}employeeMaster`,
  saveEmployeeMaster: `${mockApiBaseUrl}saveEmployeeMaster`,
  deleteEmployeeMaster: (id) => `${mockApiBaseUrl}deleteEmployeeMaster/${id}`,
  deleteSelectedEmployees: `${mockApiBaseUrl}employee-master/delete`,

  primaryCustomer: `${baseURL}foods-primarycustomer-list`,
  getPrimeCustDataFromSap: `${baseURL}foods-primarycustomer-list`,
  primeCustListBySubzone: `${baseURL}primarycustomer-by-subzone`,
  savePrimaryCustomerMaster: `${baseURL}foods-primarycustomer-store`,
  updatePrimaryCustomerMaster: `${baseURL}foods-primarycustomer-update`,
  deletePrimaryCustomerMaster: (id) => `${baseURL}foods-primarycustomer-destroy/${id}`,
  getEditPrimaryCustomerMaster : (id) => `${baseURL}foods-primarycustomer-show/${id}`,

  outletMaster: `${baseURL}get-outletMaster`,

  //student
  addAdmissionMasterData: `${baseURL}add-admissionMasterData`,
  updateAdmissionMasterData: `${baseURL}update-admissionMasterData`,
  admissionMasterData: `${baseURL}get-admissionMasterData`,

  classAllotmentData: `${baseURL}get-classAllotmentData`,
  addEnquiryMasterData: `${baseURL}add-enquiryData`,
  updateEnquiryMasterData: `${baseURL}update-enquiryData`,
  enquiryMasterData: `${baseURL}get-enquiryMasterData`,
  timeTableMasterData: `${baseURL}get-timeTableMasterData`,

//masters
  addAcademicMasterData: `${baseURL}add-academicMasterData`,
  updateAcademicMasterData: `${baseURL}update-academicMasterData`,
  academicMasterData: `${baseURL}get-academicMasterData`,


//submasters
  addDropdownMasterData: `${baseURL}add-dropdownMasterData`,
  updateDropdownMasterData: `${baseURL}update-dropdownMasterData`,
  dropdownMasterData: `${baseURL}get-dropdownMasterData`,
  addDropdownListMasterData: `${baseURL}add-dropdownListMasterData`,
  updateDropdownListMasterData: `${baseURL}update-dropdownListMasterData`,
  dropdownListMasterData: `${baseURL}get-dropdownListMasterData`,
  roleMasterData: `${baseURL}get-roleMasterData`,
  addRoleMasterData: `${baseURL}add-roleMasterData`,
  updateRoleMasterData: `${baseURL}update-roleMasterData`,
  designationMasterData: `${baseURL}get-designationMasterData`,
  addDesignationMasterData: `${baseURL}add-designationMasterData`,
  updateDesignationMasterData: `${baseURL}update-designationMasterData`,
  standardOptions: `${baseURL}get-standardOptions`,
  standardMasterData: `${baseURL}get-standardMasterData`,
  addStandardMasterData: `${baseURL}add-standardMasterData`,
  updateStandardMasterData: `${baseURL}update-standardMasterData`,
  sectionMasterData: `${baseURL}get-sectionMasterData`,
  addSectionMasterData: `${baseURL}add-sectionMasterData`,
  updateSectionMasterData: `${baseURL}update-sectionMasterData`,
  subjectMasterData: `${baseURL}get-subjectMasterData`,
  addSubjectMasterData: `${baseURL}add-subjectMasterData`,
  updateSubjectMasterData: `${baseURL}update-subjectMasterData`,
  termMasterData: `${baseURL}get-termMasterData`,
  addTermMasterData: `${baseURL}add-termMasterData`,
  updateTermMasterData: `${baseURL}update-termMasterData`,
  getMenuMasterData: `${baseURL}get-menuMasterData`,
  addMenuMasterData: `${baseURL}add-menuMasterData`,
  updateMenuMasterData: `${baseURL}update-menuMasterData`,
  getMenuListData: `${baseURL}get-menuListData`,
  addMenuListData: `${baseURL}add-menuListData`,
  updateMenuListData: `${baseURL}update-menuListData`,
  getMenuAccessData: `${baseURL}get-menuAccessData`,
  addMenuAccessData: `${baseURL}add-menuAccessData`,
  updateMenuAccessData: `${baseURL}update-menuAccessData`,

  //fees management
  feesTypeData: `${baseURL}get-feesTypeData`,
  addFeesTypeData: `${baseURL}add-feesTypeData`,
  updateFeesTypeData: `${baseURL}update-feesTypeData`,
  feesCategoryData: `${baseURL}get-feesCategoryData`,
  addFeesCategoryData: `${baseURL}add-feesCategoryData`,
  updateFeesCategoryData: `${baseURL}update-feesCategoryData`,
  feesNameData: `${baseURL}get-feesNameData`,
  addFeesNameData: `${baseURL}add-feesNameData`,
  updateFeesNameData: `${baseURL}update-feesNameData`,
  feesAllotmentData: `${baseURL}get-feesAllotmentData`,
  editFeesAllotmentData: `${baseURL}edit-feesAllotmentData`,
  addFeesAllotmentData: `${baseURL}add-feesAllotmentData`,
  updateFeesAllotmentData: `${baseURL}update-feesAllotmentData`,

  countryMasterData: `${baseURL}get-countryMasterData`,

  districtMasterData: `${baseURL}get-districtMasterData`,


  stateMasterData: `${baseURL}get-stateMasterData`,








  
  
});

