import React, { useEffect } from 'react';
import CustomTable from '../../../common/components/CustomTableView';
import { columnRole, columnDesignation } from './components/column';
import UseRoleMaster from './hooks/UseRoleMaster';
import { Tabs, Col,  } from "antd";
import { Tooltip } from '@mui/material';
import { Button } from 'react-bootstrap';
import { BsPlusLg } from 'react-icons/bs';
import { requestedFeaturesAll, requestedMenuAll } from '../../../api/constants/menuAccess';
import VerifyAccess from '../../../common/components/verify-access/verify-access';
import { useSelector } from 'react-redux';
import { isUserHasAccess } from '../../../common/helpers/helpers';

const RoleMaster = ({ setTopTitle }) => {
  const {roleMasterTabKey, designationMasterTabKey} = requestedMenuAll;
const {createKey, editKey, deleteKey} = requestedFeaturesAll;
  const userData = useSelector((state) => state.auth);
  const entitlement = userData?.userData?.data?.entitlement || {};

  const isEditDeleteAccess = isUserHasAccess(roleMasterTabKey, editKey, entitlement)
    || isUserHasAccess(roleMasterTabKey, deleteKey, entitlement);
    const isEditDeleteAccess2 = isUserHasAccess(designationMasterTabKey, editKey, entitlement)
    || isUserHasAccess(designationMasterTabKey, deleteKey, entitlement);
  const { TabPane } = Tabs;

  const actionButton = (
    <Tooltip placement="bottom" title={"Add"}>
          <VerifyAccess requestedMenu={roleMasterTabKey} requestedFeature={createKey}>
      <Button
        onClick={() => {
          onClickAddRole();
        }}
        className="btn btn-primary me-2 px-md-3 px-sm-4"
      >
        <BsPlusLg size={12} />
      </Button>
      </VerifyAccess>
    </Tooltip>
  );
  const actionButton2 = (
    <Tooltip placement="bottom" title={"Add"}>
          <VerifyAccess requestedMenu={designationMasterTabKey} requestedFeature={createKey}>
      <Button
        onClick={() => {
          onClickAddDesignation();
        }}
        className="btn btn-primary me-2 px-md-3 px-sm-4"
      >
        <BsPlusLg size={12} />
      </Button>
      </VerifyAccess>
    </Tooltip>
  );
 
  useEffect(() => {
    setTopTitle('Role Master');
  }, [setTopTitle]);

  const {
    onClickAddRole,
    handleEditRole,
    onClickAddDesignation,
    handleEditDesignation,
    roleMasterValues,
    designationMasterValues,
    roleMasterLoading,
    designationMasterLoading
  } = UseRoleMaster();

  return (
    <>
      <div className='pad-lg'>
        <Col span={"24"}>
          <Tabs
            centered
            type='card'
            defaultActiveKey="1"
          >
            <TabPane
              style={{ width: "100%", color: "#52d963" }}
              tab="Role"
              key="3"
              className="ant-tabs-tab-active-btn"
            >
              <CustomTable
                dataSource={roleMasterValues}
                loading={roleMasterLoading}
                column={columnRole({ handleEditRole, isEditDeleteAccess })}
                actionButton={actionButton}
                title={"Role Master List"}
              />
            </TabPane>
            <TabPane
              tab="Designation"
              key="4"
            >
              <CustomTable
                dataSource={designationMasterValues}
                loading={designationMasterLoading}
                column={columnDesignation({ handleEditDesignation, isEditDeleteAccess2 })}
                actionButton={actionButton2}
                title={"Designation Master List"}
              />
            </TabPane>
          </Tabs>
        </Col>
      </div>

    </>
  );
}

export default RoleMaster;
