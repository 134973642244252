import React from "react";
import CustomTable from "../../../common/components/CustomTableView";
import { column } from "./column";
import UseStaffMaster from "./UseStaffMaster";
import { Tooltip } from "@mui/material";
import { Button } from "react-bootstrap";
import { BsPlusLg } from "react-icons/bs";

const StaffMaster = ({ setTopTitle }) => {
  setTopTitle("Staff Master");
  const actionButton = (
    <Tooltip placement="bottom" title={"Add"}>
      <Button
        onClick={() => {
          onClickAdd();
        }}
        className="btn btn-primary me-2 px-md-3 px-sm-4"
      >
        <BsPlusLg size={12} />
      </Button>
    </Tooltip>
  );
  const {
    onClickAdd,
    handleEditClick,
    enquiryMasterLoading,
    enquiryMasterValues,
  } = UseStaffMaster();

  return (
    <div className="h-screen">
      <CustomTable
        dataSource={enquiryMasterValues}
        loading={enquiryMasterLoading}
        column={column}
        handleEditClick={handleEditClick}
        actionButton={actionButton}
        title={"Staff Master List"}
      />
    </div>
  );
};

export default StaffMaster;
