import React from 'react';
import EditAction from '../components/edit-action/edit-action';

export const column = ({ handleEditClick }) => [
  { key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 90, fixed: true },
  { key: '2', headerName: 'Days', field: 'dayName', hide: false, width: 100, fixed: 'left' },
  {
    key: '3',
    headerName: 'Period 1',
    field: 'period1',
    hide: false,
    width: 150,
    fixed: false, // Adjust fixed property as needed
    renderCell: (params) => renderPeriodCell(params, 'period1'),
  },
  {
    key: '4',
    headerName: 'Period 2',
    field: 'period2',
    hide: false,
    width: 150,
    fixed: false, // Adjust fixed property as needed
    renderCell: (params) => renderPeriodCell(params, 'period2'),
  },
  {
    key: '5',
    headerName: 'Period 3',
    field: 'period3',
    hide: false,
    width: 150,
    fixed: false, // Adjust fixed property as needed
    renderCell: (params) => renderPeriodCell(params, 'period3'),
  },
  {
    key: '6',
    headerName: 'Period 4',
    field: 'period4',
    hide: false,
    width: 150,
    fixed: false, // Adjust fixed property as needed
    renderCell: (params) => renderPeriodCell(params, 'period4'),
  },
  {
    key: '7',
    headerName: 'Period 5',
    field: 'period5',
    hide: false,
    width: 150,
    fixed: false, // Adjust fixed property as needed
    renderCell: (params) => renderPeriodCell(params, 'period5'),
  },
  {
    key: '8',
    headerName: 'Period 6',
    field: 'period6',
    hide: false,
    width: 150,
    fixed: false, // Adjust fixed property as needed
    renderCell: (params) => renderPeriodCell(params, 'period6'),
  },
  {
    key: '9',
    headerName: 'Period 7',
    field: 'period7',
    hide: false,
    width: 150,
    fixed: false, // Adjust fixed property as needed
    renderCell: (params) => renderPeriodCell(params, 'period7'),
  },
  {
    key: '10',
    headerName: 'Period 8',
    field: 'period8',
    hide: false,
    width: 150,
    fixed: false, // Adjust fixed property as needed
    renderCell: (params) => renderPeriodCell(params, 'period8'),
  },
  {
    key: '11',
    headerName: 'Period 9',
    field: 'period9',
    hide: true,
    width: 150,
    fixed: false, // Adjust fixed property as needed
    renderCell: (params) => renderPeriodCell(params, 'period9'),
  },
  {
    key: '12',
    headerName: 'Period 10',
    field: 'period10',
    hide: true,
    width: 150,
    fixed: false, // Adjust fixed property as needed
    renderCell: (params) => renderPeriodCell(params, 'period10'),
  },
  {
    key: '13',
    field: 'action',
    headerName: 'Action',
    width: 150,
    sortable: false,
    disableClickEventBubbling: true,
    renderCell: (params) => (
      <EditAction row={params.row} onRowEdit={handleEditClick} />
    )
  }
];

// Function to render the cell content based on the period field
const renderPeriodCell = (params, periodField) => {
  const periodData = JSON.parse(params?.row?.[periodField] || '[]');
  const subject = periodData.length > 0 ? periodData[0]?.subject?.[0] : '';
  const teacher = periodData.length > 0 ? periodData[0]?.teacher?.[0] : '';

  return (
    <div style={{  justifyContent: 'center', alignItems: 'center', }}>
      <div style={{textAlign: 'center', }}>{subject}</div>
      <div style={{textAlign: 'center', }}>{teacher}</div>
    </div>
  );
};
