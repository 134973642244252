import React from "react";
import CustomTable from "../../../common/components/CustomCheckboxTable";
import { column } from "./components/column";
import UseEnquiryMaster from "./hooks/UseEnquiryMaster";
import { Tooltip } from "@mui/material";
import { Button } from "react-bootstrap";
import { BsPlusLg } from "react-icons/bs";
import {
  requestedFeaturesAll,
  requestedMenuAll,
} from "../../../api/constants/menuAccess";
import VerifyAccess from "../../../common/components/verify-access/verify-access";
import { isUserHasAccess } from "../../../common/helpers/helpers";
import { useSelector } from "react-redux";

const EnquiryMaster = ({ setTopTitle }) => {
  const userData = useSelector((state) => state.auth);
  const entitlement = userData?.userData?.data?.entitlement || {};
  const { enquiryKey } = requestedMenuAll;
  const { createKey, editKey, deleteKey } = requestedFeaturesAll;
  const isEditDeleteAccess =
    isUserHasAccess(enquiryKey, editKey, entitlement) ||
    isUserHasAccess(enquiryKey, deleteKey, entitlement);

  console.log(enquiryKey, "555");

  setTopTitle("Enquiry Master");

  const actionButton = (
    <Tooltip placement="bottom" title={"Add"}>
      <VerifyAccess requestedMenu={enquiryKey} requestedFeature={createKey}>
        <Button
          onClick={() => {
            onClickAdd();
          }}
          className="btn btn-primary me-2 px-md-3 px-sm-4"
        >
          <BsPlusLg size={12} />
        </Button>
      </VerifyAccess>
    </Tooltip>
  );

  const {
    onClickAdd,
    onClickSubmit,
    handleEditClick,
    enquiryMasterLoading,
    enquiryMasterValues,
  } = UseEnquiryMaster();

  return (
    <div className="h-screen">
      <CustomTable
        dataSource={enquiryMasterValues}
        loading={enquiryMasterLoading}
        column={column({ handleEditClick, isEditDeleteAccess })}
        actionButton={actionButton}
        onClickSubmit={(selectedRows) => onClickSubmit(selectedRows)}
        title={"Enquiry Master List"}
      />
    </div>
  );
};

export default EnquiryMaster;
