import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import store from "./@app/store";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
