import { notification } from "antd";

export const sendFormattedId = (payload) => {
  if (typeof payload === "object") {
    const results = {};
    for (const [key, value] of Object.entries(payload)) {
      // Check if the value is an array and has objects with 'value' keys
      if (
        Array.isArray(value) &&
        value.every(
          (item) => typeof item === "object" && item !== null && "value" in item
        )
      ) {
        // Map the array to only contain 'value' entries
        results[key] = value.map((item) => item.value);
      } else if (
        typeof value === "object" &&
        value !== null &&
        "value" in value
      ) {
        // Handle dropdown value change for single dropdown objects
        results[key] = value.value;
      } else {
        // Handle radio button 'activeStatus' specifically
        if (key === "activeStatus" && value === "active") {
          results[key] = 1; // Return 1 for "active"
        } else if (key === "activeStatus" && value === "inActive") {
          results[key] = 0; // Return 0 for "inActive"
        } else if (key === "sibling" && value === "Yes") {
          results[key] = 1; // Return 0 for "inActive"
        } else if (key === "sibling" && value === "No") {
          results[key] = 0; // Return 0 for "inActive"
        } else {
          results[key] = value; // Otherwise, assign the original value
        }
      }
    }
    return results;
  }
  return {};
};

export const pathNavigate = async (navigate, { response, page }) => {
  const status = response?.payload?.response?.status;
  const statusText =
    response?.payload?.response?.statusText || "An error occurred";
  const notificationHandler = {
    200: () => notification.success({ description: statusText }),
    201: () => notification.success({ description: statusText }),
    400: () => notification.error({ description: statusText }),
    404: () => notification.error({ description: statusText }),
    500: () => notification.error({ description: statusText }),
    default: () => notification.info({ description: statusText }),
  };
  if (status === 200 || status === 201) {
    navigate(page);
  }
  (notificationHandler[status] || notificationHandler.default)();
};

export const isUserHasAccess = (
  requestedMenu = "",
  requestedFeature = "",
  entitlement = {}
) => {
  return (
    (entitlement[requestedMenu] || "" || []).indexOf(requestedFeature) !== -1
  );
};

export const isUserHasAnyAccess = (requestedMenus = [], entitlement = {}) => {
  return requestedMenus.some((menu) => {
    const menuEntitlement = entitlement[menu];
    return Array.isArray(menuEntitlement) && menuEntitlement.length > 0;
  });
};
