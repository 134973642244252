import { apiKeys, apiMethods, apiStatuses, apiUrls } from '../../api/constants/index';
import { clearMultipleData, fetchData } from '../../@app/slices/fetch-slice';
import { getData, getStatus } from '../../@app/slices/helpers/helpers';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetDropdownOptions() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getDropdownData = getData(state, apiKeys.dropdownMasterData, []) || [];
  const getDropdownStatus = getStatus(state, apiKeys.dropdownMasterData, '');
  const getDropdownLoading = apiStatuses.pending === getDropdownStatus;

  useEffect(() => {
    dispatch(
      fetchData({
        url: apiUrls.dropdownMasterData,
        method: apiMethods.GET,
        key: apiKeys.dropdownMasterData
      })
    );
  }, [dispatch]);

  const getDropdownOptions = useMemo(() => {
    return getDropdownData.filter((option) => option.activeStatus === 1)
    .map(({ dropDownId, dropdownName }) => ({
      value: dropDownId,
      label: dropdownName
    }))
  }, [getDropdownData]);

  useEffect(
    () => () => {
      dispatch(clearMultipleData({ key: [apiKeys.dropdownMasterData,] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      getDropdownData,
      getDropdownOptions,
      getDropdownStatus,
      getDropdownLoading
    }),
    [getDropdownData, getDropdownOptions, getDropdownStatus, getDropdownLoading]
  );
}

export default useGetDropdownOptions;
