import {
  apiKeys,
  apiMethods,
  apiUrls,
} from "../../../../../api/constants/index";
import {
  clearMultipleData,
  fetchData,
} from "../../../../../@app/slices/fetch-slice";
import { useDispatch } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Form } from "antd";
// import { getStatus } from '../../../@app/slices/helpers/helpers';
import navs from "../../../../../api/constants/navs";
import {
  pathNavigate,
  sendFormattedId,
} from "../../../../../common/helpers/helpers";

export default function useEnquiryMasterForm() {
  const dispatch = useDispatch();
  const { state: locationState } = useLocation(); // Rename state to locationState
  // const state = useSelector((store) => store); // Renamed to reduxState
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [selectedEnquiryType, setSelectedEnquiryType] = useState([]);
  const [selectedEnquiryFor, setSelectedEnquiryFor] = useState([]);
  const [selectedStandardOption, setSelectedStandardOption] = useState([]);
  const [selectedAcademicYear, setSelectedAcademicYear] = useState([]);
  const [selectedEnquiryBy, setSelectedEnquiryBy] = useState([]);
  // const [enquiryId, setEnquiryId] = useState("");

  // const addEnquiryMasterStatus = getStatus(state, apiKeys.addEnquiryMasterData, '');

  // console.log(addEnquiryMasterStatus,'---');

  const isEdit = locationState?.isEdit || false; // Updated to use locationState
  let defaultValue = locationState?.data; // Updated to use locationState
  const [form] = Form.useForm();
  const id = defaultValue?.id;

  const onFinish = (values) => {
    setShowDialog(false);
    if (isEdit === true) {
      dispatch(
        fetchData({
          url: apiUrls.updateEnquiryMasterData,
          method: apiMethods.POST,
          key: apiKeys.updateEnquiryMasterData,
          body: sendFormattedId({ ...values, id }),
        })
      ).then((response) => {
        pathNavigate(navigate, { response, page: navs.enquiryMaster });
      });
    } else {
      dispatch(
        fetchData({
          url: apiUrls.addEnquiryMasterData,
          method: apiMethods.POST,
          key: apiKeys.addEnquiryMasterData,
          body: sendFormattedId({ ...values }),
        })
      ).then((response) => {
        pathNavigate(navigate, { response, page: navs.enquiryMaster });
      });
    }
  };

  const handleEnyType = (selectedEnquiryType) => {
    setSelectedEnquiryType(selectedEnquiryType);
  };
  const handleEnyFor = (selectedEnquiryFor) => {
    setSelectedEnquiryFor(selectedEnquiryFor);
  };
  const handleEnyBy = (selectedEnquiryBy) => {
    setSelectedEnquiryBy(selectedEnquiryBy);
  };
  const handleEnyStd = (selectedStandardOption) => {
    setSelectedStandardOption(selectedStandardOption);
  };
  const handleAcdYear = (selectedAcademicYear) => {
    setSelectedAcademicYear(selectedAcademicYear);
  };
  const disabledFutureDates = (current) => {
    const today = new Date();
    return current && current > today;
  };

  const handleClickBack = () => {
    navigate("/enquiryMaster");
  };

  useEffect(
    () => () => {
      dispatch(
        clearMultipleData({
          key: [apiKeys.addEnquiryMasterData, apiKeys.updateEnquiryMasterData],
        })
      );
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      onFinish,
      showDialog,
      handleClickBack,
      handleEnyType,
      handleEnyFor,
      handleEnyBy,
      handleEnyStd,
      handleAcdYear,
      defaultValue,
      selectedEnquiryType,
      selectedEnquiryFor,
      selectedStandardOption,
      selectedAcademicYear,
      selectedEnquiryBy,
      disabledFutureDates,
      isEdit,
      form,
    }),
    [
      onFinish,
      showDialog,
      handleClickBack,
      handleEnyType,
      handleEnyFor,
      handleEnyBy,
      handleEnyStd,
      handleAcdYear,
      defaultValue,
      selectedEnquiryType,
      selectedEnquiryFor,
      selectedStandardOption,
      selectedAcademicYear,
      selectedEnquiryBy,
      disabledFutureDates,
      isEdit,
      form,
    ]
  );
}
