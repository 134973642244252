import React, { useEffect } from 'react';
import CustomTable from '../../../common/components/CustomTableView';
import { column } from './components/column';
import UseTermMaster from './hooks/UseTermMaster';
import { BsPlusLg } from 'react-icons/bs';
import { Tooltip } from '@mui/material';
import { Button } from 'react-bootstrap';

const TermMaster = ({ setTopTitle }) => {
  const actionButton = (
    <Tooltip placement="bottom" title={"Add"}>
      <Button
        onClick={() => {
          onClickAdd();
        }}
        className="btn btn-primary me-2 px-md-3 px-sm-4"
      >
        <BsPlusLg size={12} />
      </Button>
    </Tooltip>
  );
  useEffect(() => {
    setTopTitle('Term Master');
  }, [setTopTitle]);

  const {
    onClickAdd,
    handleEditClick,
    termMasterValues
  } = UseTermMaster();

  return (
    <div className="h-screen pad-lg">
      <CustomTable
        dataSource={termMasterValues}
        column={column({ handleEditClick })}
        actionButton={actionButton}
        title={"Term Master List"}
      />
    </div>
  );
}

export default TermMaster;
