export const columnDistrict = [
      {key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 80},
      {key: '2', headerName: 'District Name', field: 'districtName', hide: false, width: 250},
      {key: '2', headerName: 'State Name', field: 'stateName', hide: false, width: 250},
      {key: '3', headerName: 'Active Status', field: 'activeStatus', hide: false, width: 200},
  ];
  
  export const columnState = [
    {key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 100},
    {key: '2', headerName: 'State Name', field: 'stateName', hide: false, width: 250},
    {key: '3', headerName: 'Country Name', field: 'countryName', hide: false, width: 250},
    {key: '4', headerName: 'Active Status', field: 'activeStatus', hide: false, width: 120},
];

export const columnCountry = [
  {key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 100},
  {key: '2', headerName: 'Country Name', field: 'countryName', hide: false, width: 250},
  {key: '3', headerName: 'Active Status', field: 'activeStatus', hide: false, width: 120},
];
