import React from 'react';
import { useMemo, useState } from 'react';
import {baseURL} from '../../../../../../src/api/baseURL';
import {PlusOutlined} from '@ant-design/icons';
import {Button, } from 'antd';
import UseEnquiryMasterForm from './UseEnquiryMasterForm';

export default function useStudentImageAdd() {
  const {
     defaultValue, form
  } = UseEnquiryMasterForm();

  const getFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const getBase64 = (file) =>
new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});


  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [image, setImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const [imageUpdated, setImageUpdated] = useState(false);
  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleChange = (e) => {
    setFileList(e?.fileList);
    setImage(e?.file?.response?.filename ?? '');
    form.setFieldsValue({studentImage: e?.file?.response?.filename ?? ''});
    setImageUpdated(true);
  };

  
  const props = {
    name: 'image',
    action: `${baseURL}student-imageupload`,
    headers: {
      authorization: 'authorization-text'
    }
  };


  const uploadButton = (
    <Button style={{display: 'flex', direction: 'row'}} icon={<PlusOutlined style={{marginTop: '3px', marginRight: '4px'}} />}>
      <div
        style={{
          marginLeft: '3px'
        }}>
        {defaultValue?.id ? 'Update Image' : 'Upload'}
      </div>
    </Button>
  );
  

  return useMemo(() => ({
    getFile,
    previewImage,
    previewOpen,
    previewTitle,
    fileList,
    image,
    imageUpdated,
    handleChange,
    handleCancel,
    handlePreview,
    uploadButton,
    props,
  }), [ 
    getFile,
    previewImage,
    previewOpen,
    previewTitle,
    fileList,
    image,
    imageUpdated,
    handleChange,
    handleCancel,
    handlePreview,
    uploadButton,
    props,]);
}