import React, { useState, useEffect } from 'react';
import { Input, Card, Button, Col, Row, Form } from 'antd';
import ConfirmOnExit from '../../../../common/components/confirmOnExit/ConfirmOnExit';
import useMenuAccessForm from './hooks/UseMenuAccessForm';
import CustomTable from '../../../../common/components/CustomTableView';
import { columnMenuAccessForm } from '../components/accesscolumn';

function MenuAccessForm({ setTopTitle }) {
  setTopTitle('Menu Master');

  const {
    onFinish,
    showDialog,
    handleClickBack,
    isEdit,
    defaultValue,
    menuAccessData: initialTableData,
    handleEditClickAccess
  } = useMenuAccessForm();

  const [form] = Form.useForm();
  const [menuAccessData, setMenuAccessData] = useState(initialTableData || []);

  useEffect(() => {
    setMenuAccessData(initialTableData || []);
  }, [initialTableData]);

  const updateMenuAccessData = (updatedItem) => {
    setMenuAccessData(prevData => {
      return prevData.map(item => {
        if (item.id === updatedItem.id) {
          return { ...item, ...updatedItem };
        } else {
          return item;
        }
      });
    });
  };

  const handleUpdatedData = (updatedItem) => {
    updateMenuAccessData(updatedItem);
  };

  const handleSubmit = async (values) => {
    const formData = {
      ...values,
      menuAccessData: menuAccessData
    };
    onFinish(formData);
  };

  return (
    <>
      <Card>
        <Form
          name='basic'
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{
            userName: defaultValue?.userName,
            status: defaultValue?.status === 'In Active' ? 'inActive' : 'active'
          }}
          onFinish={handleSubmit}
          autoComplete='off'
          form={form}
        >
          <ConfirmOnExit showModel={showDialog} />
          <Row>
            <Col span={24}>
              <Row gutter={[15, 0]}>
                <Col md={{ span: 6 }} xs={{ span: 24 }}>
                  <Form.Item
                    label='User Name'
                    name='userName'
                    rules={[{ required: true, message: 'Please input the subject code!' }]}
                  >
                    <Input placeholder='User Name' name='userName' />
                  </Form.Item>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }} className='d-flex align-items-center justify-content-end mt-3'>
                  <Form.Item className='mx-2'>
                    <Button className='orangeFactory' type='primary' htmlType='submit'>
                      {isEdit ? 'Update' : 'Create'}
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button onClick={handleClickBack}>Back</Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <CustomTable
            dataSource={menuAccessData}
            column={columnMenuAccessForm(handleUpdatedData)} // Pass the update function to columnMenuAccessForm
            handleEditClick={handleEditClickAccess}
            title={"Access Master List"}
          />

        </Form>
      </Card>
    </>
  );
}

export default MenuAccessForm;
