import { apiKeys, apiMethods, apiUrls } from '../../../../../api/constants/index';
import { fetchData } from '../../../../../@app/slices/fetch-slice';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import {useLocation, useNavigate} from 'react-router';
import { getStatus } from '../../../../../@app/slices/helpers/helpers';
import { pathNavigate, sendFormattedId } from '../../../../../common/helpers/helpers';
import navs from '../../../../../api/constants/navs'


export default function useAcademicMasterForm() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);
  const { state: locationState } = useLocation(); // Rename state to locationState
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const isEdit = locationState?.isEdit || false;
  let defaultValue = locationState?.data;
  const acadId = defaultValue?.acadId;

  const addAcademicMasterStatus  = getStatus(state, apiKeys.addAcademicMasterData, '');
  const updateAcademicMasterStatus  = getStatus(state, apiKeys.updateAcademicMasterData, '');

  const onFinish = (values) => {
    setShowDialog(false);
    if(isEdit === true){
      dispatch(fetchData({
        url: apiUrls.updateAcademicMasterData,
        method: apiMethods.POST,
        key: apiKeys.updateAcademicMasterData,
        body: sendFormattedId({...values, acadId })
      })).then((response) => {
        pathNavigate(navigate, { response, page: navs.academicMaster }); 
    });
    }else{
      dispatch(fetchData({
        url: apiUrls.addAcademicMasterData,
        method: apiMethods.POST,
        key: apiKeys.addAcademicMasterData,
        body: sendFormattedId({...values })
        })).then((response) => {
        pathNavigate(navigate, { response, page: navs.academicMaster }); 
    });
    }
  }

  const handleClickBack = () => {
    navigate(navs.academicMaster);
  };

  useEffect(
    () => () => {
      dispatch(clearMultipleData({ key: [apiKeys.addAcademicMasterData, 
        apiKeys.updateAcademicMasterData,] }));
    },
    [dispatch]
  );


  return useMemo(() => ({
    onFinish,
    showDialog,
    handleClickBack,
    addAcademicMasterStatus,
    updateAcademicMasterStatus,
    defaultValue,
    isEdit
  }), [onFinish, showDialog, handleClickBack,
    addAcademicMasterStatus,
    updateAcademicMasterStatus, defaultValue, isEdit]);
}