import { useMemo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getData, getStatus } from "../../../../@app/slices/helpers/helpers";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from '../../../../api/constants/index';
import { fetchData } from '../../../../@app/slices/fetch-slice';
import navs from '../../../../api/constants/navs'
import useGetStandardOptions from '../../../../common/hooks/useGetStandardMaster';

export default function useEnquiryMaster() {
  const state = useSelector((store) => store);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {getStandardOptionData, getStandardLoading} = useGetStandardOptions();
  const [selectedValues, setSelectedValues] = useState([]);
  

  const enquiryMasterValues = getData(state, apiKeys.classAllotmentData, []);
  const enquiryMasterStatus = getStatus(state, apiKeys.classAllotmentData, '');
  const enquiryMasterLoading = apiStatuses.pending === enquiryMasterStatus;

  useEffect(() => {
    dispatch(fetchData({
      url: apiUrls.classAllotmentData,
      method: apiMethods.GET,
      key: apiKeys.classAllotmentData,
    }));
  }, [dispatch]);

  const onClickAdd = (selectedRows) => {
    // Here you can use selectedRows as needed
    console.log("Selected Rows:", selectedRows);

  };

  const handleChange = (selectedValues) => {
    setSelectedValues(selectedValues);
  };

  const handleEditClick = (data) => {
    navigate(navs.enquiryMasterForm, {
      state: { data, isEdit: true }
    });
  };

  return useMemo(() => ({
    onClickAdd,
    handleEditClick,
    enquiryMasterLoading,
    enquiryMasterValues,
  getStandardOptionData, 
  getStandardLoading, 
  selectedValues,
  handleChange

  }), [onClickAdd, handleEditClick, enquiryMasterLoading, handleChange,
     enquiryMasterValues, getStandardOptionData, getStandardLoading, selectedValues]);
}
