import React, { useEffect } from "react";
import CustomTable from "../../../common/components/CustomTableView";
import { columnCategory, columnName } from "./components/column";
import UseFeesCategoryMaster from "./hooks/UseFeesCategoryMaster";
import CustomDropdown from "../../../common/components/Dropdown/dropdown";
import { Tabs, Col, Button, Row, Form } from "antd";
import { Tooltip } from "@mui/material";
import { BsPlusLg } from "react-icons/bs";

const FeesCategory = ({ setTopTitle }) => {
  useEffect(() => {
    setTopTitle("Fees Category & Name");
  }, [setTopTitle]);

  const actionButton = (
    <Tooltip placement="bottom" title={"Add"}>
      <Button
        onClick={() => {
          onClickAddCat();
        }}
        className="btn btn-primary me-2 px-md-3 px-sm-4"
      >
        <BsPlusLg size={12} />
      </Button>
    </Tooltip>
  );

  const actionButton2 = (
    <Tooltip placement="bottom" title={"Add"}>
      <Button
        onClick={() => {
          onClickAddName();
        }}
        className="btn btn-primary me-2 px-md-3 px-sm-4"
      >
        <BsPlusLg size={12} />
      </Button>
    </Tooltip>
  );

  const {
    onClickAddCat,
    handleEditClickCat,
    onClickAddName,
    handleEditClickName,
    feesCategoryValues,
    selectedValues,
    handleChange,
    DropdownValue,
    handleFormSubmit,
    feesNameValues,
  } = UseFeesCategoryMaster();

  // Define the items for the Tabs
  const items = [
    {
      key: "1",
      label: "Fees Category",
      children: (
        <CustomTable
          dataSource={feesCategoryValues}
          column={columnCategory({ handleEditClickCat })}
          actionButton={actionButton}
          title={"Standard Master List"}
        />
      ),
    },
    {
      key: "2",
      label: "Fees Name",
      children: (
        <>
          <Row gutter={[45, 0]} style={{ justifyContent: "center" }}>
            <Col md={{ span: 6 }} xs={{ span: 24 }}>
              <Form.Item name="dropdownId" label="Fees Category">
                <CustomDropdown
                  options={DropdownValue}
                  onChange={handleChange}
                  value={selectedValues} // Pass selectedValues here
                  maxTagCount={0}
                  loading={false}
                  mode="single"
                  showSearch={true}
                  placeholder="Select an option"
                />
              </Form.Item>
            </Col>

            <Col md={{ span: 2 }} xs={{ span: 24 }}>
              <Form.Item name="submit">
                <Button
                  onClick={handleFormSubmit}
                  style={{ background: "#34b1aa", color: "#ffffff" }}
                  className="btn btn-sm col-lg-1 col-m-2 col-sm-2 h-100 w-auto align-items-center"
                >
                  Filter
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <CustomTable
            dataSource={feesNameValues}
            column={columnName({ handleEditClickName })}
            actionButton={actionButton2}
            title={"Standard Master List"}
          />
        </>
      ),
    },
  ];

  return (
    <div className="pad-lg">
      <Col span={"24"}>
        <Tabs
          centered
          type="card"
          defaultActiveKey="1"
          items={items} // Use items prop here
        />
      </Col>
    </div>
  );
};

export default FeesCategory;
