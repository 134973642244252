import React, { useEffect } from 'react';
import CustomTable from '../../../common/components/CustomTableView';
import { columnMenuMaster, columnMenuList, columnMenuAccess } from './components/column';
import UseMenuMaster from './hooks/UseMenuMaster';
import { Tabs, Col,  } from "antd";
import { Tooltip } from '@mui/material';
import { Button } from 'react-bootstrap';
import { BsPlusLg } from 'react-icons/bs';

const MenuMaster = ({ setTopTitle }) => {
  const { TabPane } = Tabs;
  const actionButton = (
    <Tooltip placement="bottom" title={"Add"}>
      <Button
        onClick={() => {
          onClickAddMenu();
        }}
        className="btn btn-primary me-2 px-md-3 px-sm-4"
      >
        <BsPlusLg size={12} />
      </Button>
    </Tooltip>
  );
  const actionButton2 = (
    <Tooltip placement="bottom" title={"Add"}>
      <Button
        onClick={() => {
          onClickAddList();
        }}
        className="btn btn-primary me-2 px-md-3 px-sm-4"
      >
        <BsPlusLg size={12} />
      </Button>
    </Tooltip>
  );
  const actionButton3 = (
    <Tooltip placement="bottom" title={"Add"}>
      <Button
        onClick={() => {
          onClickAddAccess();
        }}
        className="btn btn-primary me-2 px-md-3 px-sm-4"
      >
        <BsPlusLg size={12} />
      </Button>
    </Tooltip>
  );

  useEffect(() => {
    setTopTitle('Menu Master');
  }, [setTopTitle]);

  const {
    onClickAddMenu,
    handleEditClickMenu,
    onClickAddList,
    onClickAddAccess,
    handleEditClickList,
    handleEditClickAccess,
    menuValues,
    screenValues,
    accessValues
  } = UseMenuMaster();

  return (
    <>
      <div className='pad-lg'>
        <Col span={"24"}>
          <Tabs
            centered
            type='card'
            defaultActiveKey="1"
          >
            <TabPane
              style={{ width: "100%", color: "#52d963" }}
              tab="Menu Master"
              key="1"
              className="ant-tabs-tab-active-btn"
            >
              <CustomTable
                dataSource={menuValues}
                column={columnMenuMaster({ handleEditClickMenu })}
                actionButton={actionButton}
                title={"Menu Master List"}
              />
            </TabPane>
            <TabPane
              tab="Menu List"
              key="2"
            >
              <CustomTable
                dataSource={screenValues}
                column={columnMenuList({ handleEditClickList })}
                actionButton={actionButton2}
                title={"Modules Master List"}
              />
            </TabPane>
            <TabPane
              tab="Menu Access"
              key="3"
            >
              <CustomTable
                dataSource={accessValues}
                column={columnMenuAccess({ handleEditClickAccess })}
                actionButton={actionButton3}
                title={"Access Master List"}
              />
            </TabPane>
          </Tabs>
        </Col>
      </div>

    </>
  );
}

export default MenuMaster;
