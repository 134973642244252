import React from 'react';
import EditAction from '../components/edit-action/edit-action';
import VerifyAccess from '../../../../common/components/verify-access/verify-access';
import { requestedFeaturesAll, requestedMenuAll } from '../../../../api/constants/menuAccess';

const {academicMasterKey} = requestedMenuAll;
const {editKey} = requestedFeaturesAll;
export const column = ({ onRowEdit, isEditDeleteAccess }) => [
  { key: '1', headerName: 'S.No', field: 'id', hide: false, width: 80 },
  { key: '2', headerName: 'Academic Code', field: 'acadCode', hide: false, width: 160 },
  { key: '3', headerName: 'Academic Name', field: 'acadName', hide: false, width: 160 },
  { key: '4', headerName: 'Academic Year', field: 'acadYear', hide: false, width: 160 },
  { key: '5', headerName: 'Start Year', field: 'startYear', hide: false, width: 120 },
  { key: '6', headerName: 'End Year', field: 'endYear', hide: false, width: 120 },
  { key: '7', headerName: 'Active Status', field: 'activeStatus', hide: false, width: 120 },
  ...(isEditDeleteAccess
    ? [
        {
          key: '8',
          field: 'action',
          headerName: 'Action',
          width: 150,
          sortable: false,
          disableClickEventBubbling: true,
          renderCell: (params) => (
            <VerifyAccess requestedMenu={academicMasterKey} requestedFeature={editKey}>
            <EditAction row={params.row} onRowEdit={onRowEdit} />
            </VerifyAccess>
          )
        }
      ]
    : [])
];
