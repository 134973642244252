import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const globalSlice = createSlice({
  name: 'globalSlice',
  initialState,
  reducers: {
    setGlobalState: (state, { payload: { key, data, mergeFlag } }) => {
      if (key & mergeFlag) {
        state[key] = {
          ...((state[key] && JSON.parse(JSON.stringify(state[key]))) || {}),
          ...data // new value
        };
      } else if (key) {
        state[key] = data;
      }
    },
    clearGlobalState: (state, { payload: { key } }) => {
      delete state[key];
    }
  }
});

export const { setGlobalState, clearGlobalState } = globalSlice.actions;

export default globalSlice.reducer;
