import React, { useEffect } from "react";
import CustomTable from "../../../common/components/CustomTableView";
import { columnStandard, columnSection } from "./components/column";
import UseStandardMaster from "./hooks/UseStandardMaster";
import { Tabs, Col } from "antd";
import { Tooltip } from "@mui/material";
import { Button } from "react-bootstrap";
import { BsPlusLg } from "react-icons/bs";
import { useSelector } from "react-redux";
import {
  requestedFeaturesAll,
  requestedMenuAll,
} from "../../../api/constants/menuAccess";
import VerifyAccess from "../../../common/components/verify-access/verify-access";
import { isUserHasAccess } from "../../../common/helpers/helpers";

const StandardMaster = ({ setTopTitle }) => {
  const { TabPane } = Tabs;
  const { standrdMasterTabKey, sectionMasterTabKey } = requestedMenuAll;
  const { createKey, editKey, deleteKey } = requestedFeaturesAll;
  const userData = useSelector((state) => state.auth);
  const entitlement = userData?.userData?.data?.entitlement || {};

  const isEditDeleteAccess =
    isUserHasAccess(standrdMasterTabKey, editKey, entitlement) ||
    isUserHasAccess(standrdMasterTabKey, deleteKey, entitlement);
  const isEditDeleteAccess2 =
    isUserHasAccess(sectionMasterTabKey, editKey, entitlement) ||
    isUserHasAccess(sectionMasterTabKey, deleteKey, entitlement);

    console.log(createKey,'54646');
  const actionButton = (
    <Tooltip placement="bottom" title={"Add"}>
      <VerifyAccess
        requestedMenu={standrdMasterTabKey}
        requestedFeature={createKey}
      >
        <Button
          onClick={() => {
            onClickAddStandard();
          }}
          className="btn btn-primary me-2 px-md-3 px-sm-4"
        >
          <BsPlusLg size={12} />
        </Button>
      </VerifyAccess>
    </Tooltip>
  );
  const actionButton2 = (
    <Tooltip placement="bottom" title={"Add"}>
      <VerifyAccess
        requestedMenu={sectionMasterTabKey}
        requestedFeature={createKey}
      >
        <Button
          onClick={() => {
            onClickAddSection();
          }}
          className="btn btn-primary me-2 px-md-3 px-sm-4"
        >
          <BsPlusLg size={12} />
        </Button>
      </VerifyAccess>
    </Tooltip>
  );

  useEffect(() => {
    setTopTitle("Standard & Section Master");
  }, [setTopTitle]);

  const {
    onClickAddStandard,
    handleEditStandard,
    onClickAddSection,
    handleEditSection,
    standardMasterValues,
    sectionMasterValues,
    standardMasterLoading,
    sectionMasterLoading,
  } = UseStandardMaster();

  return (
    <>
      <div className="pad-lg">
        <Col span={"24"}>
          <Tabs centered type="card" defaultActiveKey="1">
            <TabPane
              style={{ width: "100%", color: "#52d963" }}
              tab="Standard"
              key="3"
              className="ant-tabs-tab-active-btn"
            >
              <CustomTable
                dataSource={standardMasterValues}
                loading={standardMasterLoading}
                column={columnStandard({
                  handleEditStandard,
                  isEditDeleteAccess,
                })}
                actionButton={actionButton}
                title={"Standard Master List"}
              />
            </TabPane>
            <TabPane tab="Section" key="4">
              <CustomTable
                dataSource={sectionMasterValues}
                loading={sectionMasterLoading}
                column={columnSection({
                  handleEditSection,
                  isEditDeleteAccess2,
                })}
                actionButton={actionButton2}
                title={"Standard Master List"}
              />
            </TabPane>
          </Tabs>
        </Col>
      </div>
    </>
  );
};

export default StandardMaster;
