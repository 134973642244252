import React, { useEffect } from 'react';
import CustomTable from '../../../common/components/CustomTableView';
import { columnDropdown, columnDropdownList } from './components/column';
import UseStandardMaster from './hooks/UseDropdownMaster';
import { Tabs, Col, Button, Row, Form } from "antd";
import CustomDropdown from '../../../common/components/Dropdown/dropdown';
import useGetDropdownOptions from '../../../common/hooks/useGetDropdownMaster';
import { Tooltip } from '@mui/material';
import { BsPlusLg } from 'react-icons/bs';
import { requestedFeaturesAll, requestedMenuAll } from '../../../api/constants/menuAccess';
import VerifyAccess from '../../../common/components/verify-access/verify-access';
import { isUserHasAccess } from '../../../common/helpers/helpers';
import { useSelector } from 'react-redux';

const StandardMaster = ({ setTopTitle }) => {
  const {dropdownMasterTabKey, dropdownListMasterTabKey} = requestedMenuAll;
  const {createKey, editKey, deleteKey} = requestedFeaturesAll;
  const userData = useSelector((state) => state.auth);
  const entitlement = userData?.userData?.data?.entitlement || {};
  const isEditDeleteAccess = isUserHasAccess(dropdownMasterTabKey, editKey, entitlement)
  || isUserHasAccess(dropdownMasterTabKey, deleteKey, entitlement);
  const isEditDeleteAccess2 = isUserHasAccess(dropdownListMasterTabKey, editKey, entitlement)
  || isUserHasAccess(dropdownListMasterTabKey, deleteKey, entitlement);

  const { TabPane } = Tabs;

  const actionButton = (
    <Tooltip placement="bottom" title={"Add"}>
          <VerifyAccess requestedMenu={dropdownMasterTabKey} requestedFeature={createKey}>
      <Button
        onClick={() => {
          onClickAddDropdown();
        }}
        className="btn btn-primary me-2 px-md-3 px-sm-4"
      >
        <BsPlusLg size={12} />
      </Button>
      </VerifyAccess>
    </Tooltip>
  );
  const actionButton2 = (
    <Tooltip placement="bottom" title={"Add"}>
          <VerifyAccess requestedMenu={dropdownListMasterTabKey} requestedFeature={createKey}>
      <Button
        onClick={() => {
          onClickAddDropdownList();
        }}
        className="btn btn-primary me-2 px-md-3 px-sm-4"
      >
        <BsPlusLg size={12} />
      </Button>
      </VerifyAccess>
    </Tooltip>
  );
 
  useEffect(() => {
    setTopTitle('Dropdown & List Master');
  }, [setTopTitle]);

  const {getDropdownOptions} = useGetDropdownOptions();

  const {
    onClickAddDropdown,
    handleEditDropdown,
    onClickAddDropdownList,
    selectedValues,
    handleChange,
    handleFormSubmit,
    handleEditDropdownList,
    dropdownMasterValues,
    dropdownListMasterValues,
    dropdownMasterLoading,
    dropdownListMasterLoading
  } = UseStandardMaster();

  return (
    <>
      <div className='pad-lg'>
        <Col span={"24"}>
          <Tabs
            centered
            type='card'
            defaultActiveKey="1"
          >
            <TabPane
              style={{ width: "100%", color: "#52d963" }}
              tab="Dropdown Master"
              key="1"
              className="ant-tabs-tab-active-btn"
            >
              <CustomTable
                dataSource={dropdownMasterValues}
                loading={dropdownMasterLoading}
                column={columnDropdown({ handleEditDropdown, isEditDeleteAccess })}
                actionButton={actionButton}
                title={"Dropdown Master"}
              />
            </TabPane>
            <TabPane
              tab="Dropdown List Master"
              key="2"
            >
              <Row gutter={[45, 0]}>
                <Col md={{ span: 6 }} xs={{ span: 24 }} >
                  <Form.Item name='dropdownId' label='Dropdown Name' >
                    <CustomDropdown
                      options={getDropdownOptions}
                      onChange={handleChange}
                      value={selectedValues} // Pass selectedValues here
                      maxTagCount={0}
                      loading={false}
                      mode="multiple"
                      showSearch={true}
                      placeholder="Select an option"
                    />
                  </Form.Item>
                </Col>

                <Col md={{ span: 2 }} xs={{ span: 24 }} >
                  <Form.Item name='submit'>
                    <Button
                      onClick={handleFormSubmit}
                      style={{ background: '#34b1aa', color: "#ffffff" }}
                      className="btn btn-sm col-lg-1 col-m-2 col-sm-2 h-100 w-auto align-items-center">
                      {" "}
                      Filter
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              <CustomTable
                dataSource={dropdownListMasterValues}
                loading={dropdownListMasterLoading}
                column={columnDropdownList({ handleEditDropdownList, isEditDeleteAccess2 })}
                actionButton={actionButton2}
                title={"Dropdown List Master"}
              />
            </TabPane>
          </Tabs>
        </Col>
      </div>

    </>
  );
}

export default StandardMaster;
