import React from "react";
import EditAction from "../components/edit-action/edit-action";
import {
  requestedFeaturesAll,
  requestedMenuAll,
} from "../../../../api/constants/menuAccess";
import VerifyAccess from "../../../../common/components/verify-access/verify-access";

const { roleMasterTabKey, designationMasterTabKey } = requestedMenuAll;
const { editKey } = requestedFeaturesAll;
export const columnRole = ({ handleEditRole, isEditDeleteAccess }) => [
  { key: "1", headerName: "S.No", field: "S.No", hide: false, width: 80 },
  {
    key: "2",
    headerName: "Role Name",
    field: "roleName",
    hide: false,
    width: 250,
  },
  {
    key: "3",
    headerName: "Active Status",
    field: "activeStatus",
    hide: false,
    width: 200,
  },
  ...(isEditDeleteAccess
    ? [
        {
          key: "4",
          field: "action",
          headerName: "Action",
          width: 150,
          sortable: false,
          disableClickEventBubbling: true,
          renderCell: (params) => (
            <VerifyAccess
              requestedMenu={roleMasterTabKey}
              requestedFeature={editKey}
            >
              <EditAction row={params.row} onRowEdit={handleEditRole} />
            </VerifyAccess>
          ),
        },
      ]
    : []),
];

export const columnDesignation = ({
  handleEditDesignation,
  isEditDeleteAccess2,
}) => [
  { key: "1", headerName: "S.No", field: "S.No", hide: false, width: 100 },
  {
    key: "2",
    headerName: "Designation Name",
    field: "designationName",
    hide: false,
    width: 250,
  },
  {
    key: "3",
    headerName: "Designation Code",
    field: "designationCode",
    hide: false,
    width: 250,
  },
  {
    key: "4",
    headerName: "Active Status",
    field: "activeStatus",
    hide: false,
    width: 120,
  },
  ...(isEditDeleteAccess2
    ? [
        {
          key: "5",
          field: "action",
          headerName: "Action",
          width: 150,
          sortable: false,
          disableClickEventBubbling: true,
          renderCell: (params) => (
            <VerifyAccess
              requestedMenu={designationMasterTabKey}
              requestedFeature={editKey}
            >
              <EditAction row={params.row} onRowEdit={handleEditDesignation} />
            </VerifyAccess>
          ),
        },
      ]
    : []),
];
