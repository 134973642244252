import { apiKeys, apiMethods, apiUrls } from '../../../../../api/constants/index';
import { fetchData } from '../../../../../@app/slices/fetch-slice';
import { useDispatch } from 'react-redux';
import { useMemo, useState } from 'react';
import {useLocation, useNavigate} from 'react-router';
import navs from '../../../../../api/constants/navs';
import { pathNavigate, sendFormattedId } from '../../../../../common/helpers/helpers';
// import { getStatusCode } from '../../../@app/slices/helpers/helpers';

export default function useStandardMasterForm() {
  const dispatch = useDispatch();
  // const state = useSelector((store) => store);
  const { state: locationState } = useLocation(); // Rename state to locationState
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const modalstandard = locationState?.modalstandard || false;
  const isEditdp = locationState?.isEditdp || false;
  const modalsection = locationState?.modalsection || false;
  const isEditdpl = locationState?.isEditdpl || false;
  let defaultValue = locationState?.data;
  const stdId = defaultValue?.stdId;
  const secId = defaultValue?.secId;
  const [selectedValues, setSelectedValues] = useState([]);


  // const updateAcademicMasterData  = getStatusCode(state, apiKeys.updateAcademicMasterData, '');

  const onFinish = (values) => {
    setShowDialog(false);
  
    if (modalstandard) {
      if (isEditdp) {
        dispatch(fetchData({
          url: apiUrls.updateStandardMasterData,
          method: apiMethods.POST,
          key: apiKeys.updateStandardMasterData,
          body: sendFormattedId({ ...values, stdId })
        })).then((response) => {
          pathNavigate(navigate, { response, page: navs.standardMaster }); 
      });
      } else {
        dispatch(fetchData({
          url: apiUrls.addStandardMasterData,
          method: apiMethods.POST,
          key: apiKeys.addStandardMasterData,
          body: sendFormattedId({ ...values })
        })).then((response) => {
          pathNavigate(navigate, { response, page: navs.standardMaster }); 
      });
      }
    } else {
      if (isEditdpl) {
        dispatch(fetchData({
          url: apiUrls.updateSectionMasterData,
          method: apiMethods.POST,
          key: apiKeys.updateSectionMasterData,
          body: sendFormattedId({ ...values, secId })
        })).then((response) => {
          pathNavigate(navigate, { response, page: navs.standardMaster }); 
      });
      } else {
        dispatch(fetchData({
          url: apiUrls.addSectionMasterData,
          method: apiMethods.POST,
          key: apiKeys.addSectionMasterData,
          body: sendFormattedId({ ...values, })
        })).then((response) => {
          pathNavigate(navigate, { response, page: navs.standardMaster }); 
      });
      }
    }
  };
  

  const handleClickBack = () => {
    navigate(navs.standardMaster);
  };

  const handleStandard = (selectedValues) => {
    setSelectedValues(selectedValues);
  };


  return useMemo(() => ({
    onFinish,
    showDialog,
    modalstandard,
    modalsection,
    handleClickBack,
    defaultValue,
    isEditdp,
    isEditdpl,
    selectedValues,
    handleStandard,
  }), [onFinish, showDialog,
    modalstandard,
    modalsection,
    selectedValues,
    handleStandard,
     handleClickBack, defaultValue, isEditdp, isEditdpl]);
}