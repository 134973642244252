import React from 'react';
import { Input, Card, Button, Radio, Col, Row, Form } from 'antd';
import ConfirmOnExit from '../../../../common/components/confirmOnExit/ConfirmOnExit';
import UseFeesTypeForm from './hooks/UseFeesTypeForm';


function SubjectMasterForm({ setTopTitle }) {
  setTopTitle('Fees Type Master');

  const {
    onFinish, showDialog, handleClickBack, isEdit, defaultValue
  } = UseFeesTypeForm();


  const [form] = Form.useForm();

  return (
    <>
      <Card>
        <ConfirmOnExit showModel={showDialog} />
        <Row>
          <Col span={24}>
            <Form
              name='basic'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{
                dropDownName: defaultValue?.dropDownName,
                dropdownListName: defaultValue?.dropdownListName,
                addColOne: defaultValue?.addColOne,
                activeStatus: defaultValue?.activeStatus === 'In Active' ? 'inActive' : 'active'
              }}
              onFinish={onFinish}
              autoComplete='off'
              form={form}>
              <Row gutter={[15, 0]}>
                <Col md={{ span: 6 }} xs={{ span: 24 }}>
                  <Form.Item label='Admission Type' name='dropDownName'>
                    <Input placeholder='Admission Type' name='dropDownName' disabled />
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }}>
                  <Form.Item label='Fees Type' name='dropdownListName'
                    rules={[{ required: true, message: 'Please input the fees type!' }]}>
                    <Input placeholder='Fees Type' name='dropdownListName' />
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }}>
                  <Form.Item label='Concession %' name='addColOne'
                    rules={[{ required: true, message: 'Please input the concession!' }]}>
                    <Input placeholder='Concession %' name='addColOne' />
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }}>
                  <Form.Item name='activeStatus' label='Status'
                    rules={[{ required: true, message: 'Please select your status' }]}>
                    <Radio.Group buttonStyle='solid' size='middle' name='activeStatus' >
                      <Radio.Button className='active' value='active'>
                        Active
                      </Radio.Button>
                      <Radio.Button className='in-active' value='inActive'>
                        In-Active
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row gutter={[15, 15]} style={{ justifyContent: 'end' }}>
                    <Col span={12} style={{ textAlign: 'right' }} className='d-flex align-items-center justify-content-end mt-3'>
                      <Form.Item className='mx-2'>
                        <Button className='orangeFactory' type='primary' htmlType='submit'>
                          {isEdit ? 'Update' : 'Add'}
                        </Button>
                      </Form.Item>
                      {/* </Col>
                    <Col span={12}> */}
                      <Form.Item>
                        <Button onClick={handleClickBack}>Back</Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default SubjectMasterForm;
