import React from 'react';
import EditAction from '../components/edit-action/edit-action';

export const columnCategory = ({ handleEditClickCat }) => [
      {key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 80},
      {key: '2', headerName: 'Fees Category', field: 'dropdownListName', hide: false, width: 180},
      {key: '3', headerName: 'Active Status', field: 'activeStatus', hide: false, width: 150},
      {
        key: '4',
        field: 'action',
        headerName: 'Action',
        width: 150,
        sortable: false,
        disableClickEventBubbling: true,
        renderCell: (params) => (
          <EditAction row={params.row} onRowEdit={handleEditClickCat} />
        )
      }
  ];
  
  export const columnName = ({ handleEditClickName }) => [
    {key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 80},
    {key: '2', headerName: 'Fees Category', field: 'feesCategory', hide: false, width: 250},
    {key: '3', headerName: 'Fees Name', field: 'feesName', hide: false, width: 250},
    {key: '4', headerName: 'Terms', field: 'noOfTerms', hide: false, width: 250},
    {key: '5', headerName: 'Active Status', field: 'activeStatus', hide: false, width: 150},
    {
      key: '6',
      field: 'action',
      headerName: 'Action',
      width: 150,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <EditAction row={params.row} onRowEdit={handleEditClickName} />
      )
    }
];
