import { useMemo, useEffect, useState, } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getData, } from "../../../../@app/slices/helpers/helpers";
import { apiKeys, apiMethods, apiUrls } from '../../../../api/constants/index';
import { fetchData } from '../../../../@app/slices/fetch-slice';
import useGetDropdownListById from '../../../../common/hooks/useGetDropdownListById';
import navs from '../../../../api/constants/navs';

export default function useStandardMaster() {

  const state = useSelector((store) => store);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {DropdownValue, getDropdownData} = useGetDropdownListById();
 const [selectedValues, setSelectedValues] = useState([]);

  const feesCategoryValues = getData(state, apiKeys.feesCategoryData, []);
  const feesNameValues = getData(state, apiKeys.feesNameData, []);

  useEffect(() => {
    dispatch(fetchData({
      url: apiUrls.feesCategoryData,
      method: apiMethods.GET,
      key: apiKeys.feesCategoryData,
    }))
  }, [dispatch,]);

  useEffect(() => {
    dispatch(fetchData({
      url: apiUrls.feesNameData,
      method: apiMethods.POST,
      key: apiKeys.feesNameData,
    }))
  }, [dispatch,]);

  useEffect(() => {
    getDropdownData(12);
  }, [getDropdownData]);

  const handleFormSubmit = () => {
    dispatch(fetchData({
      url: apiUrls.feesNameData,
      method: apiMethods.POST,
      key: apiKeys.feesNameData,
      body: { feesCatId: selectedValues }
    }));
  };

  const handleChange = (selectedValues) => {
    setSelectedValues(selectedValues);
  };

  const onClickAddCat = () => {
    navigate(navs.feesCategoryMasterForm, {
      state: { modalFeesCategory: true }
    });
  };

  const handleEditClickCat = (data) => {
    navigate(navs.feesCategoryMasterForm, {
      state: {data, isEditdp: true, modalFeesCategory: true}
    });
  };

  const onClickAddName = () => {
    navigate(navs.feesCategoryMasterForm, {
      state: { modalFeesName: true}
    });
  };


  const handleEditClickName = (data) => {
    navigate(navs.feesCategoryMasterForm, {
      state: {data, isEditdpl: true, modalFeesName: true}
    });
  };

  return useMemo(() => ({
    onClickAddCat,
    handleEditClickCat,
    onClickAddName,
    handleEditClickName,
    feesCategoryValues,
    DropdownValue,
    handleFormSubmit,
    feesNameValues,
    handleChange
  }), [
    onClickAddCat,
    handleEditClickCat,
    onClickAddName,
    handleEditClickName,
    feesCategoryValues,
    DropdownValue,
    handleFormSubmit,
    feesNameValues,
    handleChange

  ]
  )

}