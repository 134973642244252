import { apiKeys, apiMethods, apiStatuses, apiUrls } from '../../api/constants/index';
import { clearMultipleData, fetchData } from '../../@app/slices/fetch-slice';
import { getData, getStatus } from '../../@app/slices/helpers/helpers';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetDropdownListOptions() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getDropdownListData = getData(state, apiKeys.dropdownListMasterData, []); 
  const getDropdownListStatus = getStatus(state, apiKeys.dropdownListMasterData, '');
  const getDropdownListLoading = apiStatuses.pending === getDropdownListStatus;

  useEffect(() => {
    dispatch(fetchData({
      url: apiUrls.dropdownListMasterData,
      method: apiMethods.POST, 
      key: apiKeys.dropdownListMasterData,
    }))
  }, [dispatch,]);

  const busFeesCategory = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 1 && option.activeStatus === 1)
      .map(({dropListId, dropdownListName }) => ({
        value: dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);

  const busStageName = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 2 && option.activeStatus === 1)
      .map(({dropListId, dropdownListName }) => ({
        value: dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);

  const applicableDevice = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 3 && option.activeStatus === 1)
      .map(({dropListId, dropdownListName }) => ({
        value: dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);

  const busRouteName = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 4 && option.activeStatus === 1)
      .map(({dropListId, dropdownListName }) => ({
        value: dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);

  const enquiryType = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 5 && option.activeStatus === 1)
      .map(({dropListId, dropdownListName }) => ({
        value: dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);

  const enquiryFor = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 6 && option.activeStatus === 1)
      .map(({dropListId, dropdownListName }) => ({
        value: dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);

  const enquiryBy = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 7 && option.activeStatus === 1)
      .map(({dropListId, dropdownListName }) => ({
        value: dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);

  const gender = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 8 && option.activeStatus === 1)
      .map(({dropListId, dropdownListName }) => ({
        value: dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);

  const salaryRange = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 9 && option.activeStatus === 1)
      .map(({dropListId, dropdownListName }) => ({
        value: dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);

  const serialNumber = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 10 && option.activeStatus === 1)
      .map(({dropListId, dropdownListName }) => ({
        value: dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);

  const admissionType = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 11 && option.activeStatus === 1)
      .map(({dropListId, dropdownListName }) => ({
        value: dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);

  const feesCategory = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 12 && option.activeStatus === 1)
      .map(({dropListId, dropdownListName }) => ({
        value: dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);

  const qualifications = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 13 && option.activeStatus === 1)
      .map(({dropListId, dropdownListName }) => ({
        value: dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);

  const bloodGroup = useMemo(() => {
    return getDropdownListData
      .filter((option) => option.dropdownId === 14 && option.activeStatus === 1)
      .map(({dropListId, dropdownListName }) => ({
        value: dropListId,
        label: dropdownListName
      }));
  }, [getDropdownListData]);


  useEffect(
    () => () => {
      dispatch(clearMultipleData({ key: [apiKeys.dropdownlistMaster] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      getDropdownListData,
      getDropdownListStatus,
      getDropdownListLoading,
      busFeesCategory,
      busStageName,
      applicableDevice,
      busRouteName,
      enquiryType,
      enquiryFor,
      enquiryBy,
      gender,
      admissionType,
      salaryRange,
      serialNumber,
      qualifications,
      feesCategory,
      bloodGroup
    }),
    [
      getDropdownListData,
      getDropdownListStatus,
      getDropdownListLoading,
      busFeesCategory,
      busStageName,
      applicableDevice,
      busRouteName,
      enquiryType,
      enquiryFor,
      enquiryBy,
      gender,
      salaryRange,
      admissionType,
      qualifications,
      feesCategory,
      bloodGroup

    ]
  );
}

export default useGetDropdownListOptions;
