import React from 'react';
import EditAction from '../components/edit-action/edit-action';

export const column = ({ handleEditClick }) => [
      {key: '1', headerName: 'S.No', field: 'S.No', hide: false, width: 100},
      {key: '2', headerName: 'Standard', field: 'standard', hide: false, width: 180},
      {key: '3', headerName: 'Fees Category', field: 'feesCategory', hide: false, width: 180},
      {key: '4', headerName: 'Fees Name', field: 'feesName', hide: false, width: 180},
      {key: '5', headerName: 'Fees Amount', field: 'feesAmount', hide: false, width: 180},
      {key: '6', headerName: 'Academic Year', field: 'year', hide: false, width: 180},
      {key: '7', headerName: 'Active Status', field: 'activeStatus', hide: false, width: 180},
      {
        key: '8',
        field: 'action',
        headerName: 'Action',
        width: 150,
        sortable: false,
        disableClickEventBubbling: true,
        renderCell: (params) => (
          <EditAction row={params.row} onRowEdit={handleEditClick} />
        )
      }
  ];
  