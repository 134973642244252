import { useMemo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getData, getStatus } from "../../../../@app/slices/helpers/helpers";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from '../../../../api/constants/index';
import { fetchData } from '../../../../@app/slices/fetch-slice';
import navs from '../../../../api/constants/navs';

export default function UseFeesAllotment() {
  const state = useSelector((store) => store);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedValues, setSelectedValues] = useState([]);

  // Fetch fees allotment data
  const feesAllotmentValues = getData(state, apiKeys.feesAllotmentData, []);
  const feesAllotmentStatus = getStatus(state, apiKeys.feesAllotmentData, '');
  const feesAllotmentLoading = apiStatuses.pending === feesAllotmentStatus;
  
  // Fetch data for editing
  const getFeesAllotmentValues = getData(state, apiKeys.editFeesAllotmentData, []);

  console.log(getFeesAllotmentValues,'---');

  useEffect(() => {
    const fetchFeesAllotmentData = async () => {
      try {
        await dispatch(fetchData({
          url: apiUrls.feesAllotmentData,
          method: apiMethods.GET,
          key: apiKeys.feesAllotmentData,
        }));
      } catch (error) {
        console.error("Failed to fetch fees allotment data:", error);
      }
    };

    fetchFeesAllotmentData();
  }, [dispatch]);

  const handleStandard = (values) => {
    setSelectedValues(values);
  };

  const onClickAdd = () => {
    navigate(navs.feesAllotmentForm);
  };

  const handleEditClick = (data) => {
    const { feesId, academicYear } = data || {};
    
    if (feesId && academicYear) {
      dispatch(fetchData({
        url: apiUrls.editFeesAllotmentData,
        method: apiMethods.POST,
        key: apiKeys.editFeesAllotmentData,
        body: { feesId, academicYear },
      }));

      navigate(navs.feesAllotmentForm, {
        state: { ...data, isEdit: true, getFeesAllotmentValues } // Ensure getFeesAllotmentValues is included
      });
    } else {
      console.warn("Missing feesId or academicYear in data:", data);
    }
  };

  return useMemo(() => ({
    onClickAdd,
    handleEditClick,
    handleStandard,
    selectedValues,
    feesAllotmentValues,
    feesAllotmentLoading,
    getFeesAllotmentValues // Ensure this value is returned
  }), [onClickAdd, handleEditClick, handleStandard, selectedValues,
     feesAllotmentValues, feesAllotmentLoading, getFeesAllotmentValues]);
}
