import React from 'react';
import { Input, Card, Button, Radio, Col, Row, Form } from 'antd';
import ConfirmOnExit from '../../../../common/components/confirmOnExit/ConfirmOnExit';
import useMenuListForm from './hooks/UseMenuListForm';
import CustomDropdown from '../../../../common/components/Dropdown/dropdown';


function MenuListForm({ setTopTitle }) {
  setTopTitle('Menu Master');

  const {
    onFinish, showDialog, getMenuName, handleClickBack, isEdit, defaultValue,
    selectedMenuId

  } = useMenuListForm();



  const [form] = Form.useForm();

  return (
    <>
      <Card>
        <ConfirmOnExit showModel={showDialog} />
        <Row>
          <Col span={24}>
            <Form
              name='basic'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{
                moduleId: defaultValue?.moduleId,
                name: defaultValue?.name,
                activeStatus: defaultValue?.activeStatus === 'In Active' ? 'inActive' : 'active'
              }}
              onFinish={onFinish}
              autoComplete='off'
              form={form}>
              <Row gutter={[15, 0]}>
                <Col md={{ span: 6 }} xs={{ span: 24 }} >
                  <Form.Item name='moduleId' label='Menu Name'  >
                    <CustomDropdown
                      options={getMenuName}
                      value={selectedMenuId}
                      maxTagCount={0}
                      loading={false}
                      mode="single"
                      showSearch={true}
                      placeholder="Select an option"
                    />
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }}>
                  <Form.Item label='Menu List Name' name='name'
                    rules={[{ required: true, message: 'Please input the subject code!' }]}>
                    <Input placeholder='Menu Name' name='name' />
                  </Form.Item>
                </Col>
                <Col md={{ span: 6 }} xs={{ span: 24 }}>
                  <Form.Item name='activeStatus' label='Status'
                    rules={[{ required: true, message: 'Please select your status' }]}>
                    <Radio.Group buttonStyle='solid' size='middle' name='activeStatus' >
                      <Radio.Button className='active' value='active'>
                        Active
                      </Radio.Button>
                      <Radio.Button className='in-active' value='inActive'>
                        In-Active
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row gutter={[15, 15]} style={{ justifyContent: 'end' }}>
                    <Col span={12} style={{ textAlign: 'right' }} className='d-flex align-items-center justify-content-end mt-3'>
                      <Form.Item className='mx-2'>
                        <Button className='orangeFactory' type='primary' htmlType='submit'>
                          {isEdit ? 'Update' : 'Add'}
                        </Button>
                      </Form.Item>
                      {/* </Col>
                    <Col span={12}> */}
                      <Form.Item>
                        <Button onClick={handleClickBack}>Back</Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default MenuListForm;
