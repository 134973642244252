import React from 'react';
import { Input, Card, Button, Radio, Col, Row, Form, Modal } from 'antd';
import ConfirmOnExit from '../../../../common/components/confirmOnExit/ConfirmOnExit';
import UseRoleMasterForm from './hooks/UseRoleMasterForm';


function RoleMasterForm({ setTopTitle }) {
  setTopTitle('Role Master');

  const {
    onFinish, showDialog, modalrole, modaldesignation, handleClickBack, isEditdp, isEditdpl,
     defaultValue
  } = UseRoleMasterForm();

  const [form] = Form.useForm();

  return (
    <>
      <Modal open={modalrole} width={1000} footer={null} title={'Add Role'} >
        <Card>
          <ConfirmOnExit showModel={showDialog} />
          <Row>
            <Col span={24}>
              <Form
                name='basic'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{
                  roleName: defaultValue?.roleName,
                  activeStatus: defaultValue?.activeStatus === 'In Active' ? 'inActive' : 'active'
                }}
                onFinish={onFinish}
                autoComplete='off'
                form={form}>
                <Row gutter={[15, 0]}>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    <Form.Item label='Role Name' name='roleName'
                      rules={[{ required: true, message: 'Please input the role code!' }]}>
                      <Input placeholder='Role Name' name='roleName' />
                    </Form.Item>
                  </Col>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    <Form.Item name='activeStatus' label='Status'
                      rules={[{ required: true, message: 'Please select your status' }]}>
                      <Radio.Group buttonStyle='solid' size='middle' name='activeStatus' >
                        <Radio.Button className='active' value='active'>
                          Active
                        </Radio.Button>
                        <Radio.Button className='in-active' value='inActive'>
                          In-Active
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[15, 15]} style={{ justifyContent: 'end' }}>
                      <Col span={12} style={{ textAlign: 'right' }} className='d-flex align-items-center justify-content-end mt-3'>
                        <Form.Item className='mx-2'>
                          <Button className='orangeFactory' type='primary' htmlType='submit'>
                            {isEditdp ? 'Update' : 'Add'}
                          </Button>
                        </Form.Item>
                        {/* </Col>
                    <Col span={12}> */}
                        <Form.Item>
                          <Button onClick={handleClickBack}>Back</Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card>
      </Modal>
      <Modal open={modaldesignation} width={1000} footer={null} title={'Add Desigantion List'} >
        <Card>
          <ConfirmOnExit showModel={showDialog} />
          <Row>
            <Col span={24}>
              <Form
                name='basic'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{
                  designationName: defaultValue?.designationName,
                  designationCode: defaultValue?.designationName,
                  activeStatus: defaultValue?.activeStatus === 'In Active' ? 'inActive' : 'active'
                }}
                onFinish={onFinish}
                autoComplete='off'
                form={form}>
                <Row gutter={[15, 0]}>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    <Form.Item label='Designation Name' name='designationName'
                      rules={[{ required: true, message: 'Please input the short name!' }]}>
                      <Input placeholder='Designation Name' name='designationName' />
                    </Form.Item>
                  </Col>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    <Form.Item label='Designation Code' name='designationCode'
                      rules={[{ required: true, message: 'Please input the role code!' }]}>
                      <Input placeholder='Designation Code' name='designationCode' />
                    </Form.Item>
                  </Col>
                  <Col md={{ span: 8 }} xs={{ span: 24 }}>
                    <Form.Item name='activeStatus' label='Status'
                      rules={[{ required: true, message: 'Please select your status' }]}>
                      <Radio.Group buttonStyle='solid' size='middle' name='activeStatus' >
                        <Radio.Button className='active' value='active'>
                          Active
                        </Radio.Button>
                        <Radio.Button className='in-active' value='inActive'>
                          In-Active
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[15, 15]} style={{ justifyContent: 'end' }}>
                      <Col span={12} style={{ textAlign: 'right' }} className='d-flex align-items-center justify-content-end mt-3'>
                        <Form.Item className='mx-2'>
                          <Button className='orangeFactory' type='primary' htmlType='submit'>
                            {isEditdpl ? 'Update' : 'Add'}
                          </Button>
                        </Form.Item>
                        {/* </Col>
                    <Col span={12}> */}
                        <Form.Item>
                          <Button onClick={handleClickBack}>Back</Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card>
      </Modal>
    </>
  );
}

export default RoleMasterForm;
