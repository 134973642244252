import { useMemo, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getData, getStatus } from "../../../../@app/slices/helpers/helpers";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from '../../../../api/constants/index';
import { fetchData } from '../../../../@app/slices/fetch-slice';
import navs from '../../../../api/constants/navs';

export default function useDropdownMaster() {
  const state = useSelector((store) => store);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedValues, setSelectedValues] = useState([]);

  const dropdownMasterValues = getData(state, apiKeys.dropdownMasterData, []);
  const dropdownMasterStatus = getStatus(state, apiKeys.dropdownMasterData, '');
  const dropdownMasterLoading = apiStatuses.pending === dropdownMasterStatus;
  const dropdownListMasterValues = getData(state, apiKeys.dropdownListMasterData, []);
  const dropdownListMasterStatus = getStatus(state, apiKeys.dropdownListMasterData, '');
  const dropdownListMasterLoading = apiStatuses.pending === dropdownListMasterStatus;

  const handleFormSubmit = () => {
    dispatch(fetchData({
      url: apiUrls.dropdownListMasterData,
      method: apiMethods.POST,
      key: apiKeys.dropdownListMasterData,
      body: { dropdownId: selectedValues }
    }));
  };

  const getDropdownData = useCallback(() => {
    dispatch(fetchData({
      url: apiUrls.dropdownMasterData,
      method: apiMethods.GET,
      key: apiKeys.dropdownMasterData,
    }));
  }, [dispatch]);

  useEffect(() => {
    getDropdownData();
  }, []);

  useEffect(() => {
    handleFormSubmit(); // Call handleFormSubmit whenever selectedValues change
  }, [selectedValues]);

  const handleChange = (selectedValues) => {
    console.log('asdaasdasd')
    setSelectedValues(selectedValues);
  };

  const onClickAddDropdown = () => {
    navigate(navs.dropdownMasterForm, {
      state: { modaldropdown: true}
    });
  };

  const handleEditDropdown = (data) => {
    navigate(navs.dropdownMasterForm, {
      state: {data, isEditdp: true, modaldropdown: true}
    });
  };

  const onClickAddDropdownList = () => {
    navigate(navs.dropdownMasterForm, {
      state: {modaldropdownlist: true}
    });
  };

  const handleEditDropdownList = (data) => {
    navigate(navs.dropdownMasterForm, {
      state: {data, isEditdpl: true, modaldropdownlist: true}
    });
  };

  return useMemo(() => ({
    onClickAddDropdown,
    handleEditDropdown,
    onClickAddDropdownList,
    selectedValues,
    handleEditDropdownList,
    dropdownMasterValues,
    dropdownListMasterValues,
    handleChange,
    handleFormSubmit,
    dropdownMasterLoading,
    dropdownListMasterLoading
  }), [
    onClickAddDropdown,
    handleEditDropdown,
    onClickAddDropdownList,
    selectedValues,
    handleEditDropdownList,
    dropdownMasterValues,
    dropdownListMasterValues,
    handleFormSubmit,
    dropdownMasterLoading,
    dropdownListMasterLoading
  ]);
}
