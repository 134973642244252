import { apiKeys, apiMethods, apiUrls } from '../../../../../api/constants/index';
import { fetchData } from '../../../../../@app/slices/fetch-slice';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Form, } from 'antd';
// import { getStatus } from '../../../@app/slices/helpers/helpers';

export default function useEnquiryMasterForm(image) {

  const dispatch = useDispatch();
const { state: locationState } = useLocation(); // Rename state to locationState
// const state = useSelector((store) => store); // Renamed to reduxState
const navigate = useNavigate();
const [showDialog, setShowDialog] = useState(false);
const [selectedEnquiryType, setSelectedEnquiryType] = useState([]);
const [selectedEnquiryFor, setSelectedEnquiryFor] = useState([]);
const [selectedStandardOption, setSelectedStandardOption] = useState([]);
const [selectedAcademicYear, setSelectedAcademicYear] = useState([]);
const [selectedEnquiryBy, setSelectedEnquiryBy] = useState([]);
const [enquiryId, setEnquiryId] = useState('');

// const addEnquiryMasterStatus = getStatus(state, apiKeys.addEnquiryMasterData, '');

// console.log(addEnquiryMasterStatus,'---');

const isEdit = locationState?.isEdit || false; // Updated to use locationState
let defaultValue = locationState?.data; // Updated to use locationState


useEffect(() => {
  if (defaultValue?.id) {
    setEnquiryId(defaultValue.id);
  }
}, [defaultValue?.id, setEnquiryId]);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setShowDialog(false);
    let data = {
      enqType: values?.enqType,
      enquiryId: enquiryId,
      enqFor: values?.enqFor,
      enquiryStd: values?.enquiryStd,
      academicYear: values?.academicYear,
      enqBy: values?.enqBy,
      referredBy: values?.referredBy,
      enquiryDate: values?.enquiryDate,
      referredNo: values?.referredNo,
      studentName: values?.studentName,
      parentName: values?.parentName,
      parentNumber: values?.parentNumber,
      file: image ?? 'No image',
      sibling: values.sibling === 'Yes' ? 1 : 0,
      activeStatus: values.activeStatus === 'active' ? 1 : 0,
    };

    if(isEdit === true){
      dispatch(fetchData({
        url: apiUrls.updateEnquiryMasterData,
        method: apiMethods.POST,
        key: apiKeys.updateEnquiryMasterData,
        body: { data }
      }));
    }else{
      dispatch(fetchData({
        url: apiUrls.addEnquiryMasterData,
        method: apiMethods.POST,
        key: apiKeys.addEnquiryMasterData,
        body: { data }
      }));
    }
   

  }

  const handleEnyType = (selectedEnquiryType) => {
    setSelectedEnquiryType(selectedEnquiryType);
  };
  const handleEnyFor = (selectedEnquiryFor) => {
    setSelectedEnquiryFor(selectedEnquiryFor);
  };
  const handleEnyBy = (selectedEnquiryBy) => {
    setSelectedEnquiryBy(selectedEnquiryBy);
  };
  const handleEnyStd = (selectedStandardOption) => {
    setSelectedStandardOption(selectedStandardOption);
  };
  const handleAcdYear = (selectedAcademicYear) => {
    setSelectedAcademicYear(selectedAcademicYear);
  };
  const disabledFutureDates = (current) => {
    const today = new Date();
    return current && current > today;
  };

  const handleClickBack = () => {
    navigate('/enquiryMaster');
  };


  // useEffect(() => {
  //   if (addEnquiryMasterStatus === apiStatuses.succeeded) {
  //     console.log('865');
  //   }
  // }, [addEnquiryMasterStatus])

  // useEffect(
  //   () => () => {
  //     dispatch(clearMultipleData({ key: [apiKeys.addEnquiryMasterData,] }));
  //   },
  //   [dispatch]
  // );

  return useMemo(() => ({
    onFinish,
    showDialog,
    handleClickBack,
    handleEnyType,
    handleEnyFor,
    handleEnyBy,
    handleEnyStd,
    handleAcdYear,
    defaultValue,
    selectedEnquiryType, selectedEnquiryFor,
    selectedStandardOption, selectedAcademicYear, selectedEnquiryBy,
    disabledFutureDates,
    isEdit,
    form
  }), [onFinish,
    showDialog,
    handleClickBack,
    handleEnyType,
    handleEnyFor,
    handleEnyBy,
    handleEnyStd,
    handleAcdYear,
    defaultValue,
    selectedEnquiryType, selectedEnquiryFor,
    selectedStandardOption, selectedAcademicYear, selectedEnquiryBy,
    disabledFutureDates,
    isEdit, form]);
}