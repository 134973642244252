import PropTypes from 'prop-types';
import { isUserHasAccess } from '../../helpers/helpers';
import { useSelector } from 'react-redux';

const VerifyAccess = (props) => {
    const {children, requestedMenu, requestedFeature} = props;
  const userData = useSelector((state) => state.auth);
    const entitlement = userData?.userData?.data?.entitlement || {};

    if(isUserHasAccess(requestedMenu, requestedFeature, entitlement)) {
        return children
    }
    return null;
};

VerifyAccess.propTypes = {
    children: PropTypes.node,
    requestedMenu: PropTypes.string.isRequired,
    requestedFeature: PropTypes.string.isRequired,
};

export default VerifyAccess;
