import { apiKeys, apiMethods, apiStatuses, apiUrls } from '../../api/constants/index';
import { clearData, fetchData } from '../../@app/slices/fetch-slice';
import { getData, getStatus } from '../../@app/slices/helpers/helpers';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useGetFeesNameOptions() {
  const dispatch = useDispatch();
  const state = useSelector((store) => store);

  const getFeesName = getData(state, apiKeys.feesNameData, []) || [];
  const getFeesNameStatus = getStatus(state, apiKeys.getFeesName, '');
  const getFeesNameLoading = apiStatuses.pending === getFeesNameStatus;

  const getFeesData = useMemo(() => {
    return (feesCatId) => {
      dispatch(fetchData({
        url: apiUrls.feesNameData,
        method: apiMethods.POST, 
        key: apiKeys.feesNameData,
        body: { feesCatId: feesCatId }
      }));
    };
  }, [dispatch]);


  const getFeesNameOptions = useMemo(() => {
    return getFeesName.filter((option) => option.activeStatus === 1)
    .map(({ id, feesName }) => ({
      value: id,
      label: feesName
    }))
  }, [getFeesName]);

  useEffect(
    () => () => {
      dispatch(clearData({ key: [apiKeys.feesNameData,] }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      getFeesData,
      getFeesNameOptions,
      getFeesNameStatus,
      getFeesNameLoading
    }),
    [getFeesData, getFeesNameOptions, 
      getFeesNameStatus, getFeesNameLoading]
  );
}

export default useGetFeesNameOptions;
