import { createSlice } from '@reduxjs/toolkit';
import apis from '../../api/stateAPI';
import axios from "axios";

const loginStato = localStorage.getItem("loginStatus") == "true";
const authSlice = createSlice( {
  name: 'auth',
  initialState: {
    statusget: false,
    loginStatus: loginStato,
    forgetpassword: false,
    userData: [],
    error: '',
  },
  reducers: {
    loginReq: ( state ) => {
      state.statusget = true;
      state.error = '';
    },
    loginFun( state, action ) {
      state.statusget = false;
      state.loginStatus = true;
      state.userData = action.payload;
      state.error = '';
    },
    loginError( state, action ) {
      state.statusget = false;
      state.error = action.payload;
    },
    logOutReq( state ) {
      state.loginStatus = false;
      state.error = '';
    },
    settype( state, action ) {
      state.type = action.payload;
    },
    setBadgeCount( state, action ) {
      state.badgeCount = action.payload;
    },
    forgetReq: (state) => {
      state.forgetpassword = true;
    },
  },
});

export const AuthAction = authSlice.actions;
export default authSlice.reducer;


export const loginReducer =
  ({ data, api }) =>
  async (dispatch) => {
    dispatch(authSlice.actions.loginReq());
    const val = data.params;
    return apis
      .loginApi(val)
      .then(({ data }) => {
        if (data.status === 200) {
          localStorage.setItem("loginStatus", true);
          dispatch(authSlice.actions.loginFun(data));
          localStorage.setItem("userData", JSON.stringify(data));
          dispatch();
          return data;
        } else {
          dispatch(authSlice.actions.loginError(data.message));
          api.open({ message: data.message, type: "error" });
        }
      })
      .catch(() => {
        dispatch(authSlice.actions.loginError("Login Failed"));
      });
  };

export const logOutReducer = (val) => async (dispatch) => {
  const code = val?.emp_date;

  if (code) {
    let data = new FormData();
    data.append("employee_code", code);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}logs-outs`,
      headers: {
        Cookie: "laravel_session=TklhWNkURX7B3Bc62h111EFPIv7KAUeUzp5G8C0T",
      },
      data: data,
    };

    axios
      .request(config)
      .then(() => {
        //localStorage.setItem("Password", false);
      })
      .catch(() => {
        dispatch(authSlice.actions.loginError("Login Failed"));
      });
  }
  dispatch(authSlice.actions.logOutReq());
  localStorage.setItem("loginStatus", false);
  // localStorage.setItem("employee_code", false);
  // localStorage.setItem("Password", false);
  localStorage.setItem("emp_code", "undefined");
  localStorage.setItem("userData", null);
};

