import { useMemo, useEffect,  } from 'react';
import { useNavigate } from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import { getData, getStatus, } from "../../../../@app/slices/helpers/helpers";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from '../../../../api/constants/index';
import { fetchData } from '../../../../@app/slices/fetch-slice';
import navs from '../../../../api/constants/navs';

export default function useTermMaster() {
    
  const state = useSelector((store) => store);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const academicMasterValues  = getData(state, apiKeys.academicMasterData, []);
    const academicMasterStatus = getStatus(state, apiKeys.academicMasterData, '');
    const academicMasterLoading = apiStatuses.pending === academicMasterStatus;
  
    useEffect(() => {
      dispatch(fetchData({
        url: apiUrls.academicMasterData,
        method: apiMethods.GET,
        key: apiKeys.academicMasterData,
      }))
    }, [dispatch, ]);

    const onClickAdd = () => {
        navigate(navs.academicMasterForm);
    };

    const onRowEdit = (data) => {
        navigate(navs.academicMasterForm, {
          state: {data, isEdit: true}
        });
      };

    return useMemo(() => ({
        onClickAdd,
        onRowEdit,
        academicMasterValues,
        academicMasterLoading
    }), [
        onClickAdd,
        onRowEdit,
        academicMasterValues,
        academicMasterLoading
 
    ]
    )

}