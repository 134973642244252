import { apiKeys, apiMethods, apiUrls } from '../../../../../api/constants/index';
import { fetchData } from '../../../../../@app/slices/fetch-slice';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import {useLocation, useNavigate} from 'react-router';
import navs from '../../../../../api/constants/navs';
import { pathNavigate, sendFormattedId } from '../../../../../common/helpers/helpers';
import useGetDropdownListById from '../../../../../common/hooks/useGetDropdownListById';
import useGetTermOptions from '../../../../../common/hooks/useGetTermMaster';

export default function useFeesCategoryForm() {
  const dispatch = useDispatch();
  const { state: locationState } = useLocation(); // Rename state to locationState
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const isEditdp = locationState?.isEditdp || false;
  const isEditdpl = locationState?.isEditdpl || false;
  const modalFeesCategory = locationState?.modalFeesCategory || false;
  const modalFeesName = locationState?.modalFeesName || false;
  let defaultValue = locationState?.data;
  const id = defaultValue?.id;
  const dropListId = defaultValue?.dropListId;
  const dropdownId = defaultValue?.dropdownId || 12;
  const [selectedTerm, setSelectedTerm] = useState([]);
  const [selectedFeesCategory, setSelectedFeesCategory] = useState([]);
  const {DropdownValue, getDropdownData} = useGetDropdownListById();
  const {getTermOptionId} = useGetTermOptions();

console.log(DropdownValue,'****');

  const onFinish = (values) => {
    setShowDialog(false);
    if (modalFeesCategory) {
      if (isEditdp) {
        dispatch(fetchData({
          url: apiUrls.updateFeesCategoryData,
          method: apiMethods.POST,
          key: apiKeys.updateFeesCategoryData,
          body: sendFormattedId({ ...values, dropListId, dropdownId })
        })).then((response) => {
          pathNavigate(navigate, { response, page: navs.feesCategoryMaster }); 
      });
      } else {
        dispatch(fetchData({
          url: apiUrls.addFeesCategoryData,
          method: apiMethods.POST,
          key: apiKeys.addFeesCategoryData,
          body: sendFormattedId({ ...values, dropListId, dropdownId })
        })).then((response) => {
          pathNavigate(navigate, { response, page: navs.feesCategoryMaster }); 
      });
      }
    } else {
      if (isEditdpl) {
        dispatch(fetchData({
          url: apiUrls.updateFeesNameData,
          method: apiMethods.POST,
          key: apiKeys.updateFeesNameData,
          body: sendFormattedId({ ...values, id})
        })).then((response) => {
          pathNavigate(navigate, { response, page: navs.feesCategoryMaster }); 
      });
      } else {
        dispatch(fetchData({
          url: apiUrls.addFeesNameData,
          method: apiMethods.POST,
          key: apiKeys.addFeesNameData,
          body: sendFormattedId({ ...values })
        })).then((response) => {
          pathNavigate(navigate, { response, page: navs.feesCategoryMaster }); 
      });
      }
    }
  };
   

  useEffect(() => {
    getDropdownData(12);
  }, [getDropdownData]);

  const handleClickBack = () => {
    navigate(navs.feesCategoryMaster);
  };

  const handleTerm = (selectedTerm) => {
    setSelectedTerm(selectedTerm);
  };

  const handleFees = (selectedFeesCategory) => {
    setSelectedFeesCategory(selectedFeesCategory);
  };

  return useMemo(() => ({
    onFinish,
    showDialog,
    selectedTerm,
    getTermOptionId,
    handleTerm,
    DropdownValue,
    modalFeesCategory,
    modalFeesName,
    handleClickBack,
    defaultValue,
    handleFees,
    selectedFeesCategory,
    isEditdp, isEditdpl 
  }), [onFinish, showDialog, selectedTerm,
    handleTerm, DropdownValue, getTermOptionId,
    modalFeesCategory, handleFees, selectedFeesCategory,
    modalFeesName, handleClickBack, defaultValue, isEditdp, isEditdpl ]);
}