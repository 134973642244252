import React from 'react';
import { Stack, Tooltip } from '@mui/material';
import Button from 'react-bootstrap/Button';
import { FaUserEdit } from 'react-icons/fa';
import PropTypes from 'prop-types';


function EditAction({ row, onRowEdit }) {
  
    return (
      <Stack direction="row" spacing={3}>
        <Tooltip placement="bottom" title="Edit">
          <Button
            variant="outlined"
            onClick={() => onRowEdit(row) }
            color="error"
            style={{ backgroundColor: "#ffaf00", width: "50px" }}
            size="sm"
          >
            <FaUserEdit color="#fff" />
          </Button>
        </Tooltip>
      </Stack>
    );
  }
  
  EditAction.propTypes = {
    row: PropTypes.object.isRequired,
    onRowEdit: PropTypes.func.isRequired
  };
  
  export default EditAction;