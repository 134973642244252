import { useMemo, useEffect } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getData, getStatus } from "../../../../@app/slices/helpers/helpers";
import { apiKeys, apiMethods, apiStatuses, apiUrls } from "../../../../api/constants/index";
import { fetchData } from "../../../../@app/slices/fetch-slice";
import navs from "../../../../api/constants/navs";

export default function useSubjectMaster() {
  const state = useSelector((store) => store);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const subjectMasterValues = getData(state, apiKeys.subjectMasterData, []);
  const subjectMasterStatus = getStatus(state, apiKeys.subjectMasterData, "");
  const subjectMasterLoading = apiStatuses.pending === subjectMasterStatus;

  useEffect(() => {
    dispatch(
      fetchData({
        url: apiUrls.subjectMasterData,
        method: apiMethods.GET,
        key: apiKeys.subjectMasterData,
      })
    );
  }, [dispatch]);

  const onClickAdd = () => {
    navigate(navs.subjectMasterForm);
  };

  const handleEditClick = (data) => {
    navigate(navs.subjectMasterForm, {
      state: { data, isEdit: true },
    });
  };

  return useMemo(
    () => ({
      onClickAdd,
      handleEditClick,
      subjectMasterValues,
      subjectMasterLoading
    }),
    [onClickAdd, handleEditClick, subjectMasterValues,
      subjectMasterLoading]
  );
}
