import React from "react";
import { Route, Routes } from "react-router-dom";
import EmployeeMaster from "../Master/employeeMaster";
import EmployeeMasterForm from "../Master/employeeMaster/EmployeeMasterForm";
import AcademicMaster from "../Master/academicMaster/index";
import AcademicMasterForm from "../Master/academicMaster/page/AcademicMasterForm";
import TermMaster from "../SubMaster/termMaster";
import TermMasterForm from "../SubMaster/termMaster/pages/TermMasterForm";
import StandardMaster from "../SubMaster/standardMaster";
import StandardMasterForm from "../SubMaster/standardMaster/pages/StandardMasterForm";
import SubjectMaster from "../SubMaster/subjectMaster";
import SubjectMasterForm from "../SubMaster/subjectMaster/pages/SubjectMasterForm";
import EnquiryMaster from "../Student/enquiryMaster";
import EnquiryMasterForm from "../Student/enquiryMaster/pages/EnquiryMasterForm";
import TimeTableMaster from "../Student/timeTable";
import TimeTableMasterForm from "../Student/timeTable/pages/TimeTableForm";
import ClassAllotment from "../Student/classAllotment";
import ClassAllotmentForm from "../Student/classAllotment/pages/ClassAllotmentForm";
import StaffMaster from "../Staff/staffMaster";
import StaffMasterForm from "../Staff/staffMaster/StaffMasterForm";
import StudentMaster from "../Student/studentMaster";
import StudentMasterForm from "../Student/studentMaster/pages/StudentMasterForm";
import AdmissionMaster from "../Student/admissionMaster";
import AdmissionMasterForm from "../Student/admissionMaster/pages/AdmissionMasterForm";
import DropdownMaster from "../SubMaster/dropdownMaster/index";
import DropdownMasterForm from "../SubMaster/dropdownMaster/pages/DropdownMasterForm";
import RoleMaster from "../SubMaster/roleMaster";
import RoleMasterForm from "../SubMaster/roleMaster/pages/RoleMasterForm";
import LocationMaster from "../SubMaster/locationMaster";
import MenuMaster from "../SubMaster/menuMaster";
import MenuMasterForm from "../SubMaster/menuMaster/pages/MenuMasterForm";
import MenuListForm from "../SubMaster/menuMaster/pages/MenuListForm";
import MenuAccessForm from "../SubMaster/menuMaster/pages/MenuAccessForm";
import FeesType from "../FeesManagement/FeesType";
import FeesTypeForm from "../FeesManagement/FeesType/pages/FeesTypeForm";
import FeesCategory from "../FeesManagement/FeesCategory";
import FeesCategoryForm from "../FeesManagement/FeesCategory/pages/FeesCategoryForm";
import FeesAllotment from "../FeesManagement/FeesAllotment";
import FeesAllotmentForm from "../FeesManagement/FeesAllotment/pages/FeesAllotmentForm";

function RoutesComponent({ setTopTitle }) {
  return (
    <Routes>
      <Route path="/dashboard" element={<div>Dashbaord</div>}></Route>
      <Route path="/activeUsers" element={<div>Active Users List</div>}></Route>
      <Route path="/disabledUsers" element={<div>Disabled Users List</div>}></Route>
      <Route path="/profile" element={<div>Profile</div>}></Route>
      
        <>
          {/* Masters */}
          <Route path="/academicMaster" element={<AcademicMaster {...{ setTopTitle }} />}></Route>
          <Route path="/academicMaster/addForm" element={<AcademicMasterForm {...{ setTopTitle }} />}></Route>
          

           {/* Sub Masters */}
           <Route path="/dropdownMaster" element={<DropdownMaster {...{ setTopTitle }} />}></Route>
          <Route path="/dropdownMaster/addForm" element={<DropdownMasterForm {...{ setTopTitle }} />}></Route>
          <Route path="/roleMaster" element={<RoleMaster {...{ setTopTitle }} />}></Route>
          <Route path="/roleMaster/addForm" element={<RoleMasterForm {...{ setTopTitle }} />}></Route>
          <Route path="/termMaster" element={<TermMaster {...{ setTopTitle }} />}></Route>
          <Route path="/termMaster/addForm" element={<TermMasterForm {...{ setTopTitle }} />}></Route>
          <Route path="/standardMaster" element={<StandardMaster {...{ setTopTitle }} />}></Route>
          <Route path="/standardMaster/addForm" element={<StandardMasterForm {...{ setTopTitle }} />}></Route>
          <Route path="/subjectMaster" element={<SubjectMaster {...{ setTopTitle }} />}></Route>
          <Route path="/subjectMaster/addForm" element={<SubjectMasterForm {...{ setTopTitle }} />}></Route>
          <Route path="/locationMaster" element={<LocationMaster {...{ setTopTitle }} />}></Route>
          <Route path="/menuMaster" element={<MenuMaster {...{ setTopTitle }} />}></Route>
          <Route path="/menuMaster/addForm" element={<MenuMasterForm {...{ setTopTitle }} />}></Route>
          <Route path="/menuListMaster/addForm" element={<MenuListForm {...{ setTopTitle }} />}></Route>
          <Route path="/menuAccessMaster/addForm" element={<MenuAccessForm {...{ setTopTitle }} />}></Route>

          <Route path="/employeeMaster" element={<EmployeeMaster {...{ setTopTitle }} />}></Route>
          <Route path="/employeeMaster/addForm" element={<EmployeeMasterForm {...{ setTopTitle }} />}></Route>

           {/* Students */}
          <Route path="/admissionMaster" element={<AdmissionMaster {...{ setTopTitle }} />}></Route>
          <Route path="/admissionMaster/addForm" element={<AdmissionMasterForm {...{ setTopTitle }} />}></Route>
          <Route path="/studentMaster" element={<StudentMaster {...{ setTopTitle }} />}></Route>
          <Route path="/studentMaster/addForm" element={<StudentMasterForm {...{ setTopTitle }} />}></Route>
          <Route path="/enquiryMaster" element={<EnquiryMaster {...{ setTopTitle }} />}></Route>
          <Route path="/enquiryMaster/addForm" element={<EnquiryMasterForm {...{ setTopTitle }} />}></Route>
          <Route path="/timeTable" element={<TimeTableMaster {...{ setTopTitle }} />}></Route>
          <Route path="/timeTable/addForm" element={<TimeTableMasterForm {...{ setTopTitle }} />}></Route>
          <Route path="/classAllotment" element={<ClassAllotment {...{ setTopTitle }} />}></Route>
          <Route path="/classAllotment/addForm" element={<ClassAllotmentForm {...{ setTopTitle }} />}></Route>

           {/* Staff */}
          <Route path="/staffMaster" element={<StaffMaster {...{ setTopTitle }} />}></Route>
          <Route path="/staffMaster/addForm" element={<StaffMasterForm {...{ setTopTitle }} />}></Route>

           {/* Fees */}
          <Route path="/feesType" element={<FeesType {...{ setTopTitle }} />}></Route>
          <Route path="/feesType/addForm" element={<FeesTypeForm {...{ setTopTitle }} />}></Route>
          <Route path="/feesCategory" element={<FeesCategory {...{ setTopTitle }} />}></Route>
          <Route path="/feesCategory/addForm" element={<FeesCategoryForm {...{ setTopTitle }} />}></Route>
          <Route path="/feesAllotment" element={<FeesAllotment {...{ setTopTitle }} />}></Route>
          <Route path="/feesAllotment/addForm" element={<FeesAllotmentForm {...{ setTopTitle }} />}></Route>

           {/* Reports */}

        </>
   
    </Routes>
  );
}

export default RoutesComponent;
