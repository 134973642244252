export const keys = Object.freeze({
  signIn: 'signIn',
  // authenticate: 'authenticate',
  deleteSelectedEmployees: "deleteSelectedEmployees",
  deleteEmployeeMaster: "deleteEmployeeMaster",
  saveEmployeeMaster: "saveEmployeeMaster",
  employeeMaster: "employeeMaster",
  getEditEmployeeMaster: 'getEditEmployeeMaster',
  getEditUserMaster: 'getEditUserMaster',

  userMaster: "userMaster",
  saveUserMaster: 'saveUserMaster',
  updateUserMaster: 'updateUserMaster',

  primaryCustomer: "primaryCustomer",
  getPrimeCustDataFromSap: "getPrimeCustDataFromSap",
  primeCustListBySubzone: (subzone) => `primeCustListBySubzone/${subzone}`,
  savePrimaryCustomerMaster : 'savePrimaryCustomerMaster',
  updatePrimaryCustomerMaster : 'updatePrimaryCustomerMaster',
  deletePrimaryCustomerMaster : 'deletePrimaryCustomerMaster',
  getEditPrimaryCustomerMaster : 'getEditPrimaryCustomerMaster',
  outletMaster: 'outletMaster',

  //Student
  addAdmissionMasterData: 'addAdmissionMasterData',
  updateAdmissionMasterData: 'updateAdmissionMasterData',
  admissionMasterData: 'admissionMasterData',
  classAllotmentData: 'classAllotmentData',
  addEnquiryMasterData: 'addEnquiryMasterData',
  updateEnquiryMasterData: 'updateEnquiryMasterData',
  enquiryMasterData: 'enquiryMasterData',
  timeTableMasterData: 'timeTableMasterData',

  //masters
  addAcademicMasterData: 'addAcademicMasterData',
  updateAcademicMasterData: 'updateAcademicMasterData',
  academicMasterData: 'academicMasterData',

  //submasters
  addDropdownMasterData: 'addDropdownMasterData',
  updateDropdownMasterData: 'updateDropdownMasterData',
  dropdownMasterData: 'dropdownMasterData',
  addDropdownListMasterData: 'addDropdownListMasterData',
  updateDropdownListMasterData: 'updateDropdownListMasterData',
  dropdownListMasterData: 'dropdownListMasterData',
  roleMasterData: 'roleMasterData',
  addRoleMasterData: 'addRoleMasterData',
  updateRoleMasterData: 'updateRoleMasterData',
  designationMasterData: 'designationMasterData',
  addDesignationMasterData: 'adddesignationMasterData',
  updateDesignationMasterData: 'updatedesignationMasterData',
  standardMasterData: 'standardMasterData',
  standardOptions: 'standardOptions',
  addStandardMasterData: 'addStandardMasterData',
  updateStandardMasterData: 'updateStandardMasterData',
  sectionMasterData: 'sectionMasterData',
  addSectionMasterData: 'addSectionMasterData',
  updateSectionMasterData: 'updateSectionMasterData',
  subjectMasterData: 'subjectMasterData',
  addSubjectMasterData: 'addSubjectMasterData',
  updateSubjectMasterData: 'updateSubjectMasterData',
  termMasterData: 'termMasterData',
  addTermMasterData: 'addTermMasterData',
  updateTermMasterData: 'updateTermMasterData',
  getMenuMasterData: 'getMenuMasterData',
  addMenuMasterData: 'addMenuMasterData',
  updateMenuMasterData: 'updateMenuMasterData',
  getMenuListData: 'getMenuListData',
  addMenuListData: 'addMenuListData',
  updateMenuListData: 'updateMenuListData',
  getMenuAccessData: 'getMenuAccessData',
  addMenuAccessData: 'addMenuAccessData',
  updateMenuAccessData: 'updateMenuAccessData',
  
  //fees management
  feesTypeData: 'feesTypeData',
  addFeesTypeData: 'addFeesTypeData',
  updateFeesTypeData: 'updateFeesTypeData',
  feesCategoryData: 'feesCategoryData',
  addFeesCategoryData: 'addFeesCategoryData',
  updateFeesCategoryData: 'updateFeesCategoryData',
  feesNameData: 'feesNameData',
  addFeesNameData: 'addFeesNameData',
  updateFeesNameData: 'updateFeesNameData',

  feesAllotmentData: 'feesAllotmentData',
  editFeesAllotmentData: 'editFeesAllotmentData',
  addFeesAllotmentData: 'addFeesAllotmentData',
  updateFeesAllotmentData: 'updateFeesAllotmentData',

  countryMasterData: 'countryMasterData',

  districtMasterData: 'districtMasterData',
  stateMasterData: 'stateMasterData',









 
});
