import React from "react";
import CustomTable from "../../../common/components/CustomTableView";
import { column } from "./components/column";
import UseAdmissionMaster from "./hooks/UseAdmissionMaster";
import { Tooltip } from "@mui/material";
import { Button } from "react-bootstrap";
import { BsPlusLg } from "react-icons/bs";
import {
  requestedFeaturesAll,
  requestedMenuAll,
} from "../../../api/constants/menuAccess";
import VerifyAccess from "../../../common/components/verify-access/verify-access";
import { isUserHasAccess } from "../../../common/helpers/helpers";
import { useSelector } from "react-redux";

const AdmissionMaster = ({ setTopTitle }) => {
  const userData = useSelector((state) => state.auth);
  const entitlement = userData?.userData?.data?.entitlement || {};
  const { admissionKey } = requestedMenuAll;
  const { createKey, editKey, deleteKey } = requestedFeaturesAll;

  const isEditDeleteAccess =
    isUserHasAccess(admissionKey, editKey, entitlement) ||
    isUserHasAccess(admissionKey, deleteKey, entitlement);

    const actionButton = (
      <Tooltip placement="bottom" title={"Add"}>
            <VerifyAccess requestedMenu={admissionKey} requestedFeature={createKey}>
        <Button
          onClick={() => {
            onClickAdd();
          }}
          className="btn btn-primary me-2 px-md-3 px-sm-4"
        >
          <BsPlusLg size={12} />
        </Button>
        </VerifyAccess>
      </Tooltip>
    );

  setTopTitle("Admission Master");

  const { onClickAdd, handleEditClick, admissionMasterValues } =
    UseAdmissionMaster();

  return (
    <div className="h-screen">
      <CustomTable
        dataSource={admissionMasterValues}
        column={column({ handleEditClick, isEditDeleteAccess })}
        actionButton={actionButton}
        title={"Admission Master List"}
      />
    </div>
  );
};

export default AdmissionMaster;
